import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ChangeWithdrawalStatusForm from "./ChangeWithdrawalStatusForm";
import {submit} from "redux-form";
import {connect, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {actions as reportsActions} from "../../actions/reportsActions";
import {withTranslation} from "react-i18next";

const ChangeWithdrawalStatus = ({itemId, onClose, changeWithdrawalStatusSubmitting, actions}) => {

  const [dialogVisible, setDialogVisible] = useState(false)
  const dispatch = useDispatch();

  const onHandleSubmit = async (data) => {
    const result = await actions.changeWithdrawalStatus(itemId, data)
    if (result.success) {
      setDialogVisible(false);
      onClose();
    }
  }
  return (
    <React.Fragment>
      <div style={{padding: '0px 5px'}} >
        <Button size="small" variant="outlined" onClick={() => {setDialogVisible(true)}}>
          Zmień status
        </Button>
      </div>
      <Dialog open={dialogVisible} >
        <DialogTitle>Zmiana statusu zwrotu środków użytkownika</DialogTitle>
        <DialogContent>
         <ChangeWithdrawalStatusForm  onSubmit={onHandleSubmit}/>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={changeWithdrawalStatusSubmitting}
            onClick={() => {setDialogVisible(false)}}
          >
            Anuluj
          </Button>
          <Button
            disabled={changeWithdrawalStatusSubmitting}
            onClick={() => dispatch(submit('changeWithdrawalStatusForm'))}
            color="secondary"
          >
            Potwierdź
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

ChangeWithdrawalStatus.propTypes = {
  itemId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  changeWithdrawalStatusSubmitting: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(reportsActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    changeWithdrawalStatusSubmitting: state.reports.changeWithdrawalStatusSubmitting,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangeWithdrawalStatus));
