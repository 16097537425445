import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withTranslation} from 'react-i18next';
import validate from 'validate.js';
import FormTextField from '../form/FormTextField';
import FormSelect from '../form/FormSelect';

const validation = values => validate(
  values,
  {
    firstName: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    lastName: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    email: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    role: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
  },
  { fullMessages: false }
);

let OperatorAccountForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="email"
          component={FormTextField}
          label="Email"
          type="email"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="firstName"
          component={FormTextField}
          label="Imię"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="lastName"
          component={FormTextField}
          label="Nazwisko"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="role"
          component={FormSelect}
          label="Rola"
          style={{ width: 200 }}
          values={{
            ...[
              { value: "client", name: "Zamawiający" },
              { value: "mechanic", name: "Serwisant" },
              { value: "operator", name: "Operator" },
              { value: "operatorRoovee", name: "Operator - Roovee" },
              { value: 'coordinator', name: 'Koordynator' },
              { value: 'operatorAdministrator', name: 'Administrator' },
              { value: "operatorAdministratorRoovee", name: "administrator - Roovee" }
            ]
          }}
        />
      </div>
    </form>
  );
};

OperatorAccountForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

OperatorAccountForm = reduxForm({
  form: 'operatorAccountForm',
  validate: validation,
})(OperatorAccountForm);

export default withTranslation()(OperatorAccountForm);
