import React, {useEffect} from "react";
import {Button, Paper} from "@material-ui/core";
import DocumentTitle from 'react-document-title';
import TokenTable from "./TokenTable";
import {useTranslation} from "react-i18next";
import NewTokenDialog from "./newTokenDialog";
import {useDispatch, useSelector} from "react-redux";
import {actions, types} from "../../actions/publicAccessTokenActions";
import CreatedTokenDialog from "./createdTokenDialog";
import {useParams} from "react-router";
import TokenDialog from "./tokenDialog";
import DeactivateTokenDialog from "./deactivationTokenDialog";

const ApiAccess = () => {

  const dispatch = useDispatch();

  const openNewTokenDialog = useSelector(x => x.publicAccessToken.showNewTokenDialog, false)
  const openCreatedTokenDialog = useSelector(x => x.publicAccessToken.showCreatedTokenDialog, false)
  const createdToken = useSelector(x => x.publicAccessToken.accessToken, null)
  const fetchingAccessTokenList = useSelector(x => x.publicAccessToken.fetchingAccessTokenList, true)
  const accessTokenList = useSelector(x => x.publicAccessToken.accessTokenList, [])
  const permissionsList = useSelector(x => x.publicAccessToken.permissions, [])
  const submitting = useSelector(x => x.publicAccessToken.submitting, false)
  const fetchingPermissions = useSelector(x => x.publicAccessToken.fetchingPermissions, true)
  const tokenDialog = useSelector(x => x.publicAccessToken.tokenDialog, null)
  const deactivateDialog = useSelector(x => x.publicAccessToken.deactivateTokenDialog, null)

  const { id } = useParams();


  useEffect(() => {
    dispatch(actions.getTokenPermissions());
  },[])


  useEffect(() => {
    if(id !== null && id !== undefined) {
      dispatch(actions.getTokens(id))
    }
  }, [id])

  const closeNewTokenDialog = () => {
    dispatch(actions.hiddenNewTokenDialog())
  }

  const showNewTokenDialog = () => {
    dispatch(actions.showNewTokenDialog())
  }

  const closeTokenDialog = () => {
    dispatch(actions.hiddenTokenDialog())
  }

  const showTokenDialog = (edit, data) => {
    dispatch(actions.showTokenDialog(edit, data))
  }

  const closeCreatedTokenDialog = () => {
    dispatch(actions.hiddenCreatedTokenDialog())
  }

  const showDeactivateDialog = (tokenId) => {
    dispatch(actions.showDeactivateDialog(id, tokenId))
  }

  const closeDeactivateTokenDialog = () => {
    dispatch(actions.hiddenDeactivateDialog())
  }
  const onSubmit = async (data, tenantId) => {
    const result = await dispatch(actions.createToken(data.name, data.permissions, tenantId))
    if(result.type === types.CREATE_PUBLIC_ACCESS_TOKEN_SUCCESS){
      dispatch(actions.getTokens(tenantId))
    }
  }

  const onDeactivate = async (tenantId, id) => {
    const result = await dispatch(actions.deactivateTokenDialog(tenantId, id))
    if(result.type === types.DEACTIVATION_TOKEN_SUCCESS){
      dispatch(actions.getTokens(tenantId))
    }
  }

  const onUpdateSubmit = async (id, data, tenantId) => {
    const result = await dispatch(actions.updateToken(id, data.permissions, tenantId))
    if(result.type === types.UPDATE_PUBLIC_ACCESS_TOKEN_SUCCESS){
      dispatch(actions.getTokens(tenantId))
    }
  }

  const { t } = useTranslation();

  return (
    <DocumentTitle title={t('apiAccess.title')}>
      <Paper style={{ marginRight: 60, marginLeft: 60, marginTop: 80, padding: 16 }}>
        <div style={{ textAlign: 'right', paddingTop: 20}}>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={showNewTokenDialog}
            >
              Wygeneruj nowy token
            </Button>
          </div>
        </div>
        <TokenTable
          fetching={fetchingAccessTokenList}
          data={accessTokenList}
          showTokenDialog={showTokenDialog}
          showDeactivateDialog={showDeactivateDialog}
        />
        <NewTokenDialog
          open={openNewTokenDialog}
          loading={fetchingPermissions}
          submitting={submitting}
          onSubmit={(data) => onSubmit(data,id)}
          onCancel={closeNewTokenDialog}
          permissions={permissionsList}
        />
        <CreatedTokenDialog
            onCancel={closeCreatedTokenDialog}
            open={openCreatedTokenDialog}
            token={createdToken}
        />
        <TokenDialog
          onCancel={closeTokenDialog}
          open={tokenDialog.show}
          edit={tokenDialog.edit}
          data={tokenDialog.data}
          permissions={permissionsList}
          submitting={submitting}
          onSubmit={(tokenId, data) => onUpdateSubmit(tokenId, data,id)}
        />
        <DeactivateTokenDialog
          submitting={submitting}
          open={deactivateDialog.show}
          data={deactivateDialog.data}
          onCancel={closeDeactivateTokenDialog}
          onSubmit={onDeactivate}
        />
      </Paper>
    </DocumentTitle>
  )

}

export default ApiAccess;
