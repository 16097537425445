import bikesApi from "../api/bikesApi";
import { actions as notificationActions } from "./notificationActions";
import * as logger from "../utils/logger";

export const types = {
  LOAD_BIKES: "LOAD_BIKES",
  LOAD_BIKES_SUCCESS: "LOAD_BIKES_SUCCESS",
  LOAD_NEXT_BIKES: "LOAD_NEXT_BIKES",
  LOAD_NEXT_BIKES_SUCCESS: "LOAD_NEXT_BIKES_SUCCESS",
  RESTRICT_RENTALS_SUBMIT: "RESTRICT_RENTALS_SUBMIT",
  RESTRICT_RENTALS_SUCCESS: "RESTRICT_RENTALS_SUCCESS",
  RESTRICT_RENTALS_FAILURE: "RESTRICT_RENTALS_FAILURE",
  ALLOW_RENTALS_SUBMIT: "ALLOW_RENTALS_SUBMIT",
  ALLOW_RENTALS_SUCCESS: "ALLOW_RENTALS_SUCCESS",
  ALLOW_RENTALS_FAILURE: "ALLOW_RENTALS_FAILURE",
  SEND_LOCK_MESSAGE_SUBMIT: "SEND_LOCK_MESSAGE_SUBMIT",
  SEND_LOCK_MESSAGE_SUCCESS: "SEND_LOCK_MESSAGE_SUCCESS",
  SEND_LOCK_MESSAGE_FAILURE: "SEND_LOCK_MESSAGE_FAILURE",
};

export const actions = {
  loadFreshBikes: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({
      type: types.LOAD_BIKES,
      pageNumber,
      pageSize,
      sortBy,
      filterBy,
    });
    return bikesApi
      .getBikes(pageNumber, pageSize, sortBy, filterBy)
      .then((res) => {
        if (res.ok) {
          dispatch({ type: types.LOAD_BIKES_SUCCESS, result: res.data || {} });
        }
      });
  },

  loadNextBikes: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_BIKES, pageNumber });
    const sortBy = getState().bikes.list.pagedData.sortedBy;
    const filterBy = getState().bikes.list.pagedData.filteredBy;
    const pageSize = getState().bikes.list.pagedData.paging.pageSize;

    return bikesApi
      .getBikes(pageNumber, pageSize, sortBy, filterBy)
      .then((res) => {
        if (res.ok) {
          dispatch({
            type: types.LOAD_NEXT_BIKES_SUCCESS,
            result: res.data || {},
          });
        }
      });
  },

  restrictRentals: (ids, restrictionReason) => (dispatch) => {
    dispatch({ type: types.RESTRICT_RENTALS_SUBMIT, ids, restrictionReason });
    return bikesApi.restrictRentals(ids, restrictionReason).then((res) => {
      if (res.ok && res.data.success !== false) {
        dispatch({
          type: types.RESTRICT_RENTALS_SUCCESS,
          ids,
          restrictionReason,
        });
        dispatch(
          notificationActions.showSuccess("Tymczasowo wycofano rower z użytku.")
        );
      } else {
        dispatch({
          type: types.RESTRICT_RENTALS_FAILURE,
          ids,
          restrictionReason,
        });
        dispatch(
          notificationActions.showError(
            "Tymczasowe wycofanie roweru z eksploatacji nie powiodło się."
          )
        );
        return logger.error(res.data);
      }
    });
  },

  allowRentals: (ids) => (dispatch) => {
    dispatch({ type: types.ALLOW_RENTALS_SUBMIT, ids });
    return bikesApi.allowRentals(ids).then((res) => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.ALLOW_RENTALS_SUCCESS, ids });
        dispatch(
          notificationActions.showSuccess("Rower przywrócony do użytkowania.")
        );
      } else {
        dispatch({ type: types.ALLOW_RENTALS_FAILURE, ids });
        dispatch(
          notificationActions.showError(
            "Przywrócenie roweru do użytkowania nie powiodło się."
          )
        );
        return logger.error(res.data);
      }
    });
  },

  sendLockMessage: (ids, message, devicesType) => (dispatch) => {
    dispatch({ type: types.SEND_LOCK_MESSAGE_SUBMIT, ids, devicesType });
    return bikesApi.sendLockMessage(ids, message, devicesType).then((res) => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.SEND_LOCK_MESSAGE_SUCCESS, ids });
        dispatch(
          notificationActions.showSuccess("Wysłano wiadomość do locka.")
        );
      } else {
        dispatch({ type: types.SEND_LOCK_MESSAGE_FAILURE, ids });
        dispatch(
          notificationActions.showError(
            "Wysyłanie wiadomości nie powiodło się."
          )
        );
        return logger.error(res.data);
      }
    });
  },
};
