import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import validate from 'validate.js';
import FormTextField from '../form/FormTextField';
import FormCheckbox from '../form/FormCheckBox';
import FormDateTimeField from '../form/FormDateTimeField';
import { connect } from 'react-redux';

const validation = values => validate(
  values,
  {
    id: {
      presence: { message: 'Pole jest wymagane', allowEmpty: false },
      format: {
        message: 'Subdomena może składać się tylko ze znaków alfanumerycznych rozdzielonych pojedynczymi myślnikami',
        pattern: /^[a-z0-9]+(-?[a-z0-9]+)*$/,
      }
    },
    name: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    fullName: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    phoneNumber: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    email: { presence: { message: 'Pole jest wymagane', allowEmpty: false } },
    loginFailedQuantity: {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1'
      }
    },
    timeOfAccountBlock: {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 1,
        message: 'Wartość musi być większa lub równa 1'
      }
    },
    desiredBikesNumber: {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        message: 'Wartość nie może być ujemna'
      }
    },
  },
  { fullMessages: false }
);

let TenantDetailsForm = ({ handleSubmit, submitting, t, initialValues, invalid, smsCampaignsValue }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="id"
          component={FormTextField}
          label="Subdomena"
          disabled={initialValues.id == null ? false : true}
        />
      </div>
      <div>
        <Field
          name="name"
          component={FormTextField}
          label="Nazwa skrócona"
        />
      </div>
      <div>
        <Field
          name="fullName"
          component={FormTextField}
          label="Pełna nazwa"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="phoneNumber"
          component={FormTextField}
          label="Numer telefonu BOK"
        />
      </div>
      <div>
        <Field
          name="email"
          component={FormTextField}
          label="Email BOK"
          type="email"
        />
      </div>
      <div>
        <Field
          name="license.validFrom"
          component={FormDateTimeField}
          label="Licencja od"
        />
      </div>
      <div>
        <Field
          name="license.validTo"
          component={FormDateTimeField}
          label="Licencja do"
        />
      </div>
      <div>
        <Field
          name="internalUseOnly"
          component={FormCheckbox}
          label="Dostępny tylko dla testerów"
          type="checkbox"
        />
      </div>
      <div>
        <Field
          name="photoAfterRide"
          component={FormCheckbox}
          label="Konieczność zrobienia zdjęcia po przejeździe"
          type="checkbox"
        />
      </div>
      <div>
        <Field
          name="requirePeselNumber"
          component={FormCheckbox}
          label="Wymaga podania numeru pesel"
          type="checkbox"
        />
      </div>
      <div>
        <Field
          name="gbfsActive"
          component={FormCheckbox}
          label="Aktywny GBFS"
          type="checkbox"
        />
      </div>
      <div>
        <Field
          name="desiredBikesNumber"
          component={FormTextField}
          label="Liczba rowerów zgodna z zapisami umowy"
          type="number"
          parse={Number}
        />
      </div>
      <div>
        <Field
          name="smsCampaigns.enabled"
          component={FormCheckbox}
          label="Możliwość prowadzenia kampanii SMS"
          type="checkbox"
        />
      </div>
      {
        smsCampaignsValue && smsCampaignsValue.enabled &&
        <div>
          <div>
            <Field
              name="smsCampaigns.apiToken"
              component={FormTextField}
              label="Token API mProfi"
              fullWidth={false}
              type="password"
            />
          </div>
        </div>
      }
      <div>
        <Field
          name="loginFailedQuantity"
          component={FormTextField}
          label="Ilość prób logowania"
          type="number"
        />
      </div>
      <div>
        <Field
          name="timeOfAccountBlock"
          component={FormTextField}
          label="Czas blokady konta bo błędnych logowaniach"
          type="number"
        />
      </div>
      <div>
        <Field
          name="reportDateStart.clientDateAllowFrom"
          component={FormDateTimeField}
          label="Generacja raportów dla roli zamawiającego od: "
          style={{width: '100%'}}
        />
      </div>
      {initialValues.id == null
        ? null
        : <div style={{textAlign: 'right', marginTop: 16}}>
          <Button variant="contained" color="primary" type="submit" className="signin-button"
                  disabled={invalid || submitting}>
            {t('bikeTypes.submitButton')}
          </Button>
        </div>}
    </form>
  );
};

TenantDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  smsCampaignsValue: PropTypes.object,
};

TenantDetailsForm = reduxForm({
  form: 'tenantDetailsForm',
  validate: validation,
})(TenantDetailsForm);

const selector = formValueSelector('tenantDetailsForm');
TenantDetailsForm = connect(
  (state) => ({
    smsCampaignsValue: selector(state, 'smsCampaigns')
  })
)(TenantDetailsForm);

export default withTranslation()(TenantDetailsForm);
