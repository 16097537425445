import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import FormSelect from "../form/FormSelect";
import PropTypes from "prop-types";
import {SanctionType, IncomeFor, PaymentType} from "../../models/tenants/PricingModel";
import {useSelector} from "react-redux";

const validation = values => {
  const errors = {};
  const requiredFields = [
    "rentalTimeOver12H",
    "bikeLeftOutOfOperationsZonePenalty",
    "bikeDamage",
    "infrastructureDamage",
    "otherSanction",
    "rentalTimeBelow12H",
    "bikeLeftOutOfPreferredZonePenalty",
    "reward",
    "otherPayments"
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Pole jest wymagane';
    }
  });
  return errors;
}

let IncomeDistributionForm = ({ onSubmit,  t}) => {

  const incomeDistributionFormState = useSelector(state => state.form.incomeDistributionForm);

  return (
    <form onSubmit={onSubmit}>
      <h3>{t('tenantIncomeDistribution.sanctions')}</h3>
      <div>
        {SanctionType.map(sanction => (

          <div key={sanction.value}>
            <Field
            name={sanction.value}
            component={FormSelect}
            label={sanction.name}
            style={{ width: 500 }}
            values={IncomeFor}
          />
          </div>
        ))}
      </div>
      <h3>{t('tenantIncomeDistribution.payments')}</h3>
      <div>
        {PaymentType.map(payment => (
          <div key={payment.value}>
            <Field
              name={payment.value}
              component={FormSelect}
              label={payment.name}
              style={{ width: 500 }}
              values={IncomeFor}
            />
          </div>
        ))}
      </div>
      {incomeDistributionFormState && incomeDistributionFormState.syncErrors && <div>
        Wszystkie pola są wymagane
      </div>}
    </form>
  );
};

IncomeDistributionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

IncomeDistributionForm = reduxForm({
  form: 'incomeDistributionForm',
  validate: validation,
})(IncomeDistributionForm);

export default withTranslation()(IncomeDistributionForm);
