import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {submit} from "redux-form";
// eslint-disable-next-line import/no-unresolved
import GenerateWithdrawalReportForm from "./GenerateWithdrawalReportForm";

const GenerateWithdrawalReportDialog = ({open, onSubmit, onCancel, submitting, loading}) => {

  const dispatch = useDispatch();

  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>Generowanie raportu</DialogTitle>
      <DialogContent>
        {!loading && <GenerateWithdrawalReportForm onSubmit={onSubmit}  />}
        {loading && <div style={{textAlign: "center"}}>
          <CircularProgress />
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          Anuluj
        </Button>
        <Button variant="contained" onClick={() => dispatch(submit('generateWithdrawalReport'))} disabled={submitting}
                color="primary">
          Wygeneruj
        </Button>
      </DialogActions>
    </Dialog>
  )
}


GenerateWithdrawalReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default GenerateWithdrawalReportDialog;
