import { TableModel } from '../TableModel';

export class BikesListModel {
  constructor() {
    this.loading = false;
    this.pagedData = new TableModel(
      { pageNumber: 1, pageSize: 1000 },
      [{ id: 'tenant', value: 'asc' }, { id: 'bikeNumber', value: 'asc' }],
      []);
  }
}

export const InternalUseOnlyOptions = {
  true: { value: 'true', name: 'TAK' },
  false: { value: 'false', name: 'NIE' },
};

export const ShackleStateOptions = {
  closed: { value: 'closed', name: '0' },
  opened: { value: 'opened', name: '1' },
};

export const LockStateOptions = {
  closed: { value: 'closed', name: '0' },
  opened: { value: 'opened', name: '1' },
};
