export class AuthModel {
  constructor(user) {
    this.signinInProgress = false;
    if (!user) {
      this.id = null;
      this.name = null;
      this.email = null;
      this.signedIn = false;
    } else {
      this.id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.signedIn = true;
    }
  }
}
