import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import AccountsList from './AccountsList';

class MobileUserAccountsPage extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <DocumentTitle title={t('mobileUserAccounts.title')}>
        <div className="page">
          <AccountsList />
        </div>
      </DocumentTitle>
    );
  }
}

MobileUserAccountsPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(MobileUserAccountsPage);
