import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikePartsActions';
import _ from 'lodash';

export default function bikePartsReducer(state = Immutable(initialState.bikeParts), action) {
  switch (action.type) {
    case types.GET_BIKE_PARTS: {
      return state.merge({ fetching: true, data: [] });
    }
    case types.GET_BIKE_PARTS_SUCCESS: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.CREATE_BIKE_PART_SUCCESS: {
      let newData = [...state.data, { id: action.id, name: action.name }];
      newData = _.sortBy(newData, x => x.name);

      return state.merge({
        fetching: false,
        data: newData,
      });
    }
    case types.GET_BIKE_PARTS_FAILURE: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.DELETE_BIKE_PART_SUCCESS: {
      return state.merge({
        data: state.data.filter(i => i.id !== action.id),
      });
    }
    default:
      return state;
  }
}
