import React from 'react';
import PropTypes from 'prop-types';
import TextFilter from '../common/table/filters/TextFilter';
import SelectFilter from '../common/table/filters/SelectFilter';
import { TesterOptions } from '../../models/mobileUserAccounts/MobileUserAccountsListModel';

class FiltersPanel extends React.Component {
  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete = deleteFunction => this.props.onFilteredChange(deleteFunction(this.props.filteredBy));

  render() {
    return (
      <div className="flex-row flex-wrap" style={{marginBottom: 16}}>
        <TextFilter filter={this.getFilterValue("name")} onDelete={this.handleDelete} readonly label="Nazwa" />
        <TextFilter filter={this.getFilterValue("fullName")} onDelete={this.handleDelete} readonly label="Pełna nazwa" />
        <SelectFilter filter={this.getFilterValue("internalUseOnly")} items={TesterOptions} onDelete={this.handleDelete} readonly label="Widoczny dla testerów" />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
