import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/notificationActions';
import { NotificationTypes } from '../../models/NotificationModel';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorNotification from './ErrorNotification';
import SuccessNotification from './SuccessNotification';
import _ from 'lodash';

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

class NotificationsHandler extends Component  {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isOpen: false
    };
    this.onRequestClose = this.onRequestClose.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.length) {
      this.setState({isOpen: true});
    }
  }

  onRequestClose() {
    this.setState({isOpen: false});
    this.props.actions.clear();
  }

  renderMessage(notification, index) {
    if (notification.type === NotificationTypes.error) return <ErrorNotification key={index} message={notification.message}/>;
    if (notification.type === NotificationTypes.success) return <SuccessNotification key={index} message={notification.message}/>;
    return null;
  }

  render() {
    const { notifications, classes } = this.props;
    return (
      <React.Fragment>
        {notifications.some(n => n.type === NotificationTypes.error) && <Snackbar
          open={this.state.isOpen}
          autoHideDuration={8000}
          onClose={this.onRequestClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <SnackbarContent
            message={_.takeRight(notifications.filter(n => n.type === NotificationTypes.error), 3).map((e, index) => this.renderMessage(e, index))}
            className={classes.error}
          />
        </Snackbar>}
        {notifications.some(n => n.type === NotificationTypes.success) && <Snackbar
          open={this.state.isOpen}
          autoHideDuration={8000}
          onClose={this.onRequestClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <SnackbarContent
            message={_.takeRight(notifications.filter(n => n.type === NotificationTypes.success), 3).map((e, index) => this.renderMessage(e, index))}
          />
        </Snackbar>}
      </React.Fragment>
    );
  }
}

NotificationsHandler.propTypes = {
  notifications: PropTypes.array,
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationsHandler));
