import React, {useEffect} from "react";
import DocumentTitle from 'react-document-title';
import {Paper} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import HistoryList from "./HistoryList";
import {actions} from "../../actions/reportsActions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";

const AutomaticReportsHistory = () => {

  const { t } = useTranslation();
  const { id, reportId } = useParams();
  const dispatch = useDispatch();
  const reportHistory = useSelector(x => x.reports.reportConfigurationHistory, null);
  const fetchingReportConfigurationHistory = useSelector(x => x.reports.fetchingReportConfigurationHistory, true);

  useEffect(() => {
    dispatch(actions.getReportConfigurationHistory(id, reportId))
  },[])

  return(
    <DocumentTitle title={t('automaticReport.history.title')}>
      <Paper style={{ marginRight: 60, marginLeft: 60, marginTop: 80, padding: 16 }}>
        <HistoryList
          data={reportHistory}
          fetching={fetchingReportConfigurationHistory}
        />
      </Paper>
    </DocumentTitle>
  )

}



export default AutomaticReportsHistory;
