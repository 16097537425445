import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormCheckBox = ({
  input,
  label,
  ...custom
}) => (
  <FormControlLabel
    control={
      <Checkbox
        {...custom}
        checked={input.value}
        onChange={e => {
          input.onChange(e, !input.value);
        }}
        value={input.name}
      />
    }
    label={label}
  />
);

FormCheckBox.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
};

export default FormCheckBox;
