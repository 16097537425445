import { TableModel } from '../TableModel';

export class TenantsListModel {
  constructor() {
    this.loading = false;
    this.pagedData = new TableModel(
      { pageNumber: 1, pageSize: 1000 },
      [{ id: 'name', value: 'asc' }],
      []);
  }
}
