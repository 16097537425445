import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import BikesList from './bikes/BikesList';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <DocumentTitle title={t('dashboard.title')}>
        <div className="page">
          <BikesList />
        </div>
      </DocumentTitle>
    );
  }
}

HomePage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(HomePage);
