import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Table from "../common/table/Table";
import {columns} from "./Columns";
import ListItemMenu from "./ListItemMenu";
import {bindActionCreators} from "redux";
import {actions} from "../../actions/reportsActions";
import {connect} from "react-redux";
import FiltersPanel from "./FiltersPanel";
const WithdrawalReportList = ({list, actionsReport}) => {
  const onHandlePageChange = async (nextPage) => {
    await actionsReport.loadNextWithdrawalReports(nextPage);
  }

  const onHandleFiltersChange = filterBy => {
    actionsReport.loadWithdrawalReportReports(1, list.paging.pageSize, list.sortedBy, filterBy)
  }

  const onHandlePageSizeChange = nextPageSize => {
    actionsReport.loadWithdrawalReportReports(1, nextPageSize, list.sortedBy, list.filteredBy)
  }
  const onHandleSortChange = sortBy => {
    actionsReport.loadWithdrawalReportReports(1, list.paging.pageSize, sortBy, list.filteredBy);
  }

  useEffect(() => {
    actionsReport.loadWithdrawalReportReports(1, list.paging.pageSize, list.sortedBy, list.filteredBy)
  }, []);

  return (
    <div>
      <FiltersPanel filteredBy={list.filteredBy} onFilteredChange={onHandleFiltersChange} />
      <Table
        columns={[{
          Header: '',
          id: 'icon',
          // eslint-disable-next-line react/display-name
          Cell: row => <ListItemMenu itemId={row.original.id} />,
          width: 40,
          sortable: false,
          Filter: () => null,
        }, ...columns]}
        data={list.records}
        filteredBy={list.filteredBy}
        sortedBy={list.sortedBy}
        paging={list.paging}
        onFilteredChange={onHandleFiltersChange}
        onPageChange={onHandlePageChange}
        onPageSizeChange={onHandlePageSizeChange}
        onSortedChange={onHandleSortChange}
        loading={list.loading}
        minRows={0}
        keyField="id"
      />
    </div>
  )
}

WithdrawalReportList.propTypes = {
  list: PropTypes.object.isRequired,
  actionsReport: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    actionsReport: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports;
  return {
    fetching: reportState.fetchingWithdrawalReport,
    list: reportState.withdrawalsData.pagedData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalReportList);
