import accountsApi from '../api/mobileUserAccountsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';
import moment from 'moment';

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const types = {
  LOAD_MOBILE_USER_ACCOUNTS: 'LOAD_MOBILE_USER_ACCOUNTS',
  LOAD_MOBILE_USER_ACCOUNTS_SUCCESS: 'LOAD_MOBILE_USER_ACCOUNTS_SUCCESS',
  LOAD_NEXT_MOBILE_USER_ACCOUNTS: 'LOAD_NEXT_MOBILE_USER_ACCOUNTS',
  LOAD_NEXT_MOBILE_USER_ACCOUNTS_SUCCESS: 'LOAD_NEXT_MOBILE_USER_ACCOUNTS_SUCCESS',
  GET_MOBILE_USER_ACCOUNT: 'GET_MOBILE_USER_ACCOUNT',
  GET_MOBILE_USER_ACCOUNT_SUCCESS: 'GET_MOBILE_USER_ACCOUNT_SUCCESS',
  GET_MOBILE_USER_ACCOUNT_FAILURE: 'GET_MOBILE_USER_ACCOUNT_FAILURE',
  UNLOCK_USER_VERIFICATION_SUBMIT: 'UNLOCK_USER_VERIFICATION_SUBMIT',
  UNLOCK_USER_VERIFICATION_SUCCESS: 'UNLOCK_USER_VERIFICATION_SUCCESS',
  UNLOCK_USER_VERIFICATION_FAILURE: 'UNLOCK_USER_VERIFICATION_FAILURE',
  GRANT_TESTER_ROLE_SUBMIT: 'GRANT_TESTER_ROLE_SUBMIT',
  GRANT_TESTER_ROLE_SUCCESS: 'GRANT_TESTER_ROLE_SUCCESS',
  GRANT_TESTER_ROLE_FAILURE: 'GRANT_TESTER_ROLE_FAILURE',
  REVOKE_TESTER_ROLE_SUBMIT: 'REVOKE_TESTER_ROLE_SUBMIT',
  REVOKE_TESTER_ROLE_SUCCESS: 'REVOKE_TESTER_ROLE_SUCCESS',
  REVOKE_TESTER_ROLE_FAILURE: 'REVOKE_TESTER_ROLE_FAILURE',
  WITHDRAW_SUBMIT: 'WITHDRAW_SUBMIT',
  WITHDRAW_SUCCESS: 'WITHDRAW_SUCCESS',
  WITHDRAW_FAILURE: 'WITHDRAW_FAILURE',
  LOCK_MOBILE_USER_ACCOUNT_SUBMIT: 'LOCK_MOBILE_USER_ACCOUNT_SUBMIT',
  LOCK_MOBILE_USER_ACCOUNT_SUCCESS: 'LOCK_MOBILE_USER_ACCOUNT_SUCCESS',
  LOCK_MOBILE_USER_ACCOUNT_FAILURE: 'LOCK_MOBILE_USER_ACCOUNT_FAILURE',
  UNLOCK_MOBILE_USER_ACCOUNT_SUBMIT: 'UNLOCK_MOBILE_USER_ACCOUNT_SUBMIT',
  UNLOCK_MOBILE_USER_ACCOUNT_SUCCESS: 'UNLOCK_MOBILE_USER_ACCOUNT_SUCCESS',
  UNLOCK_MOBILE_USER_ACCOUNT_FAILURE: 'UNLOCK_MOBILE_USER_ACCOUNT_FAILURE',
  DELETE_MOBILE_USER_ACCOUNT_SUBMIT: 'DELETE_MOBILE_USER_ACCOUNT_SUBMIT',
  DELETE_MOBILE_USER_ACCOUNT_SUCCESS: 'DELETE_MOBILE_USER_ACCOUNT_SUCCESS',
  DELETE_MOBILE_USER_ACCOUNT_FAILURE: 'DELETE_MOBILE_USER_ACCOUNT_FAILURE',
  REGISTER_FUNDS_RETURN_SUBMIT: 'REGISTER_FUNDS_RETURN_SUBMIT',
  REGISTER_FUNDS_RETURN_SUCCESS: 'REGISTER_FUNDS_RETURN_SUCCESS',
  REGISTER_FUNDS_RETURN_FAILURE: 'REGISTER_FUNDS_RETURN_FAILURE',
  EXPORT_USERS_TO_SPREADSHEET: 'EXPORT_USERS_TO_SPREADSHEET',
  EXPORT_USERS_TO_SPREADSHEET_SUCCESS: 'EXPORT_USERS_TO_SPREADSHEET_SUCCESS',
  EXPORT_USERS_TO_SPREADSHEET_FAILURE: 'EXPORT_USERS_TO_SPREADSHEET_FAILURE',
};

export const actions = {
  loadFreshAccounts: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_MOBILE_USER_ACCOUNTS, pageNumber, pageSize, sortBy, filterBy });
    return accountsApi.getAccounts(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_MOBILE_USER_ACCOUNTS_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadNextAccounts: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_MOBILE_USER_ACCOUNTS, pageNumber });
    const sortBy = getState().mobileUserAccounts.list.pagedData.sortedBy;
    const filterBy = getState().mobileUserAccounts.list.pagedData.filteredBy;
    const pageSize = getState().mobileUserAccounts.list.pagedData.paging.pageSize;

    return accountsApi.getAccounts(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_MOBILE_USER_ACCOUNTS_SUCCESS, result: res.data || {} });
      }
    });
  },

  requestExcelExport: (sortBy, filterBy) => dispatch => {
    dispatch({ type: types.EXPORT_USERS_TO_SPREADSHEET });
    return accountsApi.exportToSpreadsheet(sortBy, filterBy).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.EXPORT_USERS_TO_SPREADSHEET_SUCCESS });
        storeBlobAs(res.data, `Użytkownicy${moment().toISOString()}.xlsx`);
      } else {
        dispatch({ type: types.EXPORT_USERS_TO_SPREADSHEET_FAILURE });
        dispatch(notificationActions.showError('Eksport nie powiódł się'));
      }
    });
  },

  getDetails: id => dispatch => {
    dispatch({ type: types.GET_MOBILE_USER_ACCOUNT });

    return accountsApi
      .getDetails(id)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_MOBILE_USER_ACCOUNT_SUCCESS, account: response.data });
        }

        dispatch({ type: types.GET_MOBILE_USER_ACCOUNT_FAILURE });
        dispatch(notificationActions.showError('Wystąpił błąd podczas pobierania szczegółów użytkownika'));
        return logger.error(response);
      });
  },

  unblockVerification: id => (dispatch) => {
    dispatch({ type: types.UNLOCK_USER_VERIFICATION_SUBMIT, id });
    return accountsApi.unblockVerification(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.UNLOCK_USER_VERIFICATION_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Odblokowano kody weryfikacyjne'));
      } else {
        dispatch({ type: types.UNLOCK_USER_VERIFICATION_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  grantTesterRole: id => (dispatch) => {
    dispatch({ type: types.GRANT_TESTER_ROLE_SUBMIT, id });
    return accountsApi.grantTesterRole(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.GRANT_TESTER_ROLE_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Uprawnienia testera zostały przypisane'));
      } else {
        dispatch({ type: types.GRANT_TESTER_ROLE_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  revokeTesterRole: id => (dispatch) => {
    dispatch({ type: types.REVOKE_TESTER_ROLE_SUBMIT, id });
    return accountsApi.revokeTesterRole(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.REVOKE_TESTER_ROLE_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Uprawnienia testera zostały odebrane'));
      } else {
        dispatch({ type: types.REVOKE_TESTER_ROLE_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  withdraw: id => (dispatch) => {
    dispatch({ type: types.WITHDRAW_SUBMIT, id });
    return accountsApi.withdraw(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.WITHDRAW_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Zlecono wypłatę środków'));
      } else {
        dispatch({ type: types.WITHDRAW_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  registerAllFundsReturn: id => (dispatch) => {
    dispatch({ type: types.REGISTER_FUNDS_RETURN_SUBMIT, id });
    return accountsApi.registerFundsReturn(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.REGISTER_FUNDS_RETURN_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Zarejestrowano zwrot środków'));
      } else {
        dispatch({ type: types.REGISTER_FUNDS_RETURN_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  lock: id => (dispatch) => {
    dispatch({ type: types.LOCK_MOBILE_USER_ACCOUNT_SUBMIT, id });
    return accountsApi.lock(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.LOCK_MOBILE_USER_ACCOUNT_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Konto zostało zablokowane'));
      } else {
        dispatch({ type: types.LOCK_MOBILE_USER_ACCOUNT_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  unlock: id => (dispatch) => {
    dispatch({ type: types.UNLOCK_MOBILE_USER_ACCOUNT_SUBMIT, id });
    return accountsApi.unlock(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.UNLOCK_MOBILE_USER_ACCOUNT_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Konto zostało odblokowane'));
      } else {
        dispatch({ type: types.UNLOCK_MOBILE_USER_ACCOUNT_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  delete: id => (dispatch) => {
    dispatch({ type: types.DELETE_MOBILE_USER_ACCOUNT_SUBMIT, id });
    return accountsApi.delete(id).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.DELETE_MOBILE_USER_ACCOUNT_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Konto zostało usunięte'));
      } else {
        dispatch({ type: types.DELETE_MOBILE_USER_ACCOUNT_FAILURE, id });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },
};
