import bikeAvailableIcon from '../../assets/bikeAvailableIcon.png';
import bikeRentedIcon from '../../assets/bikeRentedIcon.png';

export class BikeRecordModel {
  constructor(data) {
    this.id = data.id;
    this.bikeNumber = data.bikeNumber;
    this.lockImei = data.lockImei;
    this.lockPhoneNumber = data.lockPhoneNumber;
    this.lockBatteryLevel = data.lockBatteryLevel;
    this.bikeBatteryLevel = data.bikeBatteryLevel != null && data.bikeBatteryLevel >=0 && data.bikeBatteryLevel <= 100 ? data.bikeBatteryLevel : null;
    this.lockFirmware = data.lockFirmware;
    this.lockState = data.lockState;
    this.shackleState = data.shackleState;
    this.insideOperationsZone = data.insideOperationsZone;
    this.lockTimestamp = data.lockTimestamp;
    this.gpsTimestamp = data.gpsTimestamp;
    this.satellites = data.satellites;
    this.isAvailableForRental = data.isAvailableForRental;
    this.isRentalRestricted = data.isRentalRestricted;
    this.rentalRestrictionReason = data.rentalRestrictionReason;
    this.isReserved = data.isReserved;
    this.isRented = data.isRented;
    this.hasAlarmEnabled = data.hasAlarmEnabled;
    this.isOrientedUpright = data.isOrientedUpright;
    this.bikeIcon = data.icon ? data.icon : (this.isAvailableForRental ? bikeAvailableIcon : bikeRentedIcon);
    this.internalUseOnly = data.internalUseOnly;
    this.typeName = data.typeName;
    this.ongoingPause = data.ongoingPause;
    this.agps = data.agps;
    this.tenant = data.tenant;
    this.lastRideDate = data.lastRideDate;
    this.lastRideId = data.lastRideId;
    this.lastRideUrl = data.lastRideId ? `https://${this.tenant}.roovee.eu/reports/rides/${this.lastRideId}` : null;
    this.lockDeviceType = data.lockDeviceType;
  }
}
