import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikesActions';
import { BikeRecordModel } from '../models/bikes/BikeRecordModel';
import { TableModel } from '../models/TableModel';

export default function bikesReducer(state = Immutable(initialState.bikes.list), action) {
  switch (action.type) {
    case types.LOAD_BIKES: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: TableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_BIKES_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: TableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(b => new BikeRecordModel(b))),
      });
    }
    case types.LOAD_NEXT_BIKES: {
      return Immutable.merge(state, {
        pagedData: TableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_NEXT_BIKES_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        pagedData: TableModel.pageReady(state.pagedData, result.totalDataCount, result.data.map(b => new BikeRecordModel(b))),
      });
    }
    default:
      return state;
  }
}
