import api from '../api/bikeTypesApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  GET_BIKE_TYPES: 'GET_BIKE_TYPES',
  GET_BIKE_TYPES_SUCCESS: 'GET_BIKE_TYPES_SUCCESS',
  CREATE_BIKE_TYPE_SUBMIT: 'CREATE_BIKE_TYPE_SUBMIT',
  CREATE_BIKE_TYPE_SUCCESS: 'CREATE_BIKE_TYPE_SUCCESS',
  CREATE_BIKE_TYPE_FAILURE: 'CREATE_BIKE_TYPE_FAILURE',
  UPDATE_BIKE_TYPE_SUBMIT: 'UPDATE_BIKE_TYPE_SUBMIT',
  UPDATE_BIKE_TYPE_SUCCESS: 'UPDATE_BIKE_TYPE_SUCCESS',
  UPDATE_BIKE_TYPE_FAILURE: 'UPDATE_BIKE_TYPE_FAILURE',
  DELETE_BIKE_TYPE_SUBMIT: 'DELETE_BIKE_TYPE_SUBMIT',
  DELETE_BIKE_TYPE_SUCCESS: 'DELETE_BIKE_TYPE_SUCCESS',
  DELETE_BIKE_TYPE_FAILURE: 'DELETE_BIKE_TYPE_FAILURE',
};

export const actions = {
  getAll: () => (dispatch) => {
    dispatch({ type: types.GET_BIKE_TYPES });
    return api.getAll().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_BIKE_TYPES_SUCCESS, result: res.data || [] });
      }
    });
  },

  create: data => (dispatch) => {
    dispatch({ type: types.CREATE_BIKE_TYPE_SUBMIT });

    return api.create(data).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_BIKE_TYPE_SUCCESS, lock: res.data });
        dispatch(notificationActions.showSuccess('Utworzono nowy typ roweru'));
        return true;
      } else {
        dispatch({ type: types.CREATE_BIKE_TYPE_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia typu roweru'));
        return logger.error(res.data);
      }
    });
  },

  update: data => (dispatch) => {
    dispatch({ type: types.UPDATE_BIKE_TYPE_SUBMIT });

    return api.update(data.id, data).then(res => {
      if (res.ok) {
        dispatch({ type: types.UPDATE_BIKE_TYPE_SUCCESS, lock: res.data });
        dispatch(notificationActions.showSuccess('Typ roweru został zaktualizowany'));
        return true;
      } else {
        dispatch({ type: types.UPDATE_BIKE_TYPE_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas aktualizacji'));
        return logger.error(res.data);
      }
    });
  },

  delete: id => (dispatch) => {
    dispatch({ type: types.DELETE_BIKE_TYPE_SUBMIT });
    return api.delete(id).then(res => {
      if (res.ok) {
        dispatch({ type: types.DELETE_BIKE_TYPE_SUCCESS, id });
        dispatch(notificationActions.showSuccess('Typ roweru został usunięty'));
      } else {
        dispatch({ type: types.DELETE_BIKE_TYPE_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas usuwania typu roweru'));
        return logger.error(res.data);
      }
    });
  },
};
