import apiBase from './apiBase';

class TenantsApi {
  static getTenants(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize
      },
      filterBy: Object.assign({}, ...filterBy.map(x => ({ [x.id]: x.value }))),
      sortBy: Object.assign({}, ...sortBy.map(x=> ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/tenants/list`, payload);
  }

  static getDetails(id) {
    return apiBase.create().get(`/tenants/${id}`);
  }

  static create(data) {
    return apiBase.create().post('/tenants/', data);
  }

  static update(id, data) {
    return apiBase.create().put(`/tenants/${id}`, data);
  }

  static requireChangePassword(payload) {
    return apiBase.create().post(`/tenants/requirePassword`, payload);
  }

  static createOperatorAccount(id, data) {
    return apiBase.create().post(`/tenants/${id}/accounts`, data);
  }

  static tenantWithdrawDeposits(tenantId) {
    return apiBase.create().post(`/clients/wallet/withdraw/${tenantId}`);
  }

  static updateIncomeDistribution(id, data) {
    return apiBase.create().put(`/tenants/${id}/incomeDistribution`, data)
  }

  static getTenantIncomeDistribution(id) {
    return apiBase.create().get(`/tenants/${id}/incomeDistribution`)
  }
}

export default TenantsApi;
