import api from '../api/virtualLocksExternalTesterApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  GET_LOCKS: 'EXTERNAL_TESTER_GET_LOCKS',
  GET_LOCKS_SUCCESS: 'EXTERNAL_TESTER_GET_LOCKS_SUCCESS',
  OPEN_LOCK_SUBMIT: 'EXTERNAL_TESTER_OPEN_LOCK_SUBMIT',
  OPEN_LOCK_SUCCESS: 'EXTERNAL_TESTER_OPEN_LOCK_SUCCESS',
  OPEN_LOCK_FAILURE: 'EXTERNAL_TESTER_OPEN_LOCK_FAILURE',
  CLOSE_LOCK_SUBMIT: 'EXTERNAL_TESTER_CLOSE_LOCK_SUBMIT',
  CLOSE_LOCK_SUCCESS: 'EXTERNAL_TESTER_CLOSE_LOCK_SUCCESS',
  CLOSE_LOCK_FAILURE: 'EXTERNAL_TESTER_CLOSE_LOCK_FAILURE',
  VERIFICATION_PASSWORD_SUBMIT: 'VERIFICATION_PASSWORD_SUBMIT',
  VERIFICATION_PASSWORD_SUCCESS: 'VERIFICATION_PASSWORD_SUCCESS',
  VERIFICATION_PASSWORD_FAILURE: 'VERIFICATION_PASSWORD_FAILURE',
};

export const actions = {
  getAll: (password) => (dispatch) => {
    dispatch({ type: types.GET_LOCKS });
    return api.getAll(password).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_LOCKS_SUCCESS, result: res.data || [] });
      }
    });
  },

  open: (imei, password) => (dispatch) => {
    dispatch({ type: types.OPEN_LOCK_SUBMIT });
    return api.open(imei,password).then(res => {
      if (res.ok) {
        dispatch({ type: types.OPEN_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został otwarty'));
      } else {
        dispatch({ type: types.OPEN_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas otwierania zamka'));
        return logger.error(res.data);
      }
    });
  },

  close: (imei, password) => (dispatch) => {
    dispatch({ type: types.CLOSE_LOCK_SUBMIT });
    return api.close(imei, password).then(res => {
      if (res.ok) {
        dispatch({ type: types.CLOSE_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został zamknięty'));
      } else {
        dispatch({ type: types.CLOSE_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas zamykania zamka'));
        return logger.error(res.data);
      }
    });
  },

  verificationPassword: password => (dispatch) => {
    dispatch({ type: types.VERIFICATION_PASSWORD_SUBMIT });
    return api.verificationPassword({password}).then(res => {
      if (res.ok) {
        dispatch({ type: types.VERIFICATION_PASSWORD_SUCCESS, token: password });
      } else {
        dispatch({ type: types.VERIFICATION_PASSWORD_FAILURE });
        dispatch(notificationActions.showError('Błędne hasło'));
        return logger.error(res.data);
      }
    });
  },

};
