import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ShowIcon from '@material-ui/icons/FindInPage';
import routePaths from '../../routePaths';
import TextFilter from '../common/table/filters/TextFilter';
import SelectFilter from '../common/table/filters/SelectFilter';
import { VerificationBlockedOptions, AccountStatusOptions, TesterOptions, IsOverdraftOptions } from '../../models/mobileUserAccounts/MobileUserAccountsListModel';
import numberFormatter from '../../utils/numberFormatter';
import moment from 'moment';

const styles = {
  deleted: {
    color: '#ccc',
  },
  locked: {
    color: '#ff8f00',
  },
};

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: '',
    id: 'show_details',
    Cell: row => (
      <Link to={routePaths.mobileUserAccountDetails.replace(':id', row.original.userId)}>
        <IconButton style={{ width: 24, height: 24, padding: 2 }}>
          <ShowIcon />
        </IconButton>
      </Link>),
    width: 35,
    sortable: false,
    filterable: false,
  },
  {
    Header: 'Nazwisko',
    id: 'lastName',
    accessor: b => <span style={styles[b.status]}>{b.lastName}</span>,
    width: 150,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Imię',
    id: 'firstName',
    accessor: b => <span style={styles[b.status]}>{b.firstName}</span>,
    width: 120,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Nr telefonu',
    id: 'phoneNumber',
    accessor: b => <span style={styles[b.status]}>{b.phoneNumber}</span>,
    width: 100,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Email',
    id: 'email',
    accessor: b => <span style={styles[b.status]}>{b.email}</span>,
    width: 150,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: b => <span style={styles[b.status]}>{AccountStatusOptions[b.status].name}</span>,
    width: 120,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={AccountStatusOptions} label="filtruj..." />,
  },
  {
    Header: 'Konto testowe',
    id: 'isTester',
    accessor: b => <span style={styles[b.status]}>{b.isTester ? 'TAK' : 'NIE'}</span>,
    width: 140,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={TesterOptions} label="filtruj..." />,
  },
  {
    Header: 'Blokada kodów weryfikacyjnych',
    id: 'verificationBlocked',
    accessor: b => <span style={styles[b.status]}>{b.verificationBlocked ? 'TAK' : 'NIE'}</span>,
    width: 170,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={VerificationBlockedOptions} label="filtruj..." />,
  },
  {
    Header: 'ID',
    id: 'userId',
    accessor: b => <span style={styles[b.status]}>{b.userId}</span>,
    width: 260,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
    sortable: false,
  },
  {
    Header: 'Saldo',
    id: 'balance',
    accessor: b => `${numberFormatter.format(b.balance || 0)} zł`,
    width: 100,
    Filter: () => null,
    sortable: true,
  },
  {
    Header: 'Konto zadłużone',
    id: 'isOverdraft',
    accessor: b => <span style={styles[b.isOverdraft]}>{b.isOverdraft ? 'TAK' : 'NIE'}</span>,
    width: 170,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={IsOverdraftOptions} label="filtruj..." />,
  },
  {
    Header: 'Data powstania zadłużenia',
    id: 'overdraftDate',
    accessor: b => b.overdraftDate ? moment(b.overdraftDate).local().format('DD.MM.YYYY HH:mm') : '',
    width: 170,
    Filter: () => null,
    sortable: true,
  },
  {
    Header: 'Operator, u którego powstało zadłużenie',
    id: 'overdraftByTenant',
    accessor: b => <span style={styles[b.overdraftByTenant]}>{b.overdraftByTenant}</span>,
    width: 170,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
];
