import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/mobileUserAccountsActions';
import { withTranslation } from 'react-i18next';
import FiltersPanel from './FiltersPanel';
import Table from '../common/table/Table';
import { columns } from './Columns';

class AccountsList extends React.Component {
  componentDidMount() {
    const accounts = this.props.accounts;
    this.props.actions.loadFreshAccounts(1, accounts.paging.pageSize, accounts.sortedBy, accounts.filteredBy);
  }

  handleSortChange = sortBy => {
    const accounts = this.props.accounts;
    this.props.actions.loadFreshAccounts(1, accounts.paging.pageSize, sortBy, accounts.filteredBy);
  }

  handleFiltersChange = filterBy => {
    const accounts = this.props.accounts;
    this.props.actions.loadFreshAccounts(1, accounts.paging.pageSize, accounts.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange = nextPage => {
    this.props.actions.loadNextAccounts(nextPage);
  }

  handlePageSizeChange = nextPageSize => {
    const accounts = this.props.accounts;
    this.props.actions.loadFreshAccounts(1, nextPageSize, accounts.sortedBy, accounts.filteredBy);
  }

  handleExportToSpreadsheet = () => {
    const accounts = this.props.accounts;
    return this.props.actions.requestExcelExport(accounts.sortedBy, accounts.filteredBy);
  }

  render() {
    const { accounts } = this.props;

    return (
      <div style={{ margin: 16 }}>
        <FiltersPanel filteredBy={accounts.filteredBy} onFilteredChange={this.handleFiltersChange} />
        <Table
          columns={columns}
          data={accounts.records}
          filteredBy={accounts.filteredBy}
          sortedBy={accounts.sortedBy}
          paging={accounts.paging}
          onFilteredChange={this.handleFiltersChange}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          loading={accounts.loading}
          showExportToSpreadsheet
          onExportToSpreadsheet={this.handleExportToSpreadsheet}
          minRows={0}
          keyField="userId"
        />
      </div>
    );
  }
}

AccountsList.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  accounts: PropTypes.object,
};

AccountsList.defaultProps = {
  loading: false,
  accounts: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    accounts: state.mobileUserAccounts.list.pagedData,
    loading: state.mobileUserAccounts.list.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AccountsList));
