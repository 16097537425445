import React from 'react';
import PropTypes from 'prop-types';
import TextFilter from '../common/table/filters/TextFilter';
import SelectFilter from '../common/table/filters/SelectFilter';
import { AccountStatusOptions, VerificationBlockedOptions, TesterOptions, IsOverdraftOptions } from '../../models/mobileUserAccounts/MobileUserAccountsListModel';

class FiltersPanel extends React.Component {
  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete = deleteFunction => this.props.onFilteredChange(deleteFunction(this.props.filteredBy));

  render() {
    return (
      <div className="flex-row flex-wrap" style={{marginBottom: 16}}>
        <TextFilter filter={this.getFilterValue("lastName")} onDelete={this.handleDelete} readonly label="Nazwisko" />
        <TextFilter filter={this.getFilterValue("firstName")} onDelete={this.handleDelete} readonly label="Imię" />
        <TextFilter filter={this.getFilterValue("phoneNumber")} onDelete={this.handleDelete} readonly label="Nr telefonu" />
        <TextFilter filter={this.getFilterValue("email")} onDelete={this.handleDelete} readonly label="Email" />
        <TextFilter filter={this.getFilterValue("userId")} onDelete={this.handleDelete} readonly label="ID" />
        <SelectFilter filter={this.getFilterValue("status")} items={AccountStatusOptions} onDelete={this.handleDelete} readonly label="Status" />
        <SelectFilter filter={this.getFilterValue("isTester")} items={TesterOptions} onDelete={this.handleDelete} readonly label="Konto testowe" />
        <SelectFilter filter={this.getFilterValue("verificationBlocked")} items={VerificationBlockedOptions} onDelete={this.handleDelete} readonly label="Blokada kodów" />
        <SelectFilter filter={this.getFilterValue("isOverdraft")} items={IsOverdraftOptions} onDelete={this.handleDelete} readonly label="Konto zadłużone" />
        <TextFilter filter={this.getFilterValue("overdraftByTenant")} onDelete={this.handleDelete} readonly label="Operator, u którego powstało zadłużenie" />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
