import {TableModel} from "./TableModel";
import moment from "moment";

export class WithdrawalModel {
  constructor() {
    this.loading = false;
    this.pagedData = new TableModel(
      { pageNumber: 1, pageSize: 100 },
      [{ id: 'createdAt', value: 'desc' }],
      [{ id: 'startDate', value: moment().subtract(5, 'days').format() },]);
  }

}

export const WithdrawalStatus = {
  opened: { value: 'opened', name: 'Rozpatrywana' },
  accepted: { value: 'accepted', name: 'Zaakceptowana' },
  rejected: { value: 'rejected', name: 'Odrzucona' },
};
