import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/virtualLocksExternalTesterActions';
import { LockStateOptions } from '../models/bikes/BikesListModel';

export default function virtualLocksExternalTesterReducer(state = Immutable(initialState.virtualLocksExternalTester), action) {
  switch (action.type) {
    case types.GET_LOCKS: {
      return state.merge({ fetching: true, data: [] });
    }
    case types.GET_LOCKS_SUCCESS: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.OPEN_LOCK_SUCCESS: {
      const index = state.data.findIndex(l => l.imei === action.imei);
      const item = Object.assign({}, state.data[index]);
      const newState = Object.assign({}, item.state);
      newState.lockState = LockStateOptions.opened.value;
      item.state = newState;

      return state.merge({
        fetching: false,
        data: [...state.data.slice(0, index),
          item,
          ...state.data.slice(index + 1, state.length)],
      });
    }
    case types.CLOSE_LOCK_SUCCESS: {
      const index = state.data.findIndex(l => l.imei === action.imei);
      const item = Object.assign({}, state.data[index]);
      const newState = Object.assign({}, item.state);
      newState.lockState = LockStateOptions.closed.value;
      item.state = newState;

      return state.merge({
        fetching: false,
        data: [...state.data.slice(0, index),
          item,
          ...state.data.slice(index + 1, state.length)],
      });
    }
    case types.VERIFICATION_PASSWORD_SUBMIT: {
      return state.merge({
        logging: true,
        isLogin: false,
        token: null,
    })}
    case types.VERIFICATION_PASSWORD_SUCCESS: {
        return state.merge({
          logging: false,
          isLogin: true,
          token: action.token
        })}
    case types.VERIFICATION_PASSWORD_FAILURE: {
          return state.merge({
            logging: false,
            isLogin: false,
            token: null,
          })}
    default:
      return state;
  }
}
