import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import SelectFilter from '../common/table/filters/SelectFilter';
import {WithdrawalStatus} from "../../models/WithdrawalModel";
import DateTimeFilter from "../common/table/filters/DateTimeFilter";
import TextFilter from "../common/table/filters/TextFilter";
import {DateTimePicker} from "@material-ui/pickers";

const FiltersPanel = ({onFilteredChange, filteredBy}) => {


  const getFilterValue = useCallback((id) => {
    return filteredBy.find(f => f.id === id);
  },[filteredBy])

  const handleDelete = deleteFunction => onFilteredChange(deleteFunction(filteredBy));

  return (
      <div >
        <div className="flex-row flex-wrap" style={{marginBottom: 16}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <DateTimeFilter filter={getFilterValue("startDate")} onDelete={handleDelete} readonly label="Data początkowa " />
            <DateTimeFilter filter={getFilterValue("endDate")} onDelete={handleDelete} readonly label="Data końcowa" />
            <SelectFilter filter={getFilterValue("status")} items={WithdrawalStatus} onDelete={handleDelete} readonly label="Status" />
            <TextFilter filter={getFilterValue("firstName")} onDelete={handleDelete} readonly label="Imię" />
            <TextFilter filter={getFilterValue("lastName")} onDelete={handleDelete} readonly label="Nazwisko" />
            <TextFilter filter={getFilterValue("email")} onDelete={handleDelete} readonly label="Email" />
            <TextFilter filter={getFilterValue("phoneNumber")} onDelete={handleDelete} readonly label="Numer Telefonu" />
            <TextFilter filter={getFilterValue("address")} onDelete={handleDelete} readonly label="Address" />
            <TextFilter filter={getFilterValue("accountNumber")} onDelete={handleDelete} readonly label="Numer konta" />
          </div>
        </div>
        <div style={{marginBottom: 20}}>
          <div style={{ whiteSpace: 'nowrap'}}><h4>Zakres czasowy:</h4></div>
          <div style={{display: 'flex', gap: 50}}>
            <div style={{width: 150, margin: '0 10px'}}>
              <DateTimePicker
                label={'Początkowy'}
                ampm={false}
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                autoOk
                okLabel="OK"
                cancelLabel="Anuluj"
                clearLabel="Wyczyść"
                invalidLabel="Wybierz ..."
                onChange={value => {
                  onFilteredChange([...filteredBy.filter(x => x.id !== 'startDate'), {id: 'startDate', value: value ? value.toDate() : null}])
                }}
                format="DD.MM.YYYY HH:mm:ss"
                value={getFilterValue("startDate") ? getFilterValue("startDate").value : null}
                clearable
              />
            </div>
            <div style={{width: 150, margin: '0 10px'}}>
              <DateTimePicker
                label={'Końcowy'}
                ampm={false}
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                autoOk
                okLabel="OK"
                cancelLabel="Anuluj"
                clearLabel="Wyczyść"
                invalidLabel="Wybierz ..."
                onChange={value => {
                  onFilteredChange([...filteredBy.filter(x => x.id !== 'endDate'), {id: 'endDate', value: value ? value.toDate() : null}])
                }}
                format="DD.MM.YYYY HH:mm:ss"
                value={getFilterValue("endDate") ? getFilterValue("endDate").value : null}
                clearable
              />
            </div>
          </div>
        </div>
      </div>
  );

}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
