import publicAccessTokenApi from "../api/publicAccessTokenApi";
import {actions as notificationActions} from "./notificationActions";


export const types = {

  CREATE_PUBLIC_ACCESS_TOKEN: 'CREATE_PUBLIC_ACCESS_TOKEN',
  CREATE_PUBLIC_ACCESS_TOKEN_SUCCESS: 'CREATE_PUBLIC_ACCESS_TOKEN_SUCCESS',
  CREATE_PUBLIC_ACCESS_TOKEN_FAILED: 'CREATE_PUBLIC_ACCESS_TOKEN_FAILED',
  UPDATE_PUBLIC_ACCESS_TOKEN: 'UPDATE_PUBLIC_ACCESS_TOKEN',
  UPDATE_PUBLIC_ACCESS_TOKEN_SUCCESS: 'UPDATE_PUBLIC_ACCESS_TOKEN_SUCCESS',
  UPDATE_PUBLIC_ACCESS_TOKEN_FAILED: 'UPDATE_PUBLIC_ACCESS_TOKEN_FAILED',
  GET_PUBLIC_ACCESS_TOKEN: 'GET_PUBLIC_ACCESS_TOKEN',
  GET_PUBLIC_ACCESS_TOKEN_SUCCESS: 'GET_PUBLIC_ACCESS_TOKEN_SUCCESS',
  GET_PUBLIC_ACCESS_TOKEN_FAILED: 'GET_PUBLIC_ACCESS_TOKEN_FAILED',
  GET_TOKEN_PERMISSIONS: 'GET_TOKEN_PERMISSIONS',
  GET_TOKEN_PERMISSIONS_SUCCESS: 'GET_TOKEN_PERMISSIONS_SUCCESS',
  GET_TOKEN_PERMISSIONS_FAILED: 'GET_TOKEN_PERMISSIONS_FAILED',
  SHOW_NEW_TOKEN_DIALOG: 'SHOW_NEW_TOKEN_DIALOG',
  HIDDEN_NEW_TOKEN_DIALOG: 'HIDDEN_NEW_TOKEN_DIALOG',
  SHOW_TOKEN_DIALOG: 'SHOW_TOKEN_DIALOG',
  HIDDEN_TOKEN_DIALOG: 'HIDDEN_TOKEN_DIALOG',
  HIDDEN_CREATED_TOKEN_DIALOG: 'HIDDEN_CREATED_TOKEN_DIALOG',
  DEACTIVATION_TOKEN: 'DEACTIVATION_TOKEN',
  DEACTIVATION_TOKEN_SUCCESS: 'DEACTIVATION_TOKEN_SUCCESS',
  DEACTIVATION_TOKEN_FAILED: 'DEACTIVATION_TOKEN_FAILED',
  SHOW_DEACTIVATION_TOKEN_DIALOG: 'SHOW_DEACTIVATION_TOKEN_DIALOG',
  HIDDEN_DEACTIVATION_TOKEN_DIALOG: 'HIDDEN_DEACTIVATION_TOKEN_DIALOG'
}

const parsePermission = (permissionsForm) => {
  if(permissionsForm == null) return []
  const permissions = [];
  Object.keys(permissionsForm).forEach(key => {
    if(permissionsForm[key]){
      permissions.push(key)
    }
  })
  return permissions;
}

export const actions = {
  createToken: (name, permissionList, tenantId) => async (dispatch) => {
    const permissions = parsePermission(permissionList);
    dispatch({type: types.CREATE_PUBLIC_ACCESS_TOKEN })
    const result = await publicAccessTokenApi.createToken({name, permissions, tenantId})
    if(result.ok && result.data.success !== false){
      return dispatch({type: types.CREATE_PUBLIC_ACCESS_TOKEN_SUCCESS, data: result.data })
    } else {
      if(result.ok && !result.data.success){
        if(result.data.failure.errorCode === "errorCode.userManagement.publicApiAccessToken.tokenNameExist"){
          dispatch(notificationActions.showError('Podana nazwa tokenu już istnieje'));
          return
        }
      }
    }
    dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia tokenu'));
    return dispatch({type: types.CREATE_PUBLIC_ACCESS_TOKEN_FAILED })
  },
  updateToken: (id, permissionList, tenantId) => async (dispatch) => {
    const permissions = parsePermission(permissionList);
    dispatch({type: types.UPDATE_PUBLIC_ACCESS_TOKEN })
    const result = await publicAccessTokenApi.getUpdateToken(id, { permissions, tenantId})
    if(result.ok && result.data.success !== false){
      return dispatch({type: types.UPDATE_PUBLIC_ACCESS_TOKEN_SUCCESS});
    } else {
      if(result.ok && !result.data.success){
        if(result.data.failure.errorCode === "errorCode.userManagement.publicApiAccessToken.noFoundToken"){
          dispatch(notificationActions.showError('Nie znaleziono tokenu'));
        } else
        if(result.data.failure.errorCode === "errorCode.userManagement.publicApiAccessToken.inactive"){
          dispatch(notificationActions.showError('Token nieaktywny'));
        }
      }
    }
    dispatch(notificationActions.showError('Wystąpił problem podczas aktualizacji tokenu'));
    return dispatch({type: types.UPDATE_PUBLIC_ACCESS_TOKEN_FAILED});
  },
  deactivateTokenDialog: (tenantId, id) => async (dispatch) => {
    dispatch({type: types.DEACTIVATION_TOKEN })
    const result = await publicAccessTokenApi.deactivatePermissions(tenantId, id);
    if(result.ok && result.data.success !== false){
      return dispatch({type: types.DEACTIVATION_TOKEN_SUCCESS});
    } else {
      if(result.ok && !result.data.success){
        if(result.data.failure.errorCode === "errorCode.userManagement.publicApiAccessToken.noFoundToken"){
          dispatch(notificationActions.showError('Nie znaleziono tokenu'));
        } else
        if(result.data.failure.errorCode === "errorCode.userManagement.publicApiAccessToken.inactive"){
          dispatch(notificationActions.showError('Token nieaktywny'));
        }
      }
    }
    dispatch(notificationActions.showError('Wystąpił problem podczas deaktywacji tokenu'));
    return dispatch({type: types.DEACTIVATION_TOKEN_FAILED});
  },
  getTokens: (tenantId) => async (dispatch) => {
    dispatch({type: types.GET_PUBLIC_ACCESS_TOKEN })
    const result = await publicAccessTokenApi.getTokens(tenantId)
    if(result.ok){
      return dispatch({type: types.GET_PUBLIC_ACCESS_TOKEN_SUCCESS, data: result.data })
    } else {
      dispatch(notificationActions.showError('Wystąpił problem podczas pobierania listy tokenów'));
    }
    return dispatch({type: types.GET_PUBLIC_ACCESS_TOKEN_FAILED })
  },
  getTokenPermissions: () => async (dispatch) => {
    dispatch({type: types.GET_TOKEN_PERMISSIONS })
    const result = await publicAccessTokenApi.getPermissions()
    if(result.ok){
      return dispatch({type: types.GET_TOKEN_PERMISSIONS_SUCCESS, data: result.data })
    } else {
      dispatch(notificationActions.showError('Wystąpił problem podczas pobierania listy uprawnień'));
    }
    return dispatch({type: types.GET_TOKEN_PERMISSIONS_FAILED })
  },
  showNewTokenDialog: () => dispatch => {
    dispatch({type: types.SHOW_NEW_TOKEN_DIALOG })
  },
  hiddenNewTokenDialog: () => dispatch => {
    dispatch({type: types.HIDDEN_NEW_TOKEN_DIALOG })
  },
  hiddenCreatedTokenDialog: () => dispatch => {
    dispatch({type: types.HIDDEN_CREATED_TOKEN_DIALOG })
  },
  hiddenTokenDialog: () => dispatch => {
    dispatch({type: types.HIDDEN_TOKEN_DIALOG })
  },
  showTokenDialog: (edit, data) => dispatch => {
    dispatch({type: types.SHOW_TOKEN_DIALOG, result: {edit, data} })
  },
  hiddenDeactivateDialog: () => dispatch => {
    dispatch({type: types.HIDDEN_DEACTIVATION_TOKEN_DIALOG })
  },
  showDeactivateDialog: (tenantId, id) => dispatch => {
    dispatch({type: types.SHOW_DEACTIVATION_TOKEN_DIALOG, data: {tenantId, id} })
  }

}
