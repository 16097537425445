import React, {useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import {change, Field, formValueSelector, reduxForm, untouch} from "redux-form";
import FormSelect from "../form/FormSelect";
import FormTextField from "../form/FormTextField";
import {ReportRule} from "./ReportRule";
import {useTranslation} from "react-i18next";
import FormCheckBox from "../form/FormCheckBox";
import {connect, useDispatch, useSelector} from "react-redux";
import {ReportDataFor} from "./ReportDataFor";

const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const validation = values => {
  const errors = {};
  const requiredFields = [
    'reportTemplateId',
    'rule',
    'email',
  ];

  if (values.rule === 2) {
    requiredFields.push('dayOfGeneration')
  }

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Pole jest wymagane';
    }
  });

  if (!values.isGeneratePdf && !values.isGenerateXlsx) {
    errors.selectedType = 'Wymagany jest wybór jednego z pól';
    errors.isGeneratePdf = '';
    errors.isGenerateXlsx = '';
  }
  if (values.email && !validEmailRegex.test(values.email)) {
    errors.email = 'Adres email jest nieprawidłowy';
  }
  if (values.rule === 2 && values.dayOfGeneration && (values.dayOfGeneration < 1 || values.dayOfGeneration > 28)) {
    errors.dayOfGeneration = 'Wartość musi być większa lub równa 1 oraz mniejsza lub równa 28';
  }
  return errors;
}



let NewReportForm = ({handleSubmit, templateList, reportTemplateId, rule}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newReportFormState = useSelector(state => state.form.newReportForm);
  const AccountingReportIds = templateList.map(x =>{
    return ['Księgowy szczegółowy', 'Księgowy dobowy', 'Księgowy miesięczny', 'Raport zbiorczy'].includes(x.name) ?
      x.id : null;
  });

  const reportRule = useMemo(() => {
    return ReportRule.map(x => {
      return {
        value: x.id,
        name: t(`automaticReport.reportRule.${x.name}`)
      }
    })

  },[t])

  const reportDataFor = useMemo(() => {
      return ReportDataFor.map(x => {
        return {
          value: x.id,
          name: x.name
        }
      })
    }, [t])

  const templateListMemo = useMemo(() => {
    const reports = templateList.map(x => {
      return {
        value: x.id,
        name: x.name
      }
    })
    return [...reports]
  },[templateList])

  useEffect(() => {
    if (rule !== 2) {
      dispatch(change('newReportForm', 'dayOfGeneration', null ));
      dispatch(untouch('newReportForm', ['dayOfGeneration'] ));

    }
  }, [rule]);

  return(
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="reportTemplateId"
          component={FormSelect}
          label={t('automaticReport.newReportDialog.reportType')}
          style={{width: 300}}
          values={templateListMemo}
        />
      </div>
      <div>
        <Field
          name="generatedFor"
          component={FormSelect}
          label={t('automaticReport.newReportDialog.dataFor')}
          style={{width: 300}}
          values={reportDataFor}
          readonly={!(AccountingReportIds.includes(reportTemplateId))}
        />
      </div>
      <div>
        <Field
          name="rule"
          component={FormSelect}
          label={t('automaticReport.newReportDialog.ruleType')}
          style={{width: 300}}
          values={reportRule ? reportRule : []}
        />
      </div>
      <div>
        <Field
          name="dayOfGeneration"
          component={FormTextField}
          label="Dzień generacji raportu"
          type="number"
          readonly={rule !== 2}
          disabled={rule !== 2}
        />
      </div>
      <div>
        <Field
          name="email"
          component={FormTextField}
          label={t('automaticReport.newReportDialog.email')}
          style={{width: 300}}
        />
      </div>
      <div>
        <Field
          name="isGeneratePdf"
          component={FormCheckBox}
          label={t('automaticReport.newReportDialog.generatePdf')}
        />
      </div>
      <div>
        <Field
          name="isGenerateXlsx"
          component={FormCheckBox}
          label={t('automaticReport.newReportDialog.generateXlsx')}
        />
      </div>
      {newReportFormState && newReportFormState.syncErrors && newReportFormState.syncErrors.selectedType && <div>
        {newReportFormState.syncErrors.selectedType}
      </div>}
    </form>
  )
}

NewReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  templateList: PropTypes.array.isRequired,
  reportTemplateId: PropTypes.string,
  rule: PropTypes.number
};

NewReportForm = reduxForm({
  form: 'newReportForm',
  validate: validation,
})(NewReportForm);

const selector = formValueSelector('newReportForm')
NewReportForm = connect(state => {
  const reportTemplateId = selector(state, 'reportTemplateId')
  const rule = selector(state, 'rule')

  return {
    reportTemplateId,
    rule
  }
})(NewReportForm)


export default NewReportForm;
