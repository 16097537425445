import React from 'react'
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";
import FormTextField from "./FormTextField";
import {useTranslation} from "react-i18next";
import FormCheckBox from "./FormCheckBox";

let TokenForm = ({onSubmit, permissions, edit, preview}) => {

  const { t } = useTranslation()
  return(
    <form onSubmit={onSubmit}>
      <div>
        <Field
          name="name"
          disabled={edit || preview}
          component={FormTextField}
          label={t('apiAccess.tokenForm.name')}
          style={{ width: 300 }}
        />
      </div>
      <div>
        <h4>{t('apiAccess.tokenForm.permissions')}</h4>
        {permissions.map((x) => (
          <div key={x.id}>
            <Field
              disabled={preview}
              name={`permissions.${x.id}`}
              component={FormCheckBox}
              label={x.name}
            />
          </div>
        ))}
      </div>
    </form>
  )
}

TokenForm.defaultProps = {
  edit: false,
  preview: false
}

TokenForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  edit: PropTypes.bool,
  preview: PropTypes.bool
};

TokenForm = reduxForm({
  form: 'tokenForm',
})(TokenForm);

export default TokenForm;
