import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const styles = () => ({
  loadingIndicator: {
    textAlign: 'center',
  },
});

const LoadingIndicator = ({ classes, fetching, children }) => (
  <React.Fragment>
    {fetching
      ? <div className={classes.loadingIndicator}><CircularProgress size={50} /></div>
      : children}
  </React.Fragment>
);

LoadingIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(LoadingIndicator);
