import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

class MyProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: undefined,
      open: false,
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuRequestClose = this.handleMenuRequestClose.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleMenuClick(event) {
    this.setState({
      open: true,
      anchor: event.currentTarget,
    });
  }

  handleMenuRequestClose() {
    this.setState({
      open: false,
    });
  }

  handleSignOut() {
  }

  render() {
    const { t, onSignOut } = this.props;

    return (
      <div>
        <IconButton
          color="default"
          onClick={this.handleMenuClick}
          aria-label="Open profile"
          aria-owns={this.state.open ? 'profile-menu' : null}
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={this.state.anchor}
          open={this.state.open}
          onClose={this.handleMenuRequestClose}
        >
          <MenuItem
            key="signOut"
            onClick={onSignOut}
          >{t('appBar.menu.signOut')}</MenuItem>
        </Menu>
      </div>
    );
  }
}

MyProfileMenu.propTypes = {
  t: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
};

export default withTranslation()(MyProfileMenu);
