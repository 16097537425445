import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/bikeTypesActions';

export default function bikeTypesReducer(state = Immutable(initialState.bikeTypes), action) {
  switch (action.type) {
    case types.GET_BIKE_TYPES: {
      return state.merge({ fetching: true, data: [] });
    }
    case types.GET_BIKE_TYPES_SUCCESS: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.DELETE_BIKE_TYPE_SUCCESS: {
      const id = action.id;

      return state.merge({
        fetching: false,
        data: state.data.filter(b => b.id !== id),
      });
    }
    default:
      return state;  
  }
}
