import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/FormTextField';
import validate from 'validate.js';

const validation = values => validate(
  values,
  { reason: { presence: { message: 'Wprowadzenie powodu jest wymagane' } } },
  { fullMessages: false }
);

let RestrictRentalsForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          autoFocus
          name="reason"
          component={FormTextField}
          label="Powód"
          fullWidth
        />
      </div>
    </form>
  );
};

RestrictRentalsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

RestrictRentalsForm = reduxForm({
  form: 'restrictRentalsForm',
  validate: validation,
})(RestrictRentalsForm);

export default RestrictRentalsForm;
