import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation} from 'react-i18next';
import QRCode from 'qrcode.react';
import {
  Button, Card, CardContent, Typography, CardActions, IconButton, Accordion, AccordionSummary, AccordionDetails,
  CardHeader, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import LockIcon from '@material-ui/icons/Lock';
import { LockStateOptions } from '../../models/bikes/BikesListModel';

class VirtualLockDetails extends React.Component {
  state = {
    nfcDialogOpened: false,
    nfcDialogSubmitting: false,
    nfcCardId: null,
  };

  handleOpenNfcDialog = () => {
    this.setState({ nfcDialogOpened: true });
  }

  handleSubmitNfcDialog = () => {
    this.setState({ nfcDialogSubmitting: true });
    this.props
      .onTapNfcCard(this.props.lock.imei, this.state.nfcCardId)
      .then(ok => {
        if (ok) this.setState({ nfcDialogOpened: false, nfcDialogSubmitting: false, nfcCardId: null });
        else this.setState({ nfcDialogSubmitting: false, });
      });
  };

  handleCloseNfcDialog = () => {
    this.setState({ nfcDialogOpened: false, nfcCardId: null });
  };

  renderNfcCardDialog = () => (
    <Dialog open={this.state.nfcDialogOpened}>
      <DialogTitle>Symulacja zbliżenia karty NFC</DialogTitle>
      <DialogContent>
        <TextField fullWidth placeholder="Wprowadź identyfikator karty" onChange={event => this.setState({ nfcCardId: event.target.value })} />
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleCloseNfcDialog} disabled={this.state.nfcDialogSubmitting}>
          Anuluj
          </Button>
        <Button
          onClick={this.handleSubmitNfcDialog}
          disabled={this.state.nfcDialogSubmitting || !this.state.nfcCardId}
          color="secondary"
        >
          Zbliż
          </Button>
      </DialogActions>
    </Dialog>
  );


  render() {
    const { lock, onDelete, onEnable, onDisable, onOpen, onClose, t } = this.props;
    return (
      <Fragment>
        {this.renderNfcCardDialog()}
        <Card elevation={lock.state.enabled ? 4 : 0}>
          <CardHeader
            title={lock.imei}
            subheader={lock.description}
            avatar={lock.state.lockState === LockStateOptions.opened.value ? <BikeIcon color="secondary" /> : <LockIcon color="disabled" />}
            style={!lock.state.enabled ? { background: '#f2f2f2' } : null}
          />
          <CardContent style={!lock.state.enabled ? { background: '#f2f2f2' } : null}>
            {lock.state.enabled && <React.Fragment>
              <Accordion >
                <AccordionSummary >
                  <Typography variant="button">{t('virtualLocks.lockDetails.showQrCode')}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                  <Typography variant="caption" gutterBottom>{lock.qrCode}</Typography>
                  <div>
                    <QRCode value={lock.qrCode} />
                  </div>
                </AccordionDetails>
              </Accordion>
              <div>
                {lock.state.lockState === LockStateOptions.opened.value
                  ? <Button variant="contained" color="secondary" fullWidth onClick={() => onClose(lock.imei)}>{t('virtualLocks.lockDetails.closeButton')}</Button>
                  : <Button variant="contained" color="primary" fullWidth onClick={() => onOpen(lock.imei)}>{t('virtualLocks.lockDetails.openButton')}</Button>}
                <Button variant="contained" color="secondary" fullWidth onClick={this.handleOpenNfcDialog} > {t('virtualLocks.lockDetails.tapNfcCardButton')}</Button>
              </div>
            </React.Fragment>}
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            {lock.state.enabled
              ? <Button size="small" onClick={() => onDisable(lock.imei)}>{t('virtualLocks.lockDetails.disableButton')}</Button>
              : <Button size="small" onClick={() => onEnable(lock.imei)} color="secondary">{t('virtualLocks.lockDetails.enableButton')}</Button>}
            <IconButton size="small" onClick={() => onDelete(lock.imei)}><DeleteIcon /></IconButton>
          </CardActions>
        </Card>
      </Fragment>
    );
  }
}

VirtualLockDetails.propTypes = {
  t: PropTypes.func.isRequired,
  lock: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEnable: PropTypes.func.isRequired,
  onDisable: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onTapNfcCard: PropTypes.func.isRequired,
};

export default withTranslation()(VirtualLockDetails);
