import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";

const ConfirmRemoveReportDialog = ({open, onClose, onSubmit}) => {

  return (
    <Dialog open={open} fullWidth={true}  onClose={onClose}  >
      <DialogTitle>Potwierdzenie usunięcia raportu</DialogTitle>
      <DialogContent>
        <>Czy na pewno chcesz usunąć raport? Zostanie on <span style={{fontWeight: 'bold'}}>trwale usunięty</span> z bazy danych.</>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Anuluj
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
         Usuń
        </Button>
      </DialogActions>
    </Dialog>
  )

}

ConfirmRemoveReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmRemoveReportDialog;
