import React, {useEffect, useState} from "react";
import DocumentTitle from "react-document-title";
import {Button, Paper} from "@material-ui/core";
// eslint-disable-next-line import/no-unresolved
import GenerateWithdrawalReportDialog from "./GenerateWithdrawalReportDialog";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions} from "../../actions/reportsActions";
import PropTypes from "prop-types";
import WithdrawalReportList from "./WithdrawalReportList";

const WithdrawalReport = ({actions, fetching, withdrawals}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOnSubmit = async (data) =>  {
    const result = await actions.withdrawalReport(data);
    if(result.success) {
      setOpenDialog(false)
    }
  }

  return (
    <DocumentTitle title={'Raport formularzu zwrotnego'}>
      <Paper style={{ marginRight: 60, marginLeft: 60, marginTop: 80, padding: 16 }}>
        <div style={{ textAlign: 'left', paddingTop: 20}}>
          <div style={{marginBottom: 10}}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              Wygeneruj raport
            </Button>
          </div>
          <WithdrawalReportList/>
        </div>
        <GenerateWithdrawalReportDialog
          open={openDialog}
          loading={false}
          onCancel={() => {setOpenDialog(false)}}
          submitting={fetching}
          onSubmit={handleOnSubmit} />
      </Paper>
    </DocumentTitle>
  )
}
WithdrawalReport.propTypes = {
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  withdrawals: PropTypes.object
}

WithdrawalReport.defaultProps = {
  withdrawals: {},
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  const reportState = state.reports;
  return {
    fetching: reportState.fetchingWithdrawalReport,
    withdrawals: reportState.withdrawalsData.pagedData,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalReport);

