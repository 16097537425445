import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RestrictRentalsAction from './RestrictRentalsAction';
import AllowRentalsAction from './AllowRentalsAction';
import SendLockMessageAction from './SendLockMessageAction';

class ListItemMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = refreshList => {
    this.setState({ anchorEl: null });
    if (refreshList) this.props.onActionExecuted();
  };

  render() {
    const { anchorEl } = this.state;
    const { itemId } = this.props;
    const { deviceType } = this.props;

    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'list-item-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{width: 30, height: 30}}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="list-item-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleClose()}
        >
          <AllowRentalsAction menuItem={true} bikesIds={[itemId]} onActionExecuted={() => this.handleClose(true)} />
          <RestrictRentalsAction menuItem={true} bikesIds={[itemId]} onActionExecuted={() => this.handleClose(true)} />
          <SendLockMessageAction menuItem={true} bikesIds={[itemId]} deviceTypes={[deviceType]} onActionExecuted={() => this.handleClose(true)} />
        </Menu>
      </React.Fragment>
    );
  }
}

ListItemMenu.propTypes = {
  itemId: PropTypes.string.isRequired,
  deviceType: PropTypes.object.isRequired,
  onActionExecuted: PropTypes.func.isRequired,
};

export default ListItemMenu;
