import React from 'react';
import PropTypes from 'prop-types';
import SendLockMessageAction from './SendLockMessageAction';
import AllowRentalsAction from './AllowRentalsAction';
import RestrictRentalsAction from './RestrictRentalsAction';

const BikesActions = ({ bikesIds, deviceTypes, onActionExecuted }) => {
  const disabled = bikesIds.length ? false : true;
  return (
    <div>
      <AllowRentalsAction bikesIds={bikesIds} disabled={disabled} onActionExecuted={onActionExecuted} />
      <RestrictRentalsAction bikesIds={bikesIds} disabled={disabled} onActionExecuted={onActionExecuted} />
      <SendLockMessageAction bikesIds={bikesIds} deviceTypes={deviceTypes} disabled={disabled} onActionExecuted={onActionExecuted} />
    </div>
  );
};

BikesActions.propTypes = {
  bikesIds: PropTypes.array.isRequired,
  deviceTypes: PropTypes.array.isRequired,
  onActionExecuted: PropTypes.func.isRequired,
};

export default BikesActions;
