import tenantsApi from '../api/tenantsApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  LOAD_TENANTS: 'LOAD_TENANTS',
  LOAD_TENANTS_SUCCESS: 'LOAD_TENANTS_SUCCESS',
  LOAD_NEXT_TENANTS: 'LOAD_NEXT_TENANTS',
  LOAD_NEXT_TENANTS_SUCCESS: 'LOAD_NEXT_TENANTS_SUCCESS',
  GET_TENANT: 'GET_TENANT',
  GET_TENANT_SUCCESS: 'GET_TENANT_SUCCESS',
  GET_TENANT_FAILURE: 'GET_TENANT_FAILURE',
  GET_TENANT_FINANCIAL_DISTRIBUTION: 'GET_TENANT_FINANCIAL_DISTRIBUTION',
  GET_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS: 'GET_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS',
  GET_TENANT_FINANCIAL_DISTRIBUTION_FAILURE: 'GET_TENANT_FINANCIAL_DISTRIBUTION_FAILURE',
  UPDATE_TENANT_FINANCIAL_DISTRIBUTION: 'UPDATE_TENANT_FINANCIAL_DISTRIBUTION',
  UPDATE_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS: 'UPDATE_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS',
  UPDATE_TENANT_FINANCIAL_DISTRIBUTION_FAILURE: 'UPDATE_TENANT_FINANCIAL_DISTRIBUTION_FAILURE',
  CREATE_TENANT: 'CREATE_TENANT',
  CREATE_TENANT_SUCCESS: 'CREATE_TENANT_SUCCESS',
  CREATE_TENANT_FAILURE: 'CREATE_TENANT_FAILURE',
  UPDATE_TENANT: 'UPDATE_TENANT',
  UPDATE_TENANT_SUCCESS: 'UPDATE_TENANT_SUCCESS',
  UPDATE_TENANT_FAILURE: 'UPDATE_TENANT_FAILURE',
  CREATE_OPERATOR_ACCOUNT: 'CREATE_OPERATOR_ACCOUNT',
  CREATE_OPERATOR_ACCOUNT_SUCCESS: 'CREATE_OPERATOR_ACCOUNT_SUCCESS',
  CREATE_OPERATOR_ACCOUNT_FAILURE: 'CREATE_OPERATOR_ACCOUNT_FAILURE',
  REQUIRED_CHANGE_PASSWORD_ACCOUNT: 'REQUIRED_CHANGE_PASSWORD_ACCOUNT',
  REQUIRED_CHANGE_PASSWORD_ACCOUNT_SUCCESS: 'REQUIRED_CHANGE_PASSWORD_ACCOUNT_SUCCESS',
  REQUIRED_CHANGE_PASSWORD_ACCOUNT_FAILURE: 'REQUIRED_CHANGE_PASSWORD_ACCOUNT_FAILURE',
  TENANT_WITHDRAW_SUBMIT: 'TENANT_WITHDRAW_SUBMIT',
  TENANT_WITHDRAW_SUCCESS: 'TENANT_WITHDRAW_SUCCESS',
  TENANT_WITHDRAW_FAILURE: 'TENANT_WITHDRAW_FAILURE',
};

export const actions = {

  tenantWithdrawDeposits: tenantId => (dispatch) => {
    dispatch({ type: types.TENANT_WITHDRAW_SUBMIT, tenantId });
    return tenantsApi.tenantWithdrawDeposits(tenantId).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.TENANT_WITHDRAW_SUCCESS, tenantId });
        dispatch(notificationActions.showSuccess('Zlecono wypłatę środków'));
      } else {
        dispatch({ type: types.TENANT_WITHDRAW_FAILURE, tenantId });
        dispatch(notificationActions.showError('Operacja nie powiodła się'));
        return logger.error(res.data);
      }
    });
  },

  loadFreshTenants: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.LOAD_TENANTS, pageNumber, pageSize, sortBy, filterBy });
    return tenantsApi.getTenants(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_TENANTS_SUCCESS, result: res.data || {} });
      }
    });
  },

  loadNextTenants: (pageNumber) => (dispatch, getState) => {
    dispatch({ type: types.LOAD_NEXT_TENANTS, pageNumber });
    const sortBy = getState().tenants.list.pagedData.sortedBy;
    const filterBy = getState().tenants.list.pagedData.filteredBy;
    const pageSize = getState().tenants.list.pagedData.paging.pageSize;

    return tenantsApi.getTenants(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.LOAD_NEXT_TENANTS_SUCCESS, result: res.data || {} });
      }
    });
  },

  getDetails: id => dispatch => {
    dispatch({ type: types.GET_TENANT });

    return tenantsApi
      .getDetails(id)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_TENANT_SUCCESS, data: response.data });
        }

        dispatch({ type: types.GET_TENANT_FAILURE });
        dispatch(notificationActions.showError('Wystąpił błąd podczas pobierania szczegółów operatora'));
        return logger.error(response);
      });
  },

  getIncomeDistribution: id => dispatch => {
    dispatch({ type: types.GET_TENANT_FINANCIAL_DISTRIBUTION });

    return tenantsApi
      .getTenantIncomeDistribution(id)
      .then(response => {
        if (response.ok) {
          return dispatch({ type: types.GET_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS, data: response.data });
        }

        dispatch({ type: types.GET_TENANT_FINANCIAL_DISTRIBUTION_FAILURE });
        dispatch(notificationActions.showError('Wystąpił błąd podczas pobierania rozkładu prychodów operatora'));
        return logger.error(response);
      });
  },
  updateIncomeDistribution: (id, data) => dispatch => {
    dispatch({ type: types.UPDATE_TENANT_FINANCIAL_DISTRIBUTION });

    return tenantsApi
      .updateIncomeDistribution(id, data)
      .then(response => {
        if (response.ok) {
          dispatch({type: types.UPDATE_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS});
          dispatch(notificationActions.showSuccess('Rozkład przychodów dla operatora został zaktualizowany'));
          return true;
        } else {
          dispatch({type: types.UPDATE_TENANT_FINANCIAL_DISTRIBUTION_FAILURE});
          dispatch(notificationActions.showError('Wystąpił problem podczas aktualizacji rozkładu przychodów dla operatora'));
          return logger.error(response.data);
        }
      });
  },

  create: data => dispatch => {
    dispatch({ type: types.CREATE_TENANT });

    return tenantsApi.create(data).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_TENANT_SUCCESS });
        dispatch(notificationActions.showSuccess('Utworzono nowego operatora'));
        return true;
      } else {
        dispatch({ type: types.CREATE_TENANT_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia operatora'));
        return logger.error(res.data);
      }
    });
  },

  update: data => dispatch => {
    dispatch({ type: types.UPDATE_TENANT });

    return tenantsApi.update(data.id, data).then(res => {
      if (res.ok) {
        dispatch({ type: types.UPDATE_TENANT_SUCCESS });
        dispatch(notificationActions.showSuccess('Dane operatora zostały zaktualizowane'));
        return true;
      } else {
        dispatch({ type: types.UPDATE_TENANT_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas aktualizacji'));
        return logger.error(res.data);
      }
    });
  },
  createOperatorAccount: (id, data) => dispatch => {
    dispatch({ type: types.CREATE_OPERATOR_ACCOUNT });

    return tenantsApi.createOperatorAccount(id, data).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_OPERATOR_ACCOUNT_SUCCESS, data });
        dispatch(notificationActions.showSuccess('Utworzono nowe konto operatora'));
        return true;
      } else {
        dispatch({ type: types.CREATE_OPERATOR_ACCOUNT_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia konta operatora'));
        return logger.error(res.data);
      }
    });
  },
  requirePassword: (tenant, id, mode) => dispatch => {
    dispatch({ type: types.REQUIRED_CHANGE_PASSWORD_ACCOUNT });
    let obj = {};
    if (mode === "allUser") {
      obj = {
        tenant: tenant,
        mode: mode
      }
    } else if (mode === "oneUser"){
      obj = {
        tenant: tenant,
        mode: mode,
        operatorId: id
      }
    } else {
      dispatch(notificationActions.showError('Wystąpił problem podczas ustawiania wymogu zmiany hasła'));
      return false
    }
    tenantsApi.requireChangePassword(obj).then(res => {
      if (res.ok) {
        dispatch({type: types.REQUIRED_CHANGE_PASSWORD_ACCOUNT_SUCCESS});
        dispatch(notificationActions.showSuccess('Ustawiono wymóg zmiany hasła'));
        return true;
      } else {
        dispatch({type: types.REQUIRED_CHANGE_PASSWORD_ACCOUNT_FAILURE});
        dispatch(notificationActions.showError('Wystąpił problem podczas ustawiania wymogu zmiany hasła'));
        return logger.error(res.data);
      }
    }).catch(() => {
      dispatch(notificationActions.showError('Wystąpił problem podczas ustawiania wymogu zmiany hasła'));
    })
  },
};
