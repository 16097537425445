import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FormSelect = ({
  input,
  label,
  meta: { touched, error },
  values,
  readonly,
  ...custom
}) => (
    <FormControl {...custom} error={touched && error} margin="dense">
      <InputLabel htmlFor="dropdown">{label}</InputLabel>
      <Select
        value={input.value}
        disabled={readonly === true}
        onChange={event => input.onChange(event.target.value)}
        input={<Input id={`dropdown`} />}
      >
        {Object.keys(values).map(key => (
          <MenuItem key={values[key].value} value={values[key].value}>{values[key].name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

FormSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  values: PropTypes.object,
  readonly: PropTypes.bool,
};

export default FormSelect;
