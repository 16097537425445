import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React from "react";
import compose from "recompose/compose";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";


const ModalRequireChangePassword = ({ data, t, mode, onSubmit, onCancel, user, tenant }) => {
  return (
    <Dialog open={!!data} fullWidth={true}  onClose={onCancel}  >
      <DialogTitle>Wymuszenie zmiany hasła</DialogTitle>
      <DialogContent>
        {mode === "oneUser" && <> Czy na pewno chcesz wymusić zmianę hasła dla operatora <strong>&ldquo;{user}&ldquo;,</strong> podczas jego następnego logowania do panelu operatora?</>}
        {mode === "allUser" && <> Czy na pewno chcesz wymusić zmianę hasła dla <strong>wszystkich</strong> operatorów tenanta <strong>&ldquo;{tenant}&ldquo;</strong>, podczas ich następnego logowania do panelu operatora?</>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
        >
          {t('common.cancelButton')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
        >
          {t('virtualLocks.newLockDialog.submitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

ModalRequireChangePassword.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['allUser','oneUser']),
  tenant: PropTypes.string.isRequired,
  user: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ModalRequireChangePassword.defaultProps = {
  user: '',
}

export default compose(
  connect(),
  withTranslation()
)(ModalRequireChangePassword);
