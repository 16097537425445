import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/reportsActions';
import {TableModel} from "../models/TableModel";

export default function reportsReducer(state = Immutable(initialState.reports), action) {
  switch (action.type) {
    case types.LOAD_REPORTS_TEMPLATE_LIST: {
      return Immutable.merge(state, {
        fetchingTemplateList: true,
        templateList: [],
      });
    }
    case types.LOAD_REPORTS_TEMPLATE_LIST_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        fetchingTemplateList: false,
        templateList: result,
      });
    }
    case types.LOAD_REPORTS_TEMPLATE_LIST_FAILED: {
      return Immutable.merge(state, {
        fetchingTemplateList: false,
        templateList: [],
      });
    }
    case types.GET_REPORTS_CONFIGURATION_LIST: {
      return Immutable.merge(state, {
        fetchingReportConfigurationList: true,
        reportConfigurationList: [],
      });
    }
    case types.GET_REPORTS_CONFIGURATION_LIST_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        fetchingReportConfigurationList: false,
        reportConfigurationList: result,
      });
    }
    case types.GET_REPORTS_CONFIGURATION_LIST_FAILED: {
      return Immutable.merge(state, {
        fetchingReportConfigurationList: false,
        reportConfigurationList: [],
      });
    }
    case types.CREATE_REPORT_CONFIGURATION: {
      return Immutable.merge(state, {
        submitting: true,
      });
    }
    case types.CREATE_REPORT_CONFIGURATION_SUCCESS: {
      return Immutable.merge(state, {
        submitting: false,
      });
    }
    case types.CREATE_REPORT_CONFIGURATION_FAILED: {
      return Immutable.merge(state, {
        submitting: false,
      });
    }
    case types.GET_REPORTS_CONFIGURATION_HISTORY: {
      return Immutable.merge(state, {
        fetchingReportConfigurationHistory: true,
        reportConfigurationHistory: null,
      });
    }
    case types.GET_REPORTS_CONFIGURATION_HISTORY_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        fetchingReportConfigurationHistory: false,
        reportConfigurationHistory: result,
      });
    }
    case types.GET_REPORTS_CONFIGURATION_HISTORY_FAILED: {
      return Immutable.merge(state, {
        fetchingReportConfigurationHistory: false,
        reportConfigurationHistory: null,
      });
    }
    case types.GET_WITHDRAWAL_REPORT: {
      return Immutable.merge(state, {
        fetchingWithdrawalReport: true,
      });
    }
    case types.GET_WITHDRAWAL_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        fetchingWithdrawalReport: false,
      });
    }
    case types.GET_WITHDRAWAL_REPORT_FAILED: {
      return Immutable.merge(state, {
        fetchingWithdrawalReport: false,
      });
    }
    case types.GET_WITHDRAWAL_LIST: {
      return Immutable.merge(state, {
        withdrawalsData: {
          pagedData:
            TableModel.pageRequested(state.withdrawalsData.pagedData ? state.withdrawalsData.pagedData : state.withdrawalsData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
        }
        });
    }
    case types.GET_WITHDRAWAL_LIST_SUCCESS: {
      return Immutable.merge(state, {
        withdrawalsData: {pagedData: TableModel.pageReady(state.withdrawalsData.pagedData ? state.withdrawalsData.pagedData : state.withdrawalsData, action.result.totalDataCount, action.result.data)}
      });
    }
    case types.GET_NEXT_WITHDRAWAL_REPORT: {
      return Immutable.merge(state, {
        withdrawalsData: {
          pagedData:
            TableModel.pageRequested(state.withdrawalsData.pagedData ? state.withdrawalsData.pagedData : state.withdrawalsData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
        }
      });
    }
    case types.GET_NEXT_WITHDRAWAL_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        withdrawalsData: {pagedData: TableModel.pageReady(state.withdrawalsData.pagedData ? state.withdrawalsData.pagedData : state.withdrawalsData, action.result.totalDataCount, action.result.data)}
      });
    }

    case types.CHANGE_WITHDRAWAL_FORM_STATUS: {
      return Immutable.merge(state, {
        changeWithdrawalStatusSubmitting: true
      });
    }
    case types.CHANGE_WITHDRAWAL_FORM_STATUS_SUCCESS: {
      const result = action.result;
      const records = [...state.withdrawalsData.pagedData.records]
      let withdrawalIndex = records.findIndex(item => item.id === result.id);
      let withdrawal = records[withdrawalIndex];
      withdrawal = {...withdrawal, status: result.status}
      records[withdrawalIndex] = withdrawal;
      //console.log('{pagedData: TableModel.pageReady(state.withdrawalsData.pagedData, state.withdrawalsData.pagedData.paging.totalSize, records)},',{pagedData: TableModel.pageReady(state.withdrawalsData.pagedData, state.withdrawalsData.pagedData.paging.totalSize, records)},)
      return Immutable.merge(state, {
        changeWithdrawalStatusSubmitting: false,
        withdrawalsData: {pagedData: TableModel.pageReady(state.withdrawalsData.pagedData, state.withdrawalsData.pagedData.paging.totalSize, records)},
      });
    }
    case types.CHANGE_WITHDRAWAL_FORM_STATUS_FAILED: {
      return Immutable.merge(state, {
        changeWithdrawalStatusSubmitting: false
      });
    }
    default:
      return state;
  }
}
