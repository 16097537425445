import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import QRCode from 'qrcode.react';
import {
  Button, Card, CardContent, Typography,Accordion, AccordionSummary, AccordionDetails,
  CardHeader,
} from '@material-ui/core';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import LockIcon from '@material-ui/icons/Lock';
import { LockStateOptions } from '../../models/bikes/BikesListModel';


const VirtualLockExternalTesterDetails = ({lock, onClose, onOpen}) => {

  const {t}= useTranslation()

  return (
    <Fragment>
      <Card elevation={lock.state.enabled ? 4 : 0}>
        <CardHeader
          title={lock.imei}
          subheader={lock.description}
          avatar={lock.state.lockState === LockStateOptions.opened.value ? <BikeIcon color="secondary" /> : <LockIcon color="disabled" />}
          style={!lock.state.enabled ? { background: '#f2f2f2' } : null}
        />
        <CardContent style={!lock.state.enabled ? { background: '#f2f2f2' } : null}>
          {lock.state.enabled && <React.Fragment>
            <Accordion >
              <AccordionSummary >
                <Typography variant="button">{t('virtualLocks.lockDetails.showQrCode')}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column' }}>
                <Typography variant="caption" gutterBottom>{lock.qrCode}</Typography>
                <div>
                  <QRCode value={lock.qrCode} />
                </div>
              </AccordionDetails>
            </Accordion>
            <div>
              <Button variant="contained" color="secondary" fullWidth onClick={() => onClose(lock.imei)}>{t('virtualLocks.lockDetails.closeButton')}</Button>
              <Button variant="contained" color="primary" fullWidth onClick={() => onOpen(lock.imei)}>{t('virtualLocks.lockDetails.openButton')}</Button>
            </div>
          </React.Fragment>}
        </CardContent>
      </Card>
    </Fragment>
  );
}

VirtualLockExternalTesterDetails.propTypes = {
  lock: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VirtualLockExternalTesterDetails;
