import apiBase from './apiBase';

class BikeTypesApi {
  static getAll() {
    return apiBase.create().get('/globalBikeTypes');
  }

  static create(data) {
    return apiBase.create().post('/globalBikeTypes', data);
  }

  static update(id, data) {
    return apiBase.create().put(`/globalBikeTypes/${id}`, data);
  }

  static delete(id) {
    return apiBase.create().delete(`/globalBikeTypes/${id}`);
  }
}

export default BikeTypesApi;
