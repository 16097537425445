import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import DayOfGenerationReportForm from "./DayOfGenerationReportForm";
import {submit} from "redux-form";
import {useDispatch} from "react-redux";

const DayOfGenerationReportDialog = ({open, onClose, onSubmit, day}) => {
  const dispatch = useDispatch();

  return (
    <Dialog open={open} fullWidth={true}  onClose={onClose}  >
      <DialogTitle>Zmiana dnia generowania</DialogTitle>
      <DialogContent>
        <DayOfGenerationReportForm onSubmit={onSubmit} initialValues={{day}}/>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Anuluj
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(submit('dayOfGenerationReportForm'))}
        >
         Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  )

}

DayOfGenerationReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  day: PropTypes.number.isRequired
};

export default DayOfGenerationReportDialog;
