import { create as createApi } from 'apisauce';
import config from "../config";


export default class VirtualLocksApiBase {
  static create(password) {
    const api = createApi({
      baseURL: config.VIRTUALLOCKS_API_URL,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${password}`,
      },
      timeout: 20000
    });

  /*  api.addAsyncRequestTransform(request => {
        request.headers['Authorization'] = `Bearer test123`;
      }
    );*/

    return api;
  }
}
