import apiBase from './virtualLocksExternalTesterApiBase';

class virtualLocksExternalTesterApi {
  static getAll(password) {
    return apiBase.create(password).get('/externalTester/locks');
  }

  static open(imei, password) {
    return apiBase.create(password).post(`/externalTester/locks/${imei}/open`);
  }

  static close(imei, password) {
    return apiBase.create(password).post(`/externalTester/locks/${imei}/close`);
  }

  static verificationPassword(body) {
    return apiBase.create().post("externalTester/account/verificationPassword", body);
  }
}

export default virtualLocksExternalTesterApi;
