import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/FormTextField';
import validate from 'validate.js';

const validation = values => validate(
  values,
  { message: { presence: { message: 'Treść komendy jest wymagana' } } },
  { fullMessages: false }
);

let SendLockMessageForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          autoFocus
          name="message"
          component={FormTextField}
          label="Komenda"
          fullWidth
        />
      </div>
    </form>
  );
};

SendLockMessageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

SendLockMessageForm = reduxForm({
  form: 'sendLockMessageForm',
  validate: validation,
})(SendLockMessageForm);

export default SendLockMessageForm;
