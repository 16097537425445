import apiBase from './virtualLocksApiBase';

class VirtualLocksApi {
  static getAll() {
    return apiBase.create().get('/locks');
  }

  static create(data) {
    return apiBase.create().post(`/locks`, data);
  }

  static delete(imei) {
    return apiBase.create().delete(`/locks/${imei}`);
  }

  static enable(imei) {
    return apiBase.create().post(`/locks/${imei}/enable`);
  }

  static disable(imei) {
    return apiBase.create().post(`/locks/${imei}/disable`);
  }

  static open(imei) {
    return apiBase.create().post(`/locks/${imei}/open`);
  }

  static close(imei) {
    return apiBase.create().post(`/locks/${imei}/close`);
  }

  static tapNfcCard(imei, nfcCardId) {
    return apiBase.create().post(`/locks/${imei}/tap-nfc-card`, { nfcCardId });
  }
}

export default VirtualLocksApi;
