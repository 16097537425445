import React from "react";
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import FormTextField from "../form/FormTextField";
import validate from "validate.js";

const validation = values => validate(
  values,
  {
    day: {
      presence: { message: ' Pole jest wymagane ', allowEmpty: false },
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 28,
        message: ' Wartość musi być większa lub równa 1 oraz mniejsza lub równa 28 '
      },
    },

  },
  { fullMessages: false }
);
let DayOfGenerationReportForm = ({handleSubmit}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="day"
          component={FormTextField}
          label="Dzień generacji raportu"
          type="number"
        />
      </div>
    </form>
  );
}

DayOfGenerationReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

DayOfGenerationReportForm = reduxForm({
  form: 'dayOfGenerationReportForm',
  validate: validation,
})(DayOfGenerationReportForm);
DayOfGenerationReportForm = connect(
  () => ({

  })
)(DayOfGenerationReportForm);

export default DayOfGenerationReportForm
