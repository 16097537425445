import React, {useCallback, useState} from 'react';
import {Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {actions, types} from "../../actions/reportsActions";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import routePaths from "../../routePaths";
import {ReportDataForView} from "./ReportDataFor";
import DayOfGenerationReportDialog from "./DayOfGenerationReportDialog";
import ConfirmRemoveReportDialog from "./ConfirmRemoveReportDialog";

const ReportTable = ({data, fetching}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dayOfGenerationReportDialogData, setDayOfGenerationReportDialogData] = useState({
    show: false,
    currentDay: null,
    id: null
  })

  const [showConfirmRemoveReportDialog, setShowConfirmRemoveReportDialog] = useState({show:false, id: null})

  const onHandleUpdateReportConfiguration = useCallback(async (day, reportId) => {
    const res = await dispatch(actions.updateReportsConfigurations(id, reportId, {dayOfGeneration: day}));
    if(res.type === types.UPDATE_REPORT_CONFIGURATION_SUCCESS){
      setDayOfGenerationReportDialogData({
        show: false,
        currentDay: null,
        id: null
      })
      dispatch(actions.getReportConfigurations(id));
    }
  }, [])

  if(fetching){
    return (
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: 10}}>Trwa ładowanie...</div>
        <CircularProgress />
      </div>
    )
  }

  if(!data || data.length === 0){
    return (
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: 10}}>Nie znaleziono raportów.</div>
      </div>
    )
  }

  return(
    <div>
      <div>
        <div>Raporty</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Reguła</TableCell>
              <TableCell>Dane dla</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Aktywne</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map(x => (
              <TableRow key={x.id}>
                <TableCell>{x.name}</TableCell>
                <TableCell>{x.email}</TableCell>
                {
                  x.rule === 'month' && <TableCell>
                    <Button variant="contained" color="primary" onClick={() => {
                      setDayOfGenerationReportDialogData({
                        show: true,
                        currentDay: x.dayOfGeneration,
                        id: x.id
                      })
                    }}>
                      {t(`automaticReport.reportRule.${x.rule}`)} {`(${x.dayOfGeneration})`}
                    </Button>
                  </TableCell>
                }
                {x.rule !== 'month' && <TableCell>{t(`automaticReport.reportRule.${x.rule}`)} </TableCell>}
                <TableCell>{ReportDataForView[x.generatedFor] ? ReportDataForView[x.generatedFor].name : x.generatedFor}</TableCell>
                <TableCell>{x.isGeneratePdf ? "PDF" : ''}{x.isGeneratePdf && x.isGenerateXlsx ? ", " : '' }{x.isGenerateXlsx ? "XLSX" : ''}</TableCell>
                <TableCell>{x.isActive ? t('automaticReport.active') : t('automaticReport.inactive')}</TableCell>
                <TableCell size='small'>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{marginRight: 20}}
                    component={Link}
                    to={`${routePaths.automaticReport}/${id}/${x.id}`}
                  >
                    Historia
                  </Button>
                </TableCell>
                <TableCell size='small'>
                {x.isActive && <Button
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      const res = await dispatch(actions.deactivateReport(id, x.id));
                      if(res.type === types.DEACTIVATION_REPORT_CONFIGURATION_SUCCESS){
                        dispatch(actions.getReportConfigurations(id));
                      }
                    }}
                  >
                    Dezaktywuj
                  </Button>}
                  {!x.isActive && <Button
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      const res = await dispatch(actions.activateReport(id, x.id));
                      if(res.type === types.ACTIVATION_REPORT_CONFIGURATION_SUCCESS){
                        dispatch(actions.getReportConfigurations(id));
                      }
                    }}
                  >
                    Aktywuj
                  </Button>}
                </TableCell>
                <TableCell size='small'>
                  <Button
                    style={{
                      backgroundColor: 'red'
                    }}
                    variant="contained"
                    onClick={() => {setShowConfirmRemoveReportDialog({show: true, id: x.id})}}
                  >
                    Usuń
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <ConfirmRemoveReportDialog
        open={showConfirmRemoveReportDialog.show}
        onClose={() => {setShowConfirmRemoveReportDialog({show:false, id: null})}}
        onSubmit={async () => {
          const res = await dispatch(actions.removeReport(id, showConfirmRemoveReportDialog.id));
          if(res.type === types.REMOVE_REPORT_CONFIGURATION_SUCCESS){
            setShowConfirmRemoveReportDialog({show:false, id: null})
            dispatch(actions.getReportConfigurations(id));
          }
      }} />
      <DayOfGenerationReportDialog
        open={dayOfGenerationReportDialogData.show}
        day={dayOfGenerationReportDialogData.currentDay}
        onSubmit={async(e) => {
          await onHandleUpdateReportConfiguration(e.day, dayOfGenerationReportDialogData.id)
        }}
        onClose={() => {
          setDayOfGenerationReportDialogData({
            show: false,
            currentDay: null,
            id: null
          })
        }} />
    </div>
  )
}

ReportTable.propTypes = {
  data: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
}

export default ReportTable;
