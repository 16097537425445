import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/tenantsActions';

export default function tenantIncomeDistribution(state = Immutable(initialState.tenantDetails), action) {
  switch (action.type) {
    case types.GET_TENANT_FINANCIAL_DISTRIBUTION_SUCCESS: {
      const { data } = action;

      return {
        fetching: false,
        data: (data.id === null ? {} : data),
      };
    }
    case types.GET_TENANT_FINANCIAL_DISTRIBUTION_FAILURE: {
      return { fetching: false, data: {} };
    }
    default:
      return state;
  }
}
