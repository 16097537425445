import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { actions } from '../../actions/bikePartsActions';
import { Button, List, CircularProgress, ListItem, ListItemText, Paper, ListItemSecondaryAction, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import DownloadIcon from '@material-ui/icons/GetApp';

const BikePartsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fetching = useSelector(x => x.bikeParts.fetching)
  const bikeParts = useSelector(x => x.bikeParts.data);
  useEffect(() => { dispatch(actions.getAll()); }, []);

  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [reportDialogOpened, setReportDialogOpened] = useState(false)
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);
  const [newName, setNewName] = useState('');

  const [reportFrom, setReportFrom] = useState(moment().subtract(1, 'month'));
  const [reportTo, setReportTo] = useState(moment());
  const [reportGenerating, setReportGenerating] = useState(false);

  const handleDelete = async (id) => {
    setDeleting(true)
    try { await dispatch(actions.delete(id)); }
    finally { setDeleting(false); }
  };

  const handleCancelAdd = () => {
    setAddDialogOpen(false);
    setNewName('');
  }

  const handleCancelGenerateReport = () => {
    setReportDialogOpened(false);
    setReportFrom(moment().subtract(1, 'month'));
    setReportTo(moment());
  }

  const handleGenerateReport = async () => {
    setReportGenerating(true);
    setReportDialogOpened(false);
    try {
      await dispatch(actions.generateReport(reportFrom, reportTo));
    } finally {
      setReportGenerating(false);
    }
  }

  const handleAdd = async () => {
    setAdding(true)
    try {
      const ok = await dispatch(actions.create(newName));
      if (ok) {
        setAddDialogOpen(false);
        setNewName('');
      }
    }
    finally { setAdding(false); }
  }

  return <DocumentTitle title={t('bikeParts.title')}>
    <div className="page">
      <div style={{ padding: 16 }}>
        <div style={{ textAlign: 'right', marginBottom: 16 }}>
          <Button
            onClick={() => { setAddDialogOpen(true); }}
            variant="contained"
            color="primary">{t('bikeParts.addButton')}
          </Button>
          <Button disabled={reportGenerating} style={{ marginLeft: 8 }} onClick={() => setReportDialogOpened(true)}>
            {reportGenerating && < CircularProgress size={20} color="secondary" />}
            {!reportGenerating && <DownloadIcon />}
            RAPORT
          </Button>

        </div>
        {fetching && <CircularProgress color="secondary" size={24} />}
        <Paper>
          <List>
            {bikeParts.map(part => (
              <ListItem key={part.id}>
                <ListItemText primary={part.name} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Delete" disabled={deleting} onClick={() => handleDelete(part.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
        <Dialog fullWidth open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
          <DialogTitle id="form-dialog-title">Dodawanie części</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Wprowadź nazwę części
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nazwa"
              fullWidth
              onChange={(e) => setNewName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelAdd}>
              Anuluj
            </Button>
            <Button disabled={adding || !newName} onClick={handleAdd} color="primary" variant='contained'>
              Dodaj
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth open={reportDialogOpened} onClose={() => setReportDialogOpened(false)}>
          <DialogTitle id="form-dialog-title">Generowanie raportu</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Wybierz zakres reportu
            </DialogContentText>
            <DateTimePicker
              label={"Zakres od"}
              ampm={false}
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              autoOk
              okLabel="OK"
              cancelLabel="Anuluj"
              clearLabel="Wyczyść"
              invalidLabel="Wybierz ..."
              onChange={value => setReportFrom(value)}
              format="DD.MM.YYYY HH:mm:ss"
              value={reportFrom}
              clearable
            />
            <DateTimePicker
              label={"Zakres do"}
              ampm={false}
              margin="dense"
              minDate={reportFrom}
              InputLabelProps={{
                shrink: true,
              }}
              autoOk
              okLabel="OK"
              cancelLabel="Anuluj"
              clearLabel="Wyczyść"
              invalidLabel="Wybierz ..."
              onChange={value => setReportTo(value)}
              format="DD.MM.YYYY HH:mm:ss"
              value={reportTo}
              clearable
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelGenerateReport}>
              Anuluj
            </Button>
            <Button disabled={reportGenerating} onClick={handleGenerateReport} color="primary" variant='contained'>
              Generuj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  </DocumentTitle>
}

export default BikePartsPage;
