export const types = {
  SHOW_ERROR_NOTIFICATION: 'SHOW_ERROR_NOTIFICATION',
  SHOW_SUCCESS_NOTIFICATION: 'SHOW_SUCCESS_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
};

export const actions = {
  showError: (error) => dispatch => {
    return dispatch({ type: types.SHOW_ERROR_NOTIFICATION, message: error });
  },
  showSuccess: (message) => dispatch => {
    return dispatch({ type: types.SHOW_SUCCESS_NOTIFICATION, message });
  },
  clear: () => dispatch => {
    return dispatch({ type: types.CLEAR_NOTIFICATIONS });
  },
};
