import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './styles.scss';

const HeaderComponent = ({ toggleSort, className, children, ...rest }) =>
  <div
    className={classnames('rt-th', className, 'flex-row align-items-center justify-center')}
    onClick={e => (toggleSort && toggleSort(e))}
    role="columnheader"
    tabIndex="-1"
    {...rest}
  >
    {children}
    <ArrowDropUpIcon className={classnames('rt-th-asc-indicator', className)} />
    <ArrowDropDownIcon className={classnames('rt-th-desc-indicator', className)} />
  </div>;

HeaderComponent.propTypes = {
  toggleSort: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
};

export default HeaderComponent;