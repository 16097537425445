import apiBase from './apiBase';

class BikePartsApi {
  static getAll() {
    return apiBase.create().get('/bike-parts');
  }

  static create(name) {
    return apiBase.create().post('/bike-parts', { name });
  }

  static delete(id) {
    return apiBase.create().delete(`/bike-parts/${id}`);
  }

  static getReport(dateFrom, dateTo) {
    return apiBase.create().get('/bike-parts/report', { dateFrom, dateTo }, { responseType: 'blob', timeout: 120000 });
  }
}

export default BikePartsApi;
