import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../actions/bikesActions';
import { withTranslation} from 'react-i18next';
import FiltersPanel from './FiltersPanel';
import Table from '../common/table/CheckboxTable';
import yellow from '@material-ui/core/colors/yellow';
import { columns } from './Columns';
import BikesActions from './BikesActions';
import ListItemMenu from './ListItemMenu';
import _ from 'lodash';

class BikesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      selectionDeviceTypes: [],
      selectAll: false
    };

    this.handleFiltersChange = this.handleFiltersChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleActionExecuted = this.handleActionExecuted.bind(this);
  }

  componentDidMount() {
    const bikes = this.props.bikes;
    this.props.actions.loadFreshBikes(1, bikes.paging.pageSize, bikes.sortedBy, bikes.filteredBy);
  }

  handleSortChange(sortBy) {
    const bikes = this.props.bikes;
    this.props.actions.loadFreshBikes(1, bikes.paging.pageSize, sortBy, bikes.filteredBy);
  }

  handleFiltersChange(filterBy) {
    const bikes = this.props.bikes;
    this.props.actions.loadFreshBikes(1, bikes.paging.pageSize, bikes.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange(nextPage) {
    this.props.actions.loadNextBikes(nextPage);
  }

  handlePageSizeChange(nextPageSize) {
    const bikes = this.props.bikes;
    this.props.actions.loadFreshBikes(1, nextPageSize, bikes.sortedBy, bikes.filteredBy);
  }

  handleActionExecuted() {
    const bikes = this.props.bikes;
    this.props.actions.loadFreshBikes(1, bikes.paging.pageSize, bikes.sortedBy, bikes.filteredBy);
    this.setState({ selection: [], selectionDeviceTypes:[], selectAll: false })
  }

  toggleSelection = (key, shift) => {
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      if (shift) {
        const selectedItemIndex = this.props.bikes.records.findIndex(b => b.id === key);
        for (let i = selectedItemIndex; i >= 0; i--) {
          const itemId = this.props.bikes.records[i].id
          if (selection.indexOf(itemId) >= 0) break;
          selection.push(itemId);
        }

      } else {
        selection.push(key);
      }
    }

    let selectionDeviceTypes = _.uniq(this.props.bikes.records.filter(b => selection.find(s => s === b.id)).map(b => b.lockDeviceType));
    this.setState({ selection, selectionDeviceTypes });
  };

  toggleAll = () => {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original.id);
      });
    }
    let selectionDeviceTypes = selection.length === 0 ? [] : _.uniq(this.props.bikes.records.filter(b => selection.find(s => s === b.id)).map(b => b.lockDeviceType));
    this.setState({ selectAll, selection, selectionDeviceTypes });
  };

  isSelected = key => {
    return this.state.selection.includes(key);
  };

  render() {
    const { bikes } = this.props;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      getTrProps: (s, r) => {
        if (!r) return {};
        const selected = this.isSelected(r.original.id);
        return {
          style: selected ? { backgroundColor: yellow[100] } : {},
          // onClick: () => this.toggleSelection(r.original.id),
        };
      }
    };

    return (
      <div style={{ margin: 16 }}>
        <BikesActions bikesIds={this.state.selection} deviceTypes={this.state.selectionDeviceTypes} onActionExecuted={this.handleActionExecuted} />
        <FiltersPanel filteredBy={bikes.filteredBy} onFilteredChange={this.handleFiltersChange} />
        <Table
          ref={r => (this.checkboxTable = r)}
          columns={[{
            Header: '',
            id: 'icon',
            Cell: row => <ListItemMenu itemId={row.original.id} deviceType={row.original.lockDeviceType} onActionExecuted={this.handleActionExecuted} />,
            width: 40,
            sortable: false,
            Filter: () => null,
          }, ...columns]}
          data={bikes.records}
          filteredBy={bikes.filteredBy}
          sortedBy={bikes.sortedBy}
          paging={bikes.paging}
          onFilteredChange={this.handleFiltersChange}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          loading={bikes.loading}
          minRows={0}
          keyField="id"
          {...checkboxProps}
        />
      </div>
    );
  }
}

BikesList.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  bikes: PropTypes.object,
};

BikesList.defaultProps = {
  loading: false,
  bikes: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    bikes: state.bikes.list.pagedData,
    loading: state.bikes.list.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BikesList));
