export default {
  home: '/',
  signIn: '/signin',
  virtualLocks: '/virtual-locks',
  virtualLocksExternalTester: '/virtual-locks-external-tester',
  bikeTypes: '/bike-types',
  bikeParts: '/bike-parts',
  newBikeTypeDetails: '/bike-types/new',
  bikeTypeDetails: '/bike-types/:id',
  mobileUserAccounts: '/user-accounts',
  mobileUserAccountDetails: '/user-accounts/:id',
  tenants: '/tenants',
  tenantDetails: '/tenants/:id',
  automaticReport: '/automaticReport',
  apiAccess: '/apiAccess',
  withdrawalReport: '/withdrawalReport',

};
