import React, {useMemo} from "react";
import {Field, reduxForm} from "redux-form";
import FormSelect from "../form/FormSelect";
import {WithdrawalStatus} from "../../models/WithdrawalModel";
import PropTypes from "prop-types";
import {connect} from "react-redux";

let ChangeWithdrawalStatusForm = ({handleSubmit}) => {
  const withdrawalStatusMemo = useMemo(() => {
   return Object.keys(WithdrawalStatus).map(key => ({
     value: WithdrawalStatus[key].value,
     name: WithdrawalStatus[key].name
   }));
  },[])

  return(
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="status"
          component={FormSelect}
          label={"Status"}
          style={{ width: 300 }}
          values={withdrawalStatusMemo}
        />
      </div>
    </form>
  )
}
ChangeWithdrawalStatusForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,

};

ChangeWithdrawalStatusForm = reduxForm({
  form: 'changeWithdrawalStatusForm',
})(ChangeWithdrawalStatusForm);

ChangeWithdrawalStatusForm = connect(() => {
  return {

  }
})(ChangeWithdrawalStatusForm)


export default ChangeWithdrawalStatusForm;

