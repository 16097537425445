import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { Paper } from '@material-ui/core';
import { actions } from '../../actions/bikeTypesActions';
import BikeTypeForm from './BikeTypeForm';
import routePaths from '../../routePaths';

class BikeTypeDetailsPage extends React.Component {
  handleSubmit = data => {
    const submitAction = data.id
      ? this.props.actions.update
      : this.props.actions.create;
    return submitAction(data)
      .then(success => {
        if (success) this.props.history.push(routePaths.bikeTypes);
      });
  }

  render() {
    const { t, bikeType } = this.props;

    return (
      <DocumentTitle title={t('bikeTypes.title')}>
        <div className="page">
          <div style={{padding: 16}}>
            <Paper style={{padding: 16}}>
              <BikeTypeForm initialValues={bikeType} onSubmit={this.handleSubmit} />
            </Paper>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

BikeTypeDetailsPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  bikeType: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  const id = props.match.params.id;
  return {
    bikeType: id ? state.bikeTypes.data.find(d => d.id === id) : {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(BikeTypeDetailsPage);
