import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { submit } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import ServiceIcon from '@material-ui/icons/BuildTwoTone';
import { actions } from '../../actions/bikesActions';
import RestrictRentalsForm from './RestrictRentalsForm';

const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

class RestrictRentalsAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      submitting: false,
    };

    this.handleRestrictRentalsStart = this.handleRestrictRentalsStart.bind(this);
    this.handleRestrictRentalsCancel = this.handleRestrictRentalsCancel.bind(this);
    this.handleRestrictRentals = this.handleRestrictRentals.bind(this);
  }

  handleRestrictRentalsStart() {
    this.setState({
      dialogVisible: true,
    });
  }

  handleRestrictRentalsCancel() {
    this.setState({
      dialogVisible: false
    });
  }

  handleRestrictRentals(data) {
    this.setState({ submitting: true });
    const { bikesIds, reason } = data;
    this.props.actions
      .restrictRentals(bikesIds, reason)
      .then(() => this.setState({ dialogVisible: false, submitting: false }))
      .then(() => this.props.onActionExecuted());
  }

  render() {
    const { bikesIds, dispatch, fullScreen, classes, menuItem, disabled } = this.props;
    return (
      <React.Fragment>
        {menuItem
        ? <MenuItem onClick={this.handleRestrictRentalsStart} disabled={disabled}><ServiceIcon className={classes.icon} /> Wyłącz z użytku</MenuItem>
        : <Button size="small" variant="outlined" className={classes.button} onClick={this.handleRestrictRentalsStart} disabled={disabled}>
          <ServiceIcon className={classes.icon} /> Wyłącz z użytku
        </Button>}
        <Dialog open={this.state.dialogVisible} fullScreen={fullScreen} fullWidth={true}>
          <DialogTitle>Tymczasowe wyłączenie roweru z użytkowania</DialogTitle>
          <DialogContent>
            Liczba wybranych rowerów: <strong>{bikesIds.length}</strong>
          </DialogContent>
          <DialogContent>
            <RestrictRentalsForm initialValues={{bikesIds}} onSubmit={this.handleRestrictRentals} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRestrictRentalsCancel} disabled={this.state.submitting}>
              Anuluj
          </Button>
            <Button
              onClick={() => dispatch(submit("restrictRentalsForm"))}
              disabled={this.state.submitting}
              color="secondary"
            >
              Potwierdź
          </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

RestrictRentalsAction.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  bikesIds: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  classes: PropTypes.object.isRequired,
  onActionExecuted: PropTypes.func.isRequired,
  menuItem: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default compose(
  connect(null, mapDispatchToProps),
  withMobileDialog(),
  withStyles(styles)
)(RestrictRentalsAction);
