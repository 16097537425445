export class PageModel {
  constructor(data) {
    data = data || {};
    this.pageNumber = data.pageNumber || 1;
    this.pageSize = data.pageSize || 1;
    this.totalSize = data.totalDataCount || 0;
    this.totalPages = Math.ceil(data.totalDataCount / this.pageSize) || 1;
  }

  static nextPageRequested(model, pageNumber, pageSize) {
    const nextModel = { ...model };
    nextModel.pageNumber = pageNumber || nextModel.pageNumber;
    nextModel.pageSize = pageSize || nextModel.pageSize;
    nextModel.totalPages = Math.ceil(nextModel.totalSize / nextModel.pageSize) || 1;
    return nextModel;
  }

  static nextPageReady(model, totalSize) {
    const nextModel = { ...model };
    nextModel.totalSize = totalSize;
    nextModel.totalPages = Math.ceil(nextModel.totalSize / nextModel.pageSize) || 1;
    return nextModel;
  }
}
