import React, {useMemo} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {submit} from "redux-form";
import PropTypes from "prop-types";
import TokenForm from "../../form/TokenForm";

const TokenDialog = ({open, onCancel, onSubmit, submitting, permissions, data, edit}) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const dataToForm = useMemo(() => {
    if(data != null){

      return {
        name: data.name,
        permissions: data.permissions.reduce((o, key) => ({ ...o, [key]: true}), {})
      }
    }
  }, [data])

  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>{t(`apiAccess.tokenDialog.${edit ? 'titleEdit' : 'titlePreview'}`)}</DialogTitle>
      <DialogContent>
        <TokenForm
          onSubmit={(dataForm) => onSubmit(data.id,dataForm)}
          permissions={permissions}
          initialValues={dataToForm}
          edit={edit}
          preview={!edit}
        />
      </DialogContent>
      {edit && <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        <Button
          disabled={submitting}
          color="primary"
          variant="contained"
          onClick={() => dispatch(submit('tokenForm'))}
        >
          {t('apiAccess.tokenDialog.save')}
        </Button>
      </DialogActions>}
    </Dialog>
  )

}

TokenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  permissions: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired
};

export default TokenDialog
