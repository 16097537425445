
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import notifications from './notificationsReducer';
import bikes from './bikesReducer';
import virtualLocks from './virtualLocksReducer';
import bikeTypes from './bikeTypesReducer';
import bikeParts from './bikePartsReducer';
import mobileUserAccounts from './mobileUserAccountsReducer';
import mobileUserAccountDetails from './mobileUserAccountDetailsReducer';
import tenants from './tenantsReducer';
import tenantDetails from './tenantDetailsReducer';
import reports from "./reportsReducer";
import publicAccessTokenReducer from "./publicAccessTokenReducer";
import tenantIncomeDistribution from "./tenantIncomeDistributionReducer";
import virtualLocksExternalTesterReducer from "./virtualLocksExternalTesterReducer";

const bikesReducers = combineReducers({
  list: bikes,
});

const mobileUserAccountsReducer = combineReducers({
  list: mobileUserAccounts,
});

const tenantsReducer = combineReducers({
  list: tenants,
});

const appReducer = combineReducers({
  notifications,
  form: formReducer,
  bikes: bikesReducers,
  virtualLocks,
  virtualLocksExternalTester: virtualLocksExternalTesterReducer,
  bikeTypes,
  bikeParts,
  mobileUserAccounts: mobileUserAccountsReducer,
  mobileUserAccountDetails,
  tenants: tenantsReducer,
  publicAccessToken: publicAccessTokenReducer,
  tenantDetails,
  tenantIncomeDistribution,
  reports,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

