import { PublicClientApplication } from "@azure/msal-browser";
import config from "./config";

const cfg = {
  clientId: config.AZURE_AD_B2C_CLIENT_ID,
  knownAuthorities: [config.AZURE_AD_B2C_AUTHORITY, config.AZURE_AD_B2C_AUTHORITY_PASSWORD_RESET],
  authority: config.AZURE_AD_B2C_AUTHORITY,
  passwordResetAuthority: config.AZURE_AD_B2C_AUTHORITY_PASSWORD_RESET,
  scopes: config.AZURE_AD_B2C_SCOPES,
  redirectUri: config.AZURE_AD_B2C_REDIRECT_URI,
  navigateToLoginRequestUrl: true,
};

const pcaInstance = new PublicClientApplication({
  auth: cfg,
  cache: { storeAuthStateInCookie: true, secureCookies: true }
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, msg) => console.log(level + " " + msg),
  //   }
  // }
});

export const authConfig = {
  scopes: cfg.scopes,
  authority: cfg.authority,
  passwordResetAuthority: cfg.passwordResetAuthority,
};
export default pcaInstance;
