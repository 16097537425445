import React, { Component } from 'react';
import classnames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { TextField, MenuItem, Tooltip, Button, IconButton, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import './styles.scss';

/* eslint react/no-multi-comp: 0 */
/* eslint react/prop-types: 0 */

const defaultButton = props => <IconButton {...props} className="pagination-button">{props.children}</IconButton>;

export default class ReactTablePagination extends Component {
  constructor(props) {
    super();
    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.renderSizeOption = this.renderSizeOption.bind(this);
    this.canNext = this.canNext.bind(this);
    this.canPrev = this.canPrev.bind(this);
    this.handleExportToSpreadsheet = this.handleExportToSpreadsheet.bind(this);

    this.state = {
      page: props.page,
      spreadsheetExportInProgress: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.page });
  }

  getSafePage(page) {
    if (isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  handleExportToSpreadsheet() {
    this.setState({ spreadsheetExportInProgress: true });
    this.props
      .onExportToSpreadsheet()
      .finally(() => this.setState({ spreadsheetExportInProgress: false }));
  }

  renderSizeOption() {
    const { onPageSizeChange, pageSize, pageSizeOptions } = this.props;
    return (
      <TextField
        className="pagination-size-option"
        select
        value={pageSize}
        onChange={e => onPageSizeChange(Number(e.target.value))}
        InputProps={{ disableUnderline: true, className: 'pagination-select' }}
      >
        {pageSizeOptions.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
      </TextField>
    );
  }

  renderPageSize(page, pageSize, totalSize) {
    return `${(page - 1) * pageSize + 1} - ${totalSize < page * pageSize ? totalSize : page * pageSize} / ${totalSize}`;
  }

  renderPageButton(pageProps) {
    return (
      <IconButton
        className={["pagination-button", pageProps.value === this.state.page && "selected"].join(' ')}
        disabled={pageProps.disabled}
        key={`${pageProps.value}`}
        onClick={() => this.changePage(pageProps.value)}>
        {pageProps.disabled ? '...' : pageProps.value}
      </IconButton>
    );
  }

  canPrev() {
    return this.props.page > 1;
  }

  canNext() {
    return this.props.page < this.props.pages;
  }

  renderPageJump() {
    const firstPage = { value: 1 };
    const lastPage = { value: this.props.pages };
    const currentPage = { value: this.state.page };
    const prevPage = { value: currentPage.value - 1 };
    const nextPage = { value: currentPage.value + 1 };
    const morePrevPages = { value: currentPage.value - 2 };
    const moreNextPages = { value: currentPage.value + 2 };

    const mandatoryPages = [firstPage, prevPage, currentPage, nextPage, lastPage];

    const pageProps = _.uniqBy([...mandatoryPages, morePrevPages, moreNextPages], p => p.value)
      .filter(p => p.value >= firstPage.value && p.value <= lastPage.value)
      .sort((x, y) => x.value - y.value)
      .map(p => ({ ...p, disabled: !mandatoryPages.some(m => m.value == p.value) }));

    return (
      <div className="-pageJump">
        {pageProps.map(p => this.renderPageButton(p))}
      </div>
    );
  }

  renderExportToSpreadSheet() {
    const { spreadsheetExportInProgress } = this.state;
    return (
      <Tooltip title="Eksportuj do arkusza kalkulacyjnego" placement="right-start">
        <Button disabled={spreadsheetExportInProgress} onClick={() => this.handleExportToSpreadsheet()}>
          Eksportuj
          {spreadsheetExportInProgress && <CircularProgress size={24} />}
        </Button>
      </Tooltip>
    );
  }

  render() {
    const {
      page,
      totalSize,
      pageSize,
      showPageSizeOptions,
      showExportToSpreadsheet,
      showPageJump,
      className,
      PreviousComponent = defaultButton,
      NextComponent = defaultButton,
    } = this.props;

    return (
      <div className={classnames(className, 'pagination-panel flex-row align-items-center justify-space-between')} style={{ ...this.props.style }} >
        <div className="flex-row flex align-items-center justify-flex-start">
          {this.props.pageText}
          {showPageSizeOptions && this.renderSizeOption()}
          {showExportToSpreadsheet && this.renderExportToSpreadSheet()}
        </div>
        <div className="flex-row flex align-items-center justify-center">
          {this.renderPageSize(this.state.page, pageSize, totalSize)}
        </div>
        <div className="flex-row flex align-items-center justify-flex-end">
          <div className="-previous">
            <PreviousComponent onClick={() => this.canPrev() && this.changePage(page - 1)} disabled={!this.canPrev()}>
              <ChevronLeftIcon />
            </PreviousComponent>
          </div>
          <div className="-center">
            <span className="-pageInfo">
              {showPageJump
                ? this.renderPageJump()
                : <span className="-currentPage">{page}</span>
              }
            </span>
          </div>
          <div className="-next">
            <NextComponent onClick={() => this.canNext() && this.changePage(page + 1)} disabled={!this.canNext()}          >
              <ChevronRight />
            </NextComponent>
          </div>
        </div>
      </div>
    );
  }
}
