export const ReportDataFor = [
  { id: "All", name: "Wszystko"},
  { id: "Roovee", name: "Roovee"},
  { id: "Operator", name: "Zamawiający"}
]

export const ReportDataForView = {
  all: { value: "All", name: "Wszystko" },
  roovee: { value: "Roovee", name: "Roovee" },
  operator: { value: "Operator", name: "Zamawiający" }
}
