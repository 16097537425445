import apiBase from './apiBase';

class PublicAccessTokenApi {
  static createToken(data) {
    return apiBase.create().post('/publicApiAccess/token/', data);
  }
  static getTokens(tenantId) {
    return apiBase.create().get(`/publicApiAccess/tokenList/${tenantId}`);
  }
  static getUpdateToken(id, data) {
    return apiBase.create().put(`/publicApiAccess/token/${id}`, data);
  }
  static getPermissions() {
    return apiBase.create().get(`/publicApiAccess/permissions`);
  }
  static deactivatePermissions(tenantId, id) {
    return apiBase.create().delete(`/publicApiAccess/token/${tenantId}/${id}`);
  }
}

export default PublicAccessTokenApi;
