export const PaymentType = [
  { value: "rentalTimeBelow12H", name: "Czas przejazdu poniżej 12 godzin" },
  { value: "bikeLeftOutOfPreferredZonePenalty", name: "Pozostawienie roweru poza preferowaną strefą zwrotu" },
  { value: "reward", name: "Bonus za zwrot roweru do strefy" },
  { value: "otherPayments", name: "Inna" },
];

export const SanctionType = [
  { value: "rentalTimeOver12H", name: "Czas przejazdu powyżej 12 godzin" },
  { value: "bikeLeftOutOfOperationsZonePenalty", name: "Zakończenie przejazdu poza obszarem funkcjonowania" },
  { value: "bikeDamage", name: "Uszkodzenie roweru" },
  { value: "infrastructureDamage", name: "Uszkodzenie infrastruktury" },
  { value: "otherSanction", name: "Inna" },
];

export const IncomeFor = Object.freeze({
  roovee: {value: "roovee", name: "Roovee"},
  client: {value: "operator", name: "Zamawiający"}
});
