import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import { Paper, Typography, TableHead, Table, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { actions } from '../../actions/tenantsActions';
import TenantDetailsForm from './TenantDetailsForm';
import routePaths from '../../routePaths';
import LoadingIndicator from '../LoadingIndicator';
import NewOperatorAccountDialog from './NewOperatorAccountDialog';
import ModalRequireChangePassword from "./ModalRequireChangePassword";
import {Link} from "react-router-dom";
import ConfirmationButton from "../common/ConfirmationButton";
import IncomeDistributionDialog from "./IncomeDistributionDialog";

class TenantDetailsPage extends React.Component {
  state = {
    operatorDialogVisible: false,
    incomeDistributionDialogVisible: false,
    submitting: false,
    requireChangePasswordDialogVisible: null,
    tenantId: this.props.match.params.id,
    tenantIncomeDistribution: null
  }

  componentDidMount() {
    this.props.actions.getDetails(this.props.match.params.id);
  }

  handleAddNewOperatorAccount = () => this.setState({ operatorDialogVisible: true });

  handleDialogRequireChangePassword = (mode) => this.setState({ requireChangePasswordDialogVisible: mode });

  handleDialogRequireChangePasswordByUser = (mode) => this.setState({ requireChangePasswordDialogVisible: mode });

  handleSubmitRequireChangePassword = (tenant) => {
    this.props.actions.requirePassword(
      tenant,
      this.state.requireChangePasswordDialogVisible.id,
      this.state.requireChangePasswordDialogVisible.mode
    )
    this.setState({ requireChangePasswordDialogVisible: null });
  }

  handleCreateNewOperatorAccount = data => {
    this.setState({ submitting: true });
    this.props.actions.createOperatorAccount(this.props.tenant.id, data)
      .finally(() => {
        this.setState({ submitting: false, operatorDialogVisible: false });
      });
  }

  handleCancelNewOperatorAccountCreation = () => this.setState({ operatorDialogVisible: false });

  handleTenantWithdrawDeposits = () => {
    this.props.actions.tenantWithdrawDeposits(this.props.tenant.id)
  }

  handleSubmit = data => {
    this.props.actions.update(data)
      .then(success => {
        if (success) this.props.history.push(routePaths.tenants);
      });
  }

  handleEditIncomeDistribution = () => {
    this.props.actions.getIncomeDistribution(this.props.match.params.id);
    this.setState({
      incomeDistributionDialogVisible: true})
  }

  handleEditionIncomeDistribution = data => {
    this.props.actions.updateIncomeDistribution(this.props.tenant.id, data ).finally(() => {
      this.setState({ incomeDistributionDialogVisible: false });
    });
  }

  handleCancelIncomeDistributionEdition = () => this.setState({incomeDistributionDialogVisible: false});

  render() {
    const { t, fetching, tenant, tenantIncomeDistribution } = this.props;
    const { submitting, operatorDialogVisible, incomeDistributionDialogVisible, requireChangePasswordDialogVisible } = this.state;

    return (
      <DocumentTitle title={t('tenantDetails.title')}>
        <div className="page">
          <div style={{ padding: 16 }}>
            <LoadingIndicator fetching={fetching}>
              {tenant &&
                <Paper style={{ padding: 16}}>
                  <div style={{justifyContent: 'space-between', display: 'flex'}}>
                    <div style={{ marginTop: 16, gap:'10px'}}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleEditIncomeDistribution}
                      >
                        Rozkład przychodu
                      </Button>
                    </div>
                    <div style={{ marginTop: 16, display: 'flex', gap: '10px' }}>
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          component={Link}
                          to={`${routePaths.automaticReport}/${this.state.tenantId}`}
                        >
                          Konfiguracja automatycznej wysyłki raportów
                        </Button>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          component={Link}
                          to={`${routePaths.apiAccess}/${this.state.tenantId}`}
                        >
                          Konfiguracja dostępu do API
                        </Button>
                      </div>
                    </div>
                  </div>
                  <TenantDetailsForm initialValues={tenant} onSubmit={this.handleSubmit} />
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <ConfirmationButton
                      size="small"
                      variant="contained"
                      color="primary"
                      onConfirm={this.handleTenantWithdrawDeposits}
                      style={{ marginTop: 16, padding: '7px 15px', fontSize: 14 }}
                      confirmationTitle={'Wypłata depozytów'}
                      confirmationText={`Czy jesteś pewien, że chcesz wypłacić depozyty wszystkim Użytkownikom ${tenant.fullName}. Operacja jest nieodwracalna.`}
                    >
                      Zwróć depozyty
                    </ConfirmationButton>
                  </div>
                  <div style={{ textAlign: 'right', marginTop: 16 }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.handleDialogRequireChangePassword({
                        mode: "allUser"
                      })}
                    >
                      Wymuś zmianę hasła dla wszystkich operatorów
                    </Button>
                  </div>
                  <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                    <InfoOutlinedIcon />
                    <Typography style={{ verticalAlign: 'middle' }} variant="caption">
                      Upewnij się, że w <a href="https://cp1.home.pl/ccp/v/pa/ccp-domains/dnsRecords/r/0f66fdc0-2b9f-44cc-8787-593c62f334a3">panelu zarządzanie domeną</a> dodano
                      rekord CNAME dla hosta <b>{`${tenant.id}.roovee.eu`}</b>, wskazujący na nazwę kanoniczną <b>{`${tenant.id}.roovee.eu.cdn.cloudflare.net.`}</b>
                    </Typography>
                  </div>
                  <Typography variant="overline" gutterBottom>Konta operatorów</Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Imię</TableCell>
                        <TableCell>Nazwisko</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Data aktywacji</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tenant.accounts.map(account => (
                        <TableRow key={account.id}>
                          <TableCell>{account.firstName}</TableCell>
                          <TableCell>{account.lastName}</TableCell>
                          <TableCell>{account.email}</TableCell>
                          <TableCell>{account.activationDate ? moment(account.activationDate).local().format('DD.MM.YYYY HH:mm') : '-'}</TableCell>
                          <TableCell>
                            <Button
                              disabled={submitting}
                              color="primary"
                              variant="contained"
                              onClick={() => this.handleDialogRequireChangePasswordByUser({
                                user: `${account.firstName} ${account.lastName}`,
                                id: account.id,
                                mode: "oneUser"
                              })}
                            >
                              Wymuś zmianę hasła
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div style={{ textAlign: 'right', marginTop: 16 }}>
                    <Button variant="contained" color="secondary" onClick={this.handleAddNewOperatorAccount}>Dodaj operatora</Button>
                  </div>
                </Paper>
              }
            </LoadingIndicator>
          </div>
          <NewOperatorAccountDialog
            open={operatorDialogVisible}
            submitting={submitting}
            onSubmit={this.handleCreateNewOperatorAccount}
            onCancel={this.handleCancelNewOperatorAccountCreation}
          />
          {tenant && requireChangePasswordDialogVisible && <ModalRequireChangePassword
            data={requireChangePasswordDialogVisible}
            onCancel={() => this.handleDialogRequireChangePassword(null)}
            onSubmit={() => this.handleSubmitRequireChangePassword(tenant.id)}
            mode={requireChangePasswordDialogVisible.mode}
            tenant={tenant.name}
            user={requireChangePasswordDialogVisible.user}
          />}
          <IncomeDistributionDialog
            open={incomeDistributionDialogVisible}
            onSubmit={this.handleEditionIncomeDistribution}
            onCancel={this.handleCancelIncomeDistributionEdition}
            actions={this.props.actions}
            tenantIncomeDistribution={tenantIncomeDistribution}
            />
        </div>
      </DocumentTitle>
    );
  }
}

TenantDetailsPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  tenant: PropTypes.object,
  tenantIncomeDistribution: PropTypes.object
};

function mapStateToProps(state) {
  return {
    fetching: state.tenantDetails.fetching,
    tenant: state.tenantDetails.data,
    tenantIncomeDistribution: state.tenantIncomeDistribution.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(TenantDetailsPage);
