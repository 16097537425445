import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import { useTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import {Grid, CircularProgress, Button} from '@material-ui/core';
import { actions as virtualLocksActions } from '../../actions/virtualLocksExternalTesterActions';
import VirtualLockExternalTesterDetails from "./VirtualLockExternalTesterDetails";

const VirtualLocksExternalTesterPage = ({fetching, locks, isLogin, logging, token  }) => {
  const {t}= useTranslation()
  const dispatch = useDispatch();
  const [password, setPassword] = useState(null);
  useEffect(() => {
    if (isLogin && token) {
      dispatch(virtualLocksActions.getAll(token))
    }
  }, [isLogin, token]);

  const handleOpenLock = imei => dispatch(virtualLocksActions.open(imei, token));

  const handleCloseLock = imei => dispatch(virtualLocksActions.close(imei, token));

  const handleLogin = () => {
    dispatch(virtualLocksActions.verificationPassword(password));
  }

  if (!isLogin) {
   return (
     <div className="page">
       <div style={{padding: 16}}>

         <div style={{
           display: 'flex',
           alignItems: 'center',
           flexDirection: 'column',
         }}>
           <div style={{
             display: 'flex',
             flexDirection: 'column',
             gap: 10,
             alignItems: 'center',
             backgroundColor: '#FFFF00',
             borderColor: 'black',
             borderWidth: 1,
             borderStyle: 'solid',
             borderRadius: 5,
             padding: '20px 15px'
           }}>
             <div>Podaj Hasło</div>
             <input type={'password'} style={{width: 200}} onChange={x => {
               setPassword(x.currentTarget.value)
             }}/>
             <Button

               variant="contained"
               onClick={handleLogin}
               disabled={logging}
             >
               Zaloguj
             </Button>
           </div>
         </div>
       </div>
     </div>
   )
  }

  return (
    <DocumentTitle title={t('virtualLocks.title')}>
      <div className="page">
        <div style={{padding: 16}}>
          <Grid container spacing={3}>
            {fetching && <CircularProgress color="secondary" size={24} />}
            {!fetching && locks && locks.map(lock => (
              <Grid item key={lock.imei}>
                <VirtualLockExternalTesterDetails
                  lock={lock}
                  onOpen={handleOpenLock}
                  onClose={handleCloseLock}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </DocumentTitle>
  )

}
VirtualLocksExternalTesterPage.propTypes = {
  fetching: PropTypes.bool.isRequired,
  isLogin: PropTypes.bool.isRequired,
  logging: PropTypes.bool.isRequired,
  locks: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
}
function mapStateToProps(state) {
  return {
    fetching: state.virtualLocksExternalTester.fetching,
    locks: state.virtualLocksExternalTester.data,
    isLogin: state.virtualLocksExternalTester.isLogin,
    token: state.virtualLocksExternalTester.token,
    logging: state.virtualLocksExternalTester.logging

  };
}


export default
  connect(mapStateToProps, null)(VirtualLocksExternalTesterPage);
