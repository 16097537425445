import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FormMapView from './FormMapView';

const defaultPosition = { lat: 52.406376, lng: 16.925167 };
class FormMapField extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapClick = this.handleMapClick.bind(this);
  }

  handleMapClick(location) {
    const newLocation = {
      lat: location.latLng.lat(),
      lng: location.latLng.lng(),
    };
    this.props.input.onChange(newLocation);
  }

  render() {
    const { input, label } = this.props;
    return (
      <div style={{ marginTop: 10 }}>
        <Typography variant="caption">{label}</Typography>
        <FormMapView
          loadingElement={<div style={{ height: '100%', width: 300, }} />}
          containerElement={<div style={{ width: '100%', height: 300 }} />}
          mapElement={<div style={{ width: '100%', height: 300 }} />}
          onMapLoad={ref => this.mapRef = ref}
          onMapClick={this.handleMapClick}
          marker={input.value || defaultPosition}
          onMarkerRightClick={() => { }}
          initialLocation={input.value || defaultPosition}
        />
      </div>
    );
  }
}
FormMapField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
};

export default FormMapField;
