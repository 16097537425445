import React from 'react';
import PropTypes from 'prop-types';

const SuccessNotification = ({message}) => {
  return (
    <div>
      <span>{message}</span>
    </div>
  );
};

SuccessNotification.propTypes = {
  message: PropTypes.string.isRequired
};

export default SuccessNotification;
