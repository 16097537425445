import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/tenantsActions';
import { TableModel } from '../models/TableModel';

export default function tenantsReducer(state = Immutable(initialState.tenants.list), action) {
  switch (action.type) {
    case types.LOAD_TENANTS: {
      return Immutable.merge(state, {
        loading: true,
        pagedData: TableModel.pageRequested(state.pagedData, action.pageNumber, action.pageSize, action.filterBy, action.sortBy),
      });
    }
    case types.LOAD_TENANTS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        loading: false,
        pagedData: TableModel.pageReady(state.pagedData, result.totalDataCount, result.data),
      });
    }
    case types.LOAD_NEXT_TENANTS: {
      return Immutable.merge(state, {
        pagedData: TableModel.nextPageRequested(state.pagedData, action.pageNumber, action.pageSize),
      });
    }
    case types.LOAD_NEXT_TENANTS_SUCCESS: {
      const result = action.result;
      return Immutable.merge(state, {
        pagedData: TableModel.pageReady(state.pagedData, result.totalDataCount, result.data),
      });
    }
    default:
      return state;
  }
}
