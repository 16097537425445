/* eslint-disable import/default */
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './components/Root';
import moment from 'moment';
import locale from 'moment/locale/pl';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MsalProvider } from "@azure/msal-react";
import configureStore from './store/configureStore';
require('./favicon.ico');
import './styles/styles.scss';
import history from './history'
import pcaInstance from './msal';

moment.locale('pl', locale);
const store = configureStore();

render(
  <MsalProvider instance={pcaInstance}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AppContainer>
        <Root store={store} history={history} />
      </AppContainer>
    </MuiPickersUtilsProvider>
  </MsalProvider >,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <MsalProvider instance={pcaInstance}>
        <AppContainer>
          <NewRoot store={store} history={history} />
        </AppContainer>
      </MsalProvider>,
      document.getElementById('app')
    );
  });
}
