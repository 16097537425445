import React from 'react'
import PropTypes from "prop-types";
import {Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const TokenTable = ({fetching, data, showTokenDialog, showDeactivateDialog}) => {

  const { t } = useTranslation();

  if(fetching){
    return (
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: 10}}>Trwa ładowanie...</div>
        <CircularProgress />
      </div>
    )
  }

  if(!data || data.length === 0){
    return (
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: 10}}>Nie znaleziono tokenów.</div>
      </div>
    )
  }

  return(
    <div>
      <div>
        <div>Tokeny dostępu</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Token</TableCell>
              <TableCell>Nazwa</TableCell>
              <TableCell>Data Utworzenia</TableCell>
              <TableCell>Data Ostatniego Użycia</TableCell>
              <TableCell>Aktywne</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map(x => (
              <TableRow key={x.id}>
                <TableCell>{x.token}*****************</TableCell>
                <TableCell>{x.name}</TableCell>
                <TableCell>{x.createdAt}</TableCell>
                <TableCell>{x.lastUsedAt != null ? x.lastUsedAt : "---------------------------"}</TableCell>
                <TableCell>{x.isActive ? t('apiAccess.active') : t('apiAccess.inactive')}</TableCell>
                <TableCell size='small'>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{marginRight: 20}}
                    onClick={() => {showTokenDialog(false, x)}}
                  >
                    Podgląd
                  </Button>
                  {x.isActive && <Button
                    color="primary"
                    variant="contained"
                    style={{marginRight: 20}}
                    onClick={() => {
                      showTokenDialog(true, x)
                    }}
                  >
                    Edytuj
                  </Button>
                  }
                  {x.isActive && <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {showDeactivateDialog(x.id)}}
                  >
                    Dezaktywuj
                  </Button>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

TokenTable.propTypes = {
  data: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  showTokenDialog: PropTypes.func.isRequired,
  showDeactivateDialog: PropTypes.func.isRequired
}

export default TokenTable;
