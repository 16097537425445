import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FormTextField from '../form/FormTextField';
import FormMapField from '../form/FormMapField';

let VirtualLockForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="qrCode"
          component={FormTextField}
          label="Kod QR"
        />
      </div>
      <div>
        <Field
          name="description"
          component={FormTextField}
          label="Opis"
          fullWidth
        />
      </div>
      <div>
        <Field
          name="geoFence.center"
          component={FormMapField}
          label="Lokacja"
        />
      </div>
      <div>
        <Field
          name="geoFence.radius"
          component={FormTextField}
          label="Promień w metrach"
          type="number"
          parse={Number}
        />
      </div>
    </form>
  );
};

VirtualLockForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

VirtualLockForm = reduxForm({
  form: 'virtualLockForm',
})(VirtualLockForm);

export default VirtualLockForm;
