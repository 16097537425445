import React from 'react'
import {useDispatch} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {reset} from "redux-form";
import PropTypes from "prop-types";

const CreatedTokenDialog = ({open, onCancel, token }) => {

  //const { t } = useTranslation();

  const dispatch = useDispatch()

  const closeDialog = () => {
    dispatch(reset('tokenForm'))
    onCancel();
  }

  const copyToClipboard = async (token) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(token);
    } else {
      document.execCommand("copy", true, token);
    }
  }

  return(
    <Dialog open={open} fullWidth={false}>
      <DialogTitle>Nowy Token</DialogTitle>
      <DialogContent>
        <div>Wygenerowaliśmy dla Ciebie nowy token. Upewnij się, że go skopiowałeś - nie będziesz mógł go ponownie zobaczyć.</div>
        <div style={{textAlign: 'center', marginTop: 15, fontWeight: 'bold'}}>{token}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
          Zamknij
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => copyToClipboard(token)}
        >
          Skopiuj do schowka
        </Button>
      </DialogActions>
    </Dialog>
  )

}

CreatedTokenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

export default CreatedTokenDialog
