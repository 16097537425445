import React from "react";
import moment from "moment";
import { Avatar, Badge } from "@material-ui/core";
import ServiceIcon from "@material-ui/icons/Build";
import RideIcon from "@material-ui/icons/DirectionsBike";
import AlarmIcon from "@material-ui/icons/NotificationsActive";
import NotUprightIcon from "@material-ui/icons/Redo";
import PauseIcon from "@material-ui/icons/Pause";
import ReservationIcon from "@material-ui/icons/Timelapse";
import TextFilter from "../common/table/filters/TextFilter";
import DateTimeFilter from "../common/table/filters/DateTimeFilter";
import SelectFilter from "../common/table/filters/SelectFilter";
import {
  InternalUseOnlyOptions,
  ShackleStateOptions,
  LockStateOptions,
} from "../../models/bikes/BikesListModel";

const renderIconDecoration = (bike, iconElement) => {
  const color = bike.hasAlarmEnabled ? "error" : "default";
  if (bike.ongoingPause)
    return (
      <Badge
        color={color}
        badgeContent={<PauseIcon style={{ width: 16, height: 16 }} />}
      >
        {iconElement}
      </Badge>
    );
  if (bike.isRentalRestricted)
    return (
      <Badge
        color={color}
        badgeContent={<ServiceIcon style={{ width: 16, height: 16 }} />}
      >
        {iconElement}
      </Badge>
    );
  if (bike.isRented)
    return (
      <Badge
        color={color}
        badgeContent={<RideIcon style={{ width: 16, height: 16 }} />}
      >
        {iconElement}
      </Badge>
    );
  if (bike.isReserved)
    return (
      <Badge
        color={color}
        badgeContent={<ReservationIcon style={{ width: 16, height: 16 }} />}
      >
        {iconElement}
      </Badge>
    );
  if (bike.hasAlarmEnabled)
    return (
      <Badge
        color={color}
        badgeContent={<AlarmIcon style={{ width: 16, height: 16 }} />}
      >
        {iconElement}
      </Badge>
    );
  if (bike.isOrientedUpright === false)
    return (
      <Badge
        color={color}
        badgeContent={<NotUprightIcon style={{ width: 16, height: 16 }} />}
      >
        {iconElement}
      </Badge>
    );

  return iconElement;
};

const avatarStyle = { width: 30, height: 30, margin: 0, padding: 0 };
/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: "",
    id: "icon",
    Cell: (row) =>
      renderIconDecoration(
        row.original,
        <Avatar src={row.original.bikeIcon} style={avatarStyle} />
      ),
    width: 60,
    sortable: false,
    Filter: () => null,
  },
  {
    Header: "Operator",
    id: "tenant",
    accessor: (b) => b.tenant,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Numer",
    id: "bikeNumber",
    accessor: (b) => b.bikeNumber,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Typ zamka",
    id: "lockDeviceType",
    accessor: (b) => b.lockDeviceType,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "IMEI",
    id: "lockImei",
    accessor: (b) => b.lockImei,
    width: 120,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Nr telefonu",
    id: "lockPhoneNumber",
    accessor: (b) => b.lockPhoneNumber,
    width: 100,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Bateria",
    id: "lockBatteryLevel",
    accessor: (b) => `${b.lockBatteryLevel || 0}%`,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Bateria roweru",
    id: "bikeBatteryLevel",
    accessor: (b) =>
      b.bikeBatteryLevel != null
        ? `${b.bikeBatteryLevel}%`
        : "-",
    width: 120,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Firmware",
    id: "lockFirmware",
    accessor: (b) => b.lockFirmware,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Blokada",
    id: "lockState",
    accessor: (b) =>
      b.lockState === LockStateOptions.opened.value
        ? LockStateOptions.opened.name
        : LockStateOptions.closed.name,
    width: 80,
    Filter: ({ filter, onChange }) => (
      <SelectFilter
        onChange={onChange}
        filter={filter}
        items={LockStateOptions}
        label="filtruj..."
      />
    ),
  },
  {
    Header: "Podkowa",
    id: "shackleState",
    accessor: (b) =>
      b.shackleState === ShackleStateOptions.opened.value
        ? ShackleStateOptions.opened.name
        : ShackleStateOptions.closed.name,
    width: 80,
    Filter: ({ filter, onChange }) => (
      <SelectFilter
        onChange={onChange}
        filter={filter}
        items={ShackleStateOptions}
        label="filtruj..."
      />
    ),
  },
  {
    Header: "Czas locka",
    id: "lockTimestamp",
    accessor: (b) =>
      b.lockTimestamp
        ? moment(b.lockTimestamp).local().format("DD.MM.YYYY HH:mm")
        : null,
    width: 120,
    Filter: ({ filter, onChange }) => (
      <DateTimeFilter
        onChange={onChange}
        filter={filter}
        label="filtruj od..."
      />
    ),
  },
  {
    Header: "Czas GPS",
    id: "gpsTimestamp",
    accessor: (b) =>
      b.gpsTimestamp
        ? moment(b.gpsTimestamp).local().format("DD.MM.YYYY HH:mm")
        : null,
    width: 120,
    Filter: ({ filter, onChange }) => (
      <DateTimeFilter
        onChange={onChange}
        filter={filter}
        label="filtruj od..."
      />
    ),
  },
  {
    Header: "Satelity",
    id: "satellites",
    accessor: (b) => b.satellites,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "AGPS",
    id: "agps",
    accessor: (b) => b.agps,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Typ",
    id: "typeName",
    accessor: (b) => b.typeName,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Testowy",
    id: "internalUseOnly",
    accessor: (b) => (b.internalUseOnly ? "TAK" : "NIE"),
    width: 90,
    Filter: ({ filter, onChange }) => (
      <SelectFilter
        onChange={onChange}
        filter={filter}
        items={InternalUseOnlyOptions}
        label="filtruj..."
      />
    ),
  },
  {
    Header: "Ostatni przejazd",
    id: "lastRideDate",
    Cell: (row) =>
      row.original.lastRideDate ? (
        <a
          href={row.original.lastRideUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {moment(row.original.lastRideDate).local().format("DD.MM.YYYY HH:mm")}
        </a>
      ) : null,
    width: 120,
    Filter: () => null,
  },
  {
    Header: "Powód wyłączenia",
    id: "rentalRestrictionReason",
    accessor: (b) => b.rentalRestrictionReason,
    width: 130,
    Filter: () => null,
    sortable: false,
  },
];
