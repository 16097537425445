import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { actions } from '../../actions/tenantsActions';
import FiltersPanel from './FiltersPanel';
import Table from '../common/table/Table';
import { columns } from './Columns';

class TenantsList extends React.Component {
  componentDidMount() {
    const tenants = this.props.tenants;
    this.props.actions.loadFreshTenants(1, tenants.paging.pageSize, tenants.sortedBy, tenants.filteredBy);
  }

  handleSortChange = sortBy => {
    const tenants = this.props.tenants;
    this.props.actions.loadFreshTenants(1, tenants.paging.pageSize, sortBy, tenants.filteredBy);
  }

  handleFiltersChange = filterBy => {
    const tenants = this.props.tenants;
    this.props.actions.loadFreshTenants(1, tenants.paging.pageSize, tenants.sortedBy, filterBy.filter(f => f.value));
  }

  handlePageChange = nextPage => {
    this.props.actions.loadNextTenants(nextPage);
  }

  handlePageSizeChange = nextPageSize => {
    const tenants = this.props.tenants;
    this.props.actions.loadFreshTenants(1, nextPageSize, tenants.sortedBy, tenants.filteredBy);
  }

  render() {
    const { tenants } = this.props;

    return (
      <div style={{ margin: 16 }}>
        <FiltersPanel filteredBy={tenants.filteredBy} onFilteredChange={this.handleFiltersChange} />
        <Table
          columns={columns}
          data={tenants.records}
          filteredBy={tenants.filteredBy}
          sortedBy={tenants.sortedBy}
          paging={tenants.paging}
          onFilteredChange={this.handleFiltersChange}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          loading={tenants.loading}
          minRows={0}
          keyField="userId"
        />
      </div>
    );
  }
}

TenantsList.propTypes = {
  actions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  tenants: PropTypes.object,
};

TenantsList.defaultProps = {
  loading: false,
  tenants: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    tenants: state.tenants.list.pagedData,
    loading: state.tenants.list.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TenantsList));
