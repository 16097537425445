import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logo.png';
import iot from '../../assets/logotypes/iot.png';
import bluemedia from '../../assets/logotypes/bluemedia.png';
import orange from '../../assets/logotypes/orange.png';
import roovee from '../../assets/logotypes/roovee.png';
import './AnonymousContentPanelStyles.scss';

const AnonymousContentPanel = ({ children }) => (
  <div className="anonymous-content-panel">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <img src={logo} alt="roovee" className="logo img-responsive" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-10 col-xs-offset-1 col-sm-4 col-sm-offset-4 col-lg-2 col-lg-offset-5">
              {children}
            </div>
          </div>
        </div>
      </main>

      <footer>
        <div className="container-fluid">
          <div className="row">
              <div className="col-sm-3">
                <img src={roovee} alt="roovee" />
                <p>&copy; 2020 ROOVEE<br />
                  Biuro Obsługi Klienta:<br />
                </p>
                <p>tel.: +48 88 77 66 833 <br /> <a href="mailto:bok@roovee.eu">bok@roovee.eu</a></p>
              </div>

              <div className="col-sm-3">
                <img src={iot} alt="Internet Of Things" />
              </div>

              <div className="col-sm-3">
                <img src={orange} alt="Orange" />
              </div>

              <div className="col-sm-3">
                <img src={bluemedia} alt="Blue Media" />
              </div>
          </div>
        </div>
      </footer>
    </div>
);

AnonymousContentPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnonymousContentPanel;
