import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import ServiceIcon from '@material-ui/icons/BuildTwoTone';
import { actions } from '../../actions/bikesActions';

const styles = theme => ({
  button: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

class AllowRentalsAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      submitting: false,
    };

    this.handleAllowRentalsStart = this.handleAllowRentalsStart.bind(this);
    this.handleAllowRentalsCancel = this.handleAllowRentalsCancel.bind(this);
    this.handleAllowRentals = this.handleAllowRentals.bind(this);
  }

  handleAllowRentalsStart() {
    this.setState({
      dialogVisible: true,
    });
  }

  handleAllowRentalsCancel() {
    this.setState({
      dialogVisible: false
    });
  }

  handleAllowRentals() {
    this.setState({ submitting: true });
    this.props.actions
      .allowRentals(this.props.bikesIds)
      .then(() => this.setState({ dialogVisible: false, submitting: false }))
      .then(() => this.props.onActionExecuted());
  }

  render() {
    const { bikesIds, fullScreen, classes, menuItem, disabled } = this.props;
    return (
      <React.Fragment>
        {menuItem
        ? <MenuItem onClick={this.handleAllowRentalsStart} disabled={disabled}><ServiceIcon className={classes.icon} /> Przywróć do użytku</MenuItem>
        : <Button size="small" variant="outlined" className={classes.button} onClick={this.handleAllowRentalsStart} disabled={disabled}>
          <ServiceIcon className={classes.icon} /> Przywróć do użytku
        </Button>}
        <Dialog open={this.state.dialogVisible} fullScreen={fullScreen} fullWidth={true}>
          <DialogTitle>Przywrócenie roweru do użytku</DialogTitle>
          <DialogContent>
            Liczba wybranych rowerów: <strong>{bikesIds.length}</strong>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAllowRentalsCancel} disabled={this.state.submitting}>
              Anuluj
          </Button>
            <Button
              onClick={this.handleAllowRentals}
              disabled={this.state.submitting}
              color="secondary"
            >
              Potwierdź
          </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

AllowRentalsAction.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  bikesIds: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onActionExecuted: PropTypes.func.isRequired,
  menuItem: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default compose(
  connect(null, mapDispatchToProps),
  withMobileDialog(),
  withStyles(styles)
)(AllowRentalsAction);
