import React, {useEffect, useState} from "react";
import DocumentTitle from 'react-document-title';
import {useTranslation} from "react-i18next";
import ReportTable from "./ReportTable";
import {Button, Paper} from "@material-ui/core";
import NewReportDialog from "./NewReportDialog";
import {useDispatch, useSelector} from "react-redux";
import {actions, types} from '../../actions/reportsActions'
import {useParams} from "react-router";

const AutomaticReports = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);


  const fetchingTemplateList = useSelector(state => state.reports.fetchingTemplateList, true);
  const templateList = useSelector(state => state.reports.templateList, []);
  const submitting = useSelector(state => state.reports.submitting, false);
  const reportConfigurationList = useSelector(state => state.reports.reportConfigurationList, [])
  const fetchingReportConfigurationList = useSelector(state => state.reports.fetchingReportConfigurationList, true);


  useEffect(() => {
    if (openDialog === true){
      dispatch(actions.loadReportsTemplateList(id))
    }
  }, [openDialog])

  useEffect(() => {
    dispatch(actions.getReportConfigurations(id));
  }, [])

  const onSubmit = async (data, tenantId) => {
    const res = await dispatch(actions.createReport(data,tenantId))
    if (res.type === types.CREATE_REPORT_CONFIGURATION_SUCCESS){
      setOpenDialog(false);
      dispatch(actions.getReportConfigurations(id));
    }
  }

  return (
    <DocumentTitle title={t('automaticReport.title')}>
      <Paper style={{ marginRight: 60, marginLeft: 60, marginTop: 80, padding: 16 }}>
        <div style={{ textAlign: 'right', paddingTop: 20}}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenDialog(true)}
          >
            Dodaj Raport
          </Button>
        </div>
        <ReportTable
          data={reportConfigurationList}
          fetching={fetchingReportConfigurationList}
        />
        <NewReportDialog
          loading={fetchingTemplateList}
          submitting={submitting}
          templateList={templateList}
          open={openDialog}
          onSubmit={(value) => onSubmit(value, id)}
          onCancel={() => setOpenDialog(false)}
        />
      </Paper>
    </DocumentTitle>
  )

}

export default AutomaticReports
