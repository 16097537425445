import React from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {submit} from "redux-form";
import PropTypes from "prop-types";
import TokenForm from "../../form/TokenForm";

const NewTokenDialog = ({open, onCancel, onSubmit, loading, submitting, permissions}) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>{t('apiAccess.newTokenDialog.title')}</DialogTitle>
      <DialogContent>
        {!loading && <TokenForm onSubmit={onSubmit} permissions={permissions}/>}
        {loading && <div style={{textAlign: "center"}}>
          <CircularProgress />
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting || loading}>
          {t('common.cancelButton')}
        </Button>
        <Button
          disabled={submitting || loading}
          color="primary"
          variant="contained"
          onClick={() => dispatch(submit('tokenForm'))}
        >
          {t('apiAccess.newTokenDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

NewTokenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  permissions: PropTypes.array.isRequired,
};

export default NewTokenDialog
