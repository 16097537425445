import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import { submit } from 'redux-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import TenantDetailsForm from './TenantDetailsForm';

const NewTenantDialog = ({ open, onSubmit, onCancel, submitting, dispatch, t }) => (
  <Dialog open={open} fullWidth={true} onClose={onCancel}>
    <DialogTitle>{t('tenants.newTenantDialog.title')}</DialogTitle>
    <DialogContent>
      <TenantDetailsForm onSubmit={onSubmit} initialValues={{}} submitting={submitting} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} disabled={submitting}>
        {t('common.cancelButton')}
      </Button>
      <Button
        disabled={submitting}
        color="primary"
        variant="contained"
        onClick={() => dispatch(submit('tenantDetailsForm'))}
      >
        {t('tenants.newTenantDialog.submitButton')}
      </Button>
    </DialogActions>
  </Dialog>
);

NewTenantDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default compose(
  connect(),
  withTranslation()
)(NewTenantDialog);
