import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { Button } from '@material-ui/core';
import TenantsList from './TenantsList';
import NewTenantDialog from './NewTenantDialog';
import { actions } from '../../actions/tenantsActions';
import routePaths from '../../routePaths';

class TenantsPage extends React.Component {
  state = {
    tenantDialogVisible: false,
    submitting: false,
  }

  handleAddNewTenant = () => this.setState({ tenantDialogVisible: true });

  handleCreateNewTenant = data => {
    this.setState({ submitting: true });
    this.props.actions.create(data)
      .finally(() => {
        this.setState({ submitting: false, tenantDialogVisible: false });
        this.props.history.push(routePaths.tenantDetails.replace(':id', data.id));
      });
  }

  handleCancelNewTenantCreation = () => this.setState({ tenantDialogVisible: false });

  render() {
    const { t } = this.props;
    const { submitting, tenantDialogVisible } = this.state;

    return (
      <DocumentTitle title={t('tenants.title')}>
        <div className="page">
          <div style={{padding: 16}}>
            <div style={{ textAlign: 'right', marginBottom: 16 }}>
              <Button variant="contained" color="primary" onClick={() => this.handleAddNewTenant()}>{t('tenants.newTenantButton')}</Button>
            </div>
            <TenantsList />
            <NewTenantDialog
              open={tenantDialogVisible}
              submitting={submitting}
              onSubmit={this.handleCreateNewTenant}
              onCancel={this.handleCancelNewTenantCreation}
            />
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

TenantsPage.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation()
)(TenantsPage);

