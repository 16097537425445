import apiBase from './apiBase';

class MobileUserAccountsApi {
  static getAccounts(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize
      },
      filterBy: Object.assign({}, ...filterBy.map(x => ({ [x.id]: x.value }))),
      sortBy: Object.assign({}, ...sortBy.map(x=> ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/mobileUserAccounts`, payload, { timeout : 90000 });
  }

  static getDetails(id) {
    return apiBase
      .create()
      .get(`/mobileUserAccounts/${id}`);
  }

  static exportToSpreadsheet(sortBy, filterBy){
    const payload = {
      filterBy: Object.assign({}, ...filterBy.map(x => ({ [x.id]: x.value }))),
      sortBy: Object.assign({}, ...sortBy.map(x => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/mobileUserAccounts/export`, payload, { responseType: 'blob' });
  }

  static unblockVerification(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/unblockVerification`);
  }

  static grantTesterRole(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/grantTesterRole`);
  }

  static revokeTesterRole(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/revokeTesterRole`);
  }

  static withdraw(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/withdrawAvailableFunds`);
  }

  static registerFundsReturn(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/register-all-funds-withdrawal`, {});
  }

  static lock(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/lock`);
  }

  static unlock(id) {
    return apiBase
      .create()
      .post(`/mobileUserAccounts/${id}/unlock`);
  }

  static delete(id) {
    return apiBase
      .create()
      .delete(`/mobileUserAccounts/${id}`);
  }
}

export default MobileUserAccountsApi;
