import api from '../api/virtualLocksApi';
import { actions as notificationActions } from './notificationActions';
import * as logger from '../utils/logger';

export const types = {
  GET_LOCKS: 'GET_LOCKS',
  GET_LOCKS_SUCCESS: 'GET_LOCKS_SUCCESS',
  CREATE_LOCK_SUBMIT: 'CREATE_LOCK_SUBMIT',
  CREATE_LOCK_SUCCESS: 'CREATE_LOCK_SUCCESS',
  CREATE_LOCK_FAILURE: 'CREATE_LOCK_FAILURE',
  DELETE_LOCK_SUBMIT: 'DELETE_LOCK_SUBMIT',
  DELETE_LOCK_SUCCESS: 'DELETE_LOCK_SUCCESS',
  DELETE_LOCK_FAILURE: 'DELETE_LOCK_FAILURE',
  ENABLE_LOCK_SUBMIT: 'ENABLE_LOCK_SUBMIT',
  ENABLE_LOCK_SUCCESS: 'ENABLE_LOCK_SUCCESS',
  ENABLE_LOCK_FAILURE: 'ENABLE_LOCK_FAILURE',
  DISABLE_LOCK_SUBMIT: 'DISABLE_LOCK_SUBMIT',
  DISABLE_LOCK_SUCCESS: 'DISABLE_LOCK_SUCCESS',
  DISABLE_LOCK_FAILURE: 'DISABLE_LOCK_FAILURE',
  OPEN_LOCK_SUBMIT: 'OPEN_LOCK_SUBMIT',
  OPEN_LOCK_SUCCESS: 'OPEN_LOCK_SUCCESS',
  OPEN_LOCK_FAILURE: 'OPEN_LOCK_FAILURE',
  CLOSE_LOCK_SUBMIT: 'CLOSE_LOCK_SUBMIT',
  CLOSE_LOCK_SUCCESS: 'CLOSE_LOCK_SUCCESS',
  CLOSE_LOCK_FAILURE: 'CLOSE_LOCK_FAILURE',
  TAP_NFC_CARD: 'TAP_NFC_CARD',
  TAP_NFC_CARD_SUCCESS: 'TAP_NFC_CARD_SUCCESS',
  TAP_NFC_CARD_FAILURE: 'TAP_NFC_CARD_FAILURE',
};

export const actions = {
  getAll: () => (dispatch) => {
    dispatch({ type: types.GET_LOCKS });
    return api.getAll().then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_LOCKS_SUCCESS, result: res.data || [] });
      }
    });
  },

  create: data => (dispatch) => {
    dispatch({ type: types.CREATE_LOCK_SUBMIT });

    return api.create(data).then(res => {
      if (res.ok) {
        dispatch({ type: types.CREATE_LOCK_SUCCESS, lock: res.data });
        dispatch(notificationActions.showSuccess('Utworzono nowy wirtualny zamek'));
      } else {
        dispatch({ type: types.CREATE_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas tworzenia zamka'));
        return logger.error(res.data);
      }
    });
  },

  delete: imei => (dispatch) => {
    dispatch({ type: types.DELETE_LOCK_SUBMIT });
    return api.delete(imei).then(res => {
      if (res.ok) {
        dispatch({ type: types.DELETE_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został usunięty'));
      } else {
        dispatch({ type: types.DELETE_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas usuwania zamka'));
        return logger.error(res.data);
      }
    });
  },

  enable: imei => (dispatch) => {
    dispatch({ type: types.ENABLE_LOCK_SUBMIT });
    return api.enable(imei).then(res => {
      if (res.ok) {
        dispatch({ type: types.ENABLE_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został włączony'));
      } else {
        dispatch({ type: types.ENABLE_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas włączania zamka'));
        return logger.error(res.data);
      }
    });
  },

  disable: imei => (dispatch) => {
    dispatch({ type: types.DISABLE_LOCK_SUBMIT });
    return api.disable(imei).then(res => {
      if (res.ok) {
        dispatch({ type: types.DISABLE_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został wyłączony'));
      } else {
        dispatch({ type: types.DISABLE_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas wyłączania zamka'));
        return logger.error(res.data);
      }
    });
  },

  open: imei => (dispatch) => {
    dispatch({ type: types.OPEN_LOCK_SUBMIT });
    return api.open(imei).then(res => {
      if (res.ok) {
        dispatch({ type: types.OPEN_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został otwarty'));
      } else {
        dispatch({ type: types.OPEN_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas otwierania zamka'));
        return logger.error(res.data);
      }
    });
  },

  close: imei => (dispatch) => {
    dispatch({ type: types.CLOSE_LOCK_SUBMIT });
    return api.close(imei).then(res => {
      if (res.ok) {
        dispatch({ type: types.CLOSE_LOCK_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Wirtualny zamek został zamknięty'));
      } else {
        dispatch({ type: types.CLOSE_LOCK_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas zamykania zamka'));
        return logger.error(res.data);
      }
    });
  },

  tapNfcCard: (imei, nfcCardId) => (dispatch) => {
    dispatch({ type: types.TAP_NFC_CARD });
    return api.tapNfcCard(imei, nfcCardId).then(res => {
      if (res.ok) {
        dispatch({ type: types.TAP_NFC_CARD_SUCCESS, imei });
        dispatch(notificationActions.showSuccess('Zbliżono kartę do wirtualnego zamka'));
        return true;
      } else {
        dispatch({ type: types.TAP_NFC_CARD_FAILURE });
        dispatch(notificationActions.showError('Wystąpił problem podczas zbliżania karty do wirtualnego zamka'));
        logger.error(res.data);
        return false;
      }
    });
  },
};
