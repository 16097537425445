import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/notificationActions';
import { NotificationModel, NotificationTypes } from '../models/NotificationModel';

export default function notificationsReducer(state = Immutable(initialState.notifications), action) {
  switch (action.type) {
    case types.SHOW_ERROR_NOTIFICATION: {
      return [...state, new NotificationModel(action.message, NotificationTypes.error)];
    }
    case types.SHOW_SUCCESS_NOTIFICATION: {
      return [...state, new NotificationModel(action.message, NotificationTypes.success)];
    }
    case types.CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
}
