import React from "react";
import numberFormatter from "../../utils/numberFormatter";
import SelectFilter from "../common/table/filters/SelectFilter";
import {WithdrawalStatus} from "../../models/WithdrawalModel";
import moment from "moment/moment";
import TextFilter from "../common/table/filters/TextFilter";

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: "Status",
    id: "status",
    accessor: (b) => {
      if (b.status === 0) return WithdrawalStatus.opened.name
      if (b.status === 1 || b.status === 'opened') return WithdrawalStatus.opened.name
      if (b.status === 2 || b.status === 'accepted') return WithdrawalStatus.accepted.name
      if (b.status === 3 || b.status === 'rejected') return WithdrawalStatus.rejected.name
      return null
    },
    width: 130,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={WithdrawalStatus} label="filtruj..." />,

  },
  {
    Header: "Data",
    id: "createdAt",
    accessor: b => b.createdAt ? moment(b.createdAt).local().format('DD.MM.YYYY HH:mm') : '',
    width: 140,
    Filter: () => null,
    sortable: true,

  },
  {
    Header: "Id formularza",
    id: "id",
    accessor: (b) => b.id,
    width: 90,
    Filter: () => null,
  },
  {
    Header: "Imię",
    id: "firstName",
    accessor: (b) => b.firstName,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),

  },
  {
    Header: "Nazwisko",
    id: "lastName",
    accessor: (b) => b.lastName,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),

  },
  {
    Header: "Email",
    id: "email",
    accessor: (b) => b.email,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),

  },
  {
    Header: "Numer telefonu",
    id: "phoneNumber",
    accessor: (b) => b.phoneNumber,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Id usera",
    id: "userId",
    accessor: (b) => b.userId,
    width: 90,
    Filter: () => null,

  },
  {
    Header: "Adres",
    id: "address",
    accessor: (b) => b.address,
    width: 90,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "Iban",
    id: "countryCode",
    accessor: (b) => b.countryCode,
    width: 90,
    Filter: () => null,

  },
  {
    Header: "Numer konta",
    id: "accountNumber",
    accessor: (b) => b.accountNumber,
    width: 140,
    Filter: ({ filter, onChange }) => (
      <TextFilter onChange={onChange} filter={filter} label="filtruj..." />
    ),
  },
  {
    Header: "SWIFT",
    id: "swift",
    accessor: (b) => b.swift,
    width: 90,
    Filter: () => null,

  },
  {
    Header: "Kwota wypłaty",
    id: "amount",
    accessor: b => `${numberFormatter.format(b.amount || 0)} zł`,
    width: 90,
    Filter: () => null,

  },
];
