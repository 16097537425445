import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/tenantsActions';

export default function tenantDetailsReducer(state = Immutable(initialState.tenantDetails), action) {
  switch (action.type) {
    case types.GET_TENANT: {
      return { fetching: true };
    }
    case types.GET_TENANT_SUCCESS: {
      const { data } = action;
      
      return {
        fetching: false,
        data,
      };
    }
    case types.GET_TENANT_FAILURE: {
      return { fetching: false, data: null };
    }
    case types.CREATE_OPERATOR_ACCOUNT_SUCCESS: {
      const updatedTenant = Object.assign({}, state.data);
      updatedTenant.accounts = [...updatedTenant.accounts, action.data];
      return {
        fetching: false,
        data: updatedTenant,
      };
    }
    default:
      return state;  
  }
}
