import React from 'react'
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";

const DeactivateTokenDialog = ({open, onCancel, onSubmit, submitting, data}) => {

  const { t } = useTranslation();

  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>Deaktywacja tokena</DialogTitle>
      <DialogContent>
        <div>Czy na pewno chcesz deaktywować token?</div>
        <div>Operacja ta jest nieodwracalna</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting}>
          {t('common.cancelButton')}
        </Button>
        <Button
          disabled={submitting}
          color="primary"
          variant="contained"
          onClick={() => onSubmit(data.tenantId, data.id)}
        >
          {t('apiAccess.newTokenDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )

}

DeactivateTokenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default DeactivateTokenDialog
