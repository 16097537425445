/*eslint global-require: "off"*/
'use strict';

import areIntlLocalesSupported from 'intl-locales-supported';

function numberFormatter() {
  let NumberFormat;
  if (areIntlLocalesSupported(['pl-PL'])) {
    NumberFormat = global.Intl.NumberFormat;
  } else {
    const IntlPolyfill = require('intl');
    NumberFormat = IntlPolyfill.NumberFormat;
    require('intl/locale-data/jsonp/pl-PL');
  }
  const formatter = new NumberFormat('pl-PL', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return {
    format: formatter.format
  };
}

export default numberFormatter();
