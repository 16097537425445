import React from 'react'
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import NewReportForm from "./NewReportForm";
import {useDispatch} from "react-redux";
import {submit} from "redux-form";

const NewReportDialog = ({ open, onSubmit, onCancel, submitting, loading, templateList }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  return(
    <Dialog open={open} fullWidth={false} onClose={onCancel}>
      <DialogTitle>{t('automaticReport.newReportDialog.title')}</DialogTitle>
      <DialogContent>
        {!loading && <NewReportForm onSubmit={onSubmit} templateList={templateList} />}
        {loading && <div style={{textAlign: "center"}}>
          <CircularProgress />
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={submitting || loading}>
          {t('common.cancelButton')}
        </Button>
        <Button
          disabled={submitting || loading}
          color="primary"
          variant="contained"
          onClick={() => dispatch(submit('newReportForm'))}
        >
          {t('automaticReport.newReportDialog.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  templateList: PropTypes.array.isRequired,
};

export default NewReportDialog



