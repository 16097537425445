import reportsAPI from '../api/reportsApi';
import {actions as notificationActions} from "./notificationActions";

export const types = {
  LOAD_REPORTS_TEMPLATE_LIST: 'LOAD_REPORTS_TEMPLATE_LIST',
  LOAD_REPORTS_TEMPLATE_LIST_SUCCESS: 'LOAD_REPORTS_TEMPLATE_LIST_SUCCESS',
  LOAD_REPORTS_TEMPLATE_LIST_FAILED: 'LOAD_REPORTS_TEMPLATE_LIST_FAILED',
  CREATE_REPORT_CONFIGURATION: 'CREATE_REPORT_CONFIGURATION',
  CREATE_REPORT_CONFIGURATION_SUCCESS: 'CREATE_REPORT_CONFIGURATION_SUCCESS',
  CREATE_REPORT_CONFIGURATION_FAILED: 'CREATE_REPORT_CONFIGURATION_FAILED',
  DEACTIVATION_REPORT_CONFIGURATION: 'DEACTIVATION_REPORT_CONFIGURATION',
  DEACTIVATION_REPORT_CONFIGURATION_SUCCESS: 'DEACTIVATION_REPORT_CONFIGURATION_SUCCESS',
  DEACTIVATION_REPORT_CONFIGURATION_FAILED: 'DEACTIVATION_REPORT_CONFIGURATION_FAILED',
  REMOVE_REPORT_CONFIGURATION: 'REMOVE_REPORT_CONFIGURATION',
  REMOVE_REPORT_CONFIGURATION_SUCCESS: 'REMOVE_REPORT_CONFIGURATION_SUCCESS',
  REMOVE_REPORT_CONFIGURATION_FAILED: 'REMOVE_REPORT_CONFIGURATION_FAILED',
  ACTIVATION_REPORT_CONFIGURATION: 'DEACTIVATION_REPORT_CONFIGURATION_FAILED',
  ACTIVATION_REPORT_CONFIGURATION_SUCCESS: 'ACTIVATION_REPORT_CONFIGURATION_SUCCESS',
  ACTIVATION_REPORT_CONFIGURATION_FAILED: 'ACTIVATION_REPORT_CONFIGURATION_FAILED',
  UPDATE_REPORT_CONFIGURATION: 'UPDATE_REPORT_CONFIGURATION',
  UPDATE_REPORT_CONFIGURATION_SUCCESS: 'UPDATE_REPORT_CONFIGURATION_SUCCESS',
  UPDATE_REPORT_CONFIGURATION_FAILED: 'UPDATE_REPORT_CONFIGURATION_FAILED',
  GET_REPORTS_CONFIGURATION_LIST: 'GET_REPORTS_CONFIGURATION_LIST',
  GET_REPORTS_CONFIGURATION_LIST_SUCCESS: 'GET_REPORTS_CONFIGURATION_LIST_SUCCESS',
  GET_REPORTS_CONFIGURATION_LIST_FAILED: 'GET_REPORTS_CONFIGURATION_LIST_FAILED',
  GET_REPORTS_CONFIGURATION_HISTORY: 'GET_REPORTS_CONFIGURATION_HISTORY',
  GET_REPORTS_CONFIGURATION_HISTORY_SUCCESS: 'GET_REPORTS_CONFIGURATION_HISTORY_SUCCESS',
  GET_REPORTS_CONFIGURATION_HISTORY_FAILED: 'GET_REPORTS_CONFIGURATION_HISTORY_FAILED',
  GET_WITHDRAWAL_REPORT: 'GET_WITHDRAWAL_REPORT',
  GET_WITHDRAWAL_REPORT_SUCCESS: 'GET_WITHDRAWAL_REPORT_SUCCESS',
  GET_WITHDRAWAL_REPORT_FAILED: 'GET_WITHDRAWAL_REPORT_FAILED',
  GET_WITHDRAWAL_LIST: 'GET_WITHDRAWAL_LIST',
  GET_WITHDRAWAL_LIST_SUCCESS: 'GET_WITHDRAWAL_LIST_SUCCESS',
  GET_WITHDRAWAL_LIST_FAILED: 'GET_WITHDRAWAL_LIST_FAILED',
  CHANGE_WITHDRAWAL_FORM_STATUS: 'CHANGE_WITHDRAWAL_FORM_STATUS',
  CHANGE_WITHDRAWAL_FORM_STATUS_SUCCESS: 'CHANGE_WITHDRAWAL_FORM_STATUS_SUCCESS',
  CHANGE_WITHDRAWAL_FORM_STATUS_FAILED: 'CHANGE_WITHDRAWAL_FORM_STATUS_FAILED',
  GET_NEXT_WITHDRAWAL_REPORT: 'GET_NEXT_WITHDRAWAL_REPORT',
  GET_NEXT_WITHDRAWAL_REPORT_SUCCESS: 'GET_NEXT_WITHDRAWAL_REPORT_SUCCESS'
}
function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}
export const actions = {
  loadReportsTemplateList: (tenantId) => async (dispatch) => {
    dispatch({ type: types.LOAD_REPORTS_TEMPLATE_LIST });
    try {
      const result = await reportsAPI.getReportsTemplates(tenantId)
      if(result.ok) {
        return dispatch({ type: types.LOAD_REPORTS_TEMPLATE_LIST_SUCCESS, result: result.data });
      }
      return dispatch({ type: types.LOAD_REPORTS_TEMPLATE_LIST_FAILED });
    }
    catch (error){
      return dispatch({ type: types.LOAD_REPORTS_TEMPLATE_LIST_FAILED });
    }
  },
  createReport: (data, tenantId) => async (dispatch) => {
    dispatch({ type: types.CREATE_REPORT_CONFIGURATION });
    try {
      const result = await reportsAPI.createReportConfigurations(data,tenantId)
      if(result.ok) {
        return dispatch({ type: types.CREATE_REPORT_CONFIGURATION_SUCCESS });
      }
      return dispatch({ type: types.CREATE_REPORT_CONFIGURATION_FAILED });
    }
    catch (error){
      return dispatch({ type: types.CREATE_REPORT_CONFIGURATION_FAILED });
    }
  },
  deactivateReport: (tenantId, id) => async (dispatch) => {
    dispatch({ type: types.DEACTIVATION_REPORT_CONFIGURATION });
    try {
      const result = await reportsAPI.deactivateReportsConfigurations(tenantId, id)
      if(result.ok) {
        return dispatch({ type: types.DEACTIVATION_REPORT_CONFIGURATION_SUCCESS });
      }
      return dispatch({ type: types.DEACTIVATION_REPORT_CONFIGURATION_FAILED });
    }
    catch (error){
      return dispatch({ type: types.DEACTIVATION_REPORT_CONFIGURATION_FAILED });
    }
  },
  removeReport: (tenantId, id) => async (dispatch) => {
    dispatch({ type: types.REMOVE_REPORT_CONFIGURATION });
    try {
      const result = await reportsAPI.removeReportsConfigurations(tenantId, id)
      if(result.ok) {
        return dispatch({ type: types.REMOVE_REPORT_CONFIGURATION_SUCCESS });
      }
      return dispatch({ type: types.REMOVE_REPORT_CONFIGURATION_FAILED });
    }
    catch (error){
      return dispatch({ type: types.REMOVE_REPORT_CONFIGURATION_FAILED });
    }
  },
  activateReport: (tenantId, id) => async (dispatch) => {
    dispatch({ type: types.ACTIVATION_REPORT_CONFIGURATION });
    try {
      const result = await reportsAPI.activateReportsConfigurations(tenantId, id)
      if(result.ok) {
        return dispatch({ type: types.ACTIVATION_REPORT_CONFIGURATION_SUCCESS });
      }
      return dispatch({ type: types.ACTIVATION_REPORT_CONFIGURATION_FAILED });
    }
    catch (error){
      return dispatch({ type: types.ACTIVATION_REPORT_CONFIGURATION_FAILED });
    }
  },
  updateReportsConfigurations: (tenantId, id, data) => async (dispatch) => {
    dispatch({ type: types.UPDATE_REPORT_CONFIGURATION });
    try {
      const result = await reportsAPI.updateReportsConfigurations(tenantId, {...data, id})
      if(result.ok) {
        return dispatch({ type: types.UPDATE_REPORT_CONFIGURATION_SUCCESS });
      }
      return dispatch({ type: types.UPDATE_REPORT_CONFIGURATION_FAILED });
    }
    catch (error){
      return dispatch({ type: types.UPDATE_REPORT_CONFIGURATION_FAILED });
    }
  },
  getReportConfigurations: (tenantId) => async (dispatch) => {
    dispatch({ type: types.GET_REPORTS_CONFIGURATION_LIST });
    try {
      const result = await reportsAPI.getReportsConfigurations(tenantId)
      if(result.ok) {
        return dispatch({ type: types.GET_REPORTS_CONFIGURATION_LIST_SUCCESS, result: result.data });
      }
      return dispatch({ type: types.GET_REPORTS_CONFIGURATION_LIST_FAILED });
    }
    catch (error){
      return dispatch({ type: types.GET_REPORTS_CONFIGURATION_LIST_FAILED });
    }
  },
  getReportConfigurationHistory: (tenantId, reportId) => async (dispatch) => {
    dispatch({ type: types.GET_REPORTS_CONFIGURATION_HISTORY });
    try {
      const result = await reportsAPI.getReportsConfigurationsHistory(tenantId, reportId)
      if(result.ok) {
        return dispatch({ type: types.GET_REPORTS_CONFIGURATION_HISTORY_SUCCESS, result: result.data });
      }
      return dispatch({ type: types.GET_REPORTS_CONFIGURATION_HISTORY_FAILED });
    }
    catch (error){
      return dispatch({ type: types.GET_REPORTS_CONFIGURATION_HISTORY_FAILED });
    }
  },
  downloadPdf: (tenantId, id) => async dispatch => {
    return await reportsAPI
      .downloadPdf(tenantId, id)
      .then(response => {
        if (response.ok) {
          let a = document.createElement('a');
          a.href = response.data;
          a.download = 'raport.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          dispatch(notificationActions.showError('Wystąpił problem podczas pobierania raportu'));
        }
      });
  },
  downloadXlsx: (tenantId, id) => async dispatch => {
    return await reportsAPI
      .downloadXlsx(tenantId, id)
      .then(response => {
        if (response.ok) {
          let a = document.createElement('a');
          a.href = response.data;
          a.download = 'raport.xlsx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          dispatch(notificationActions.showError('Wystąpił problem podczas pobierania raportu'));
        }
      });
  },
  loadWithdrawalReportReports: (pageNumber, pageSize, sortBy, filterBy) => (dispatch) => {
    dispatch({ type: types.GET_WITHDRAWAL_LIST, pageSize ,sortBy, filterBy, pageNumber });
    return reportsAPI.withdrawalFormReportList(pageNumber, pageSize, sortBy, filterBy).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_WITHDRAWAL_LIST_SUCCESS, result: res.data || {} });
      }
    });
  },
  loadNextWithdrawalReports: (pageNumber) => (dispatch, getState) => {
    const sortBy = getState().reports.withdrawalsData.pagedData.sortedBy;
    const filterBy = getState().reports.withdrawalsData.pagedData.filteredBy;
    const pageSize = getState().reports.withdrawalsData.pagedData.paging.pageSize;
    dispatch({ type: types.GET_NEXT_WITHDRAWAL_REPORT, pageSize ,sortBy, filterBy, pageNumber });
    return reportsAPI.withdrawalFormReportList(pageNumber, pageSize, sortBy ? sortBy : [], filterBy ? filterBy : []).then(res => {
      if (res.ok) {
        dispatch({ type: types.GET_NEXT_WITHDRAWAL_REPORT_SUCCESS, result: res.data || {} });
      }
    });
  },
  withdrawalReport: (data) => async dispatch => {
    dispatch({ type: types.GET_WITHDRAWAL_REPORT });
    return await reportsAPI
    .generateWithdrawalFormReport(data)
    .then(response => {
      if (response.ok) {
        storeBlobAs(response.data, 'Zwroty_Klientow_Roovee.xlsx');
        dispatch({ type: types.GET_WITHDRAWAL_REPORT_SUCCESS });
        return {success: true}
      } else {
        dispatch(notificationActions.showError('Wystąpił problem podczas pobierania raportu'));
        dispatch({ type: types.GET_WITHDRAWAL_REPORT_FAILED });
        return {success: false}

      }
    });
  },
  changeWithdrawalStatus: (id, data) => async dispatch => {
    dispatch({ type: types.CHANGE_WITHDRAWAL_FORM_STATUS });
    return await reportsAPI
      .withdrawalFormChangeStatus(id ,data)
      .then(response => {
        if (response.ok) {
          dispatch({ type: types.CHANGE_WITHDRAWAL_FORM_STATUS_SUCCESS, result: {status: data.status, id} });
          return {success: true}
        } else {
          dispatch({ type: types.CHANGE_WITHDRAWAL_FORM_STATUS_FAILED });
          return {success: false}

        }
      });
  }
}
