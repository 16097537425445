import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { submit } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/MessageTwoTone";
import { actions } from "../../actions/bikesActions";
import SendLockMessageForm from "./SendLockMessageForm";

const styles = (theme) => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

class SendLockMessageAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      submitting: false,
    };

    this.handleSendLockMessageStart =
      this.handleSendLockMessageStart.bind(this);
    this.handleSendLockMessageCancel =
      this.handleSendLockMessageCancel.bind(this);
    this.handleSendLockMessage = this.handleSendLockMessage.bind(this);
  }

  handleSendLockMessageStart() {
    this.setState({
      dialogVisible: true,
    });
  }

  handleSendLockMessageCancel() {
    this.setState({
      dialogVisible: false,
    });
  }

  handleSendLockMessage(data) {
    this.setState({ submitting: true });
    const { bikesIds, devicesType, message } = data;
    this.props.actions
      .sendLockMessage(bikesIds, message, devicesType)
      .then(() => this.setState({ dialogVisible: false, submitting: false }))
      .then(() => this.props.onActionExecuted());
  }

  render() {
    const {
      bikesIds,
      deviceTypes,
      dispatch,
      fullScreen,
      classes,
      menuItem,
      disabled,
    } = this.props;
    return (
      <React.Fragment>
        {menuItem ? (
          <MenuItem
            onClick={this.handleSendLockMessageStart}
            disabled={disabled}
          >
            <MessageIcon className={classes.icon} />
            Wyślij komendę
          </MenuItem>
        ) : deviceTypes.length > 1 ? (
          <Tooltip title="Masowe wysyłanie komend jest dostępne wyłącznie w przypadku wybraniu zamków jednego typu">
            <span>
              <Button
                size="small"
                variant="outlined"
                className={classes.button}
                disabled
              >
                <MessageIcon className={classes.icon} />
                Wyślij komendę
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            size="small"
            variant="outlined"
            className={classes.button}
            onClick={this.handleSendLockMessageStart}
            disabled={disabled}
          >
            <MessageIcon className={classes.icon} />
            Wyślij komendę
          </Button>
        )}
        <Dialog
          open={this.state.dialogVisible}
          fullScreen={fullScreen}
          fullWidth={true}
        >
          <DialogTitle>Wysyłanie komendy</DialogTitle>
          <DialogContent>
            Liczba wybranych urządzeń: <strong>{bikesIds.length}</strong>
            {deviceTypes[0] === 'omni'
             && <>
             <Typography>Dostępne komendy OMNI</Typography>
             <Typography>ALARMON,10 - Włączanie alarmu dźwiękowego</Typography>
             <Typography>REFRESH - Odświeżenie danych</Typography>
             <Typography>RESTART - Restart zamka</Typography>
             <Typography>SHUTDOWN - Wyłączenie zamka</Typography>
             <Typography>UNLOCK,, - Otwarcie blokady</Typography>
             </>
            }
          </DialogContent>
          <DialogContent>
            <SendLockMessageForm
              initialValues={{ bikesIds, devicesType: deviceTypes[0] }}
              onSubmit={this.handleSendLockMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSendLockMessageCancel}
              disabled={this.state.submitting}
            >
              Anuluj
            </Button>
            <Button
              onClick={() => dispatch(submit("sendLockMessageForm"))}
              disabled={this.state.submitting}
              color="secondary"
            >
              Potwierdź
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };
}

SendLockMessageAction.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  bikesIds: PropTypes.array.isRequired,
  deviceTypes: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  classes: PropTypes.object.isRequired,
  onActionExecuted: PropTypes.func.isRequired,
  menuItem: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default compose(
  connect(null, mapDispatchToProps),
  withMobileDialog(),
  withStyles(styles)
)(SendLockMessageAction);
