import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import PaginationPanel from './pagination/PaginationPanel';
import HeaderComponent from './header/HeaderComponent';
import "react-table/react-table.css";

const CheckboxTable = checkboxHOC(ReactTable);
CheckboxTable.displayName = 'CheckboxTable';

//import "./table.css";

const sortConverter = {
  toReactTable: (sortBy) => sortBy.map(s => ({ id: s.id, desc: s.value === 'desc' })),
  toServerSide: (sortBy) => sortBy.map(s => ({ id: s.id, value: s.desc ? 'desc' : 'asc' }))
};

const filterConverter = {
  toReactTable: (filterBy, columns) => filterBy.map(filter => {
    const def = columns && columns.find(c => c.filterId === filter.id);
    return def ? { id: def.id, value: filter.value } : filter;
  }),
  toServerSide: (filterBy, columns) => filterBy.map(filter => {
    const def = columns && columns.find(c => c.id === filter.id && c.filterId);
    return def ? { id: def.filterId, value: filter.value } : filter;
  })
};

const Table = React.forwardRef(({ loading, paging, columns, filteredBy, sortedBy, onFilteredChange, onPageChange, onPageSizeChange, onSortedChange, data, ...props }, ref) => <CheckboxTable
  ref={ref}
  ThComponent={HeaderComponent}
  columns={columns}
  loading={loading}
  loadingText="Ładowanie danych..."
  noDataText="Nie znaleziono danych"
  data={[...data || []]}
  manual
  pageText="Wyświetl"
  defaultPageSize={paging.pageSize}
  pageSizeOptions={[1000, 2000, 5000]}
  page={paging.pageNumber}
  pages={paging.totalPages}
  onPageChange={onPageChange}
  onPageSizeChange={onPageSizeChange}
  canNext={paging.pageNumber < paging.totalPages}
  canPrevious={paging.pageNumber > 1}
  PaginationComponent={PaginationPanel}
  getPaginationProps={() => ({ totalSize: paging.totalSize })}
  sorted={sortConverter.toReactTable([...sortedBy])}
  onSortedChange={sortBy => onSortedChange(sortConverter.toServerSide(sortBy))}
  sortable
  filtered={filterConverter.toReactTable([...filteredBy], columns)}
  filterable
  onFilteredChange={filterBy => onFilteredChange(filterConverter.toServerSide(filterBy, columns))}
  className="-highlight"
  {...props}
/>);
Table.displayName = 'CheckboxTable';

Table.propTypes = {
  loading: PropTypes.bool,
  paging: PropTypes.object,
  filteredBy: PropTypes.array,
  sortedBy: PropTypes.array,
  data: PropTypes.array,
  columns: PropTypes.array,
  onFilteredChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortedChange: PropTypes.func,
};

Table.defaultProps = {
  columns: [],
  loading: false,
  paging: {},
  filteredBy: [],
  sortedBy: [],
  data: [],
  onFilteredChange: () => { },
  onPageChange: () => { },
  onPageSizeChange: () => { },
  onSortedChange: () => { },
};

export default Table;
