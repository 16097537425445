import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import numberFormatter from '../../utils/numberFormatter';
import ConfirmationButton from '../common/ConfirmationButton';

class MobileUserAccountWallet extends React.PureComponent {
  render() {
    const { t, account, onWithdraw, onFundsReturn, disabled } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  {t('mobileUserAccounts.details.walletHeader')}
                </Typography>
              </Grid>
            </Grid>
            <Paper style={{padding: 16}}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.balance')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="body2">
                    {numberFormatter.format(account.wallet.balance)} zł
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.availableToWithdraw')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="body2">
                    {numberFormatter.format(account.wallet.availableToWithdraw)} zł
                  </Typography>
                </Grid>
              </Grid>

              <Button size="small" variant="outlined" color="secondary" onClick={onWithdraw} style={{marginTop: 16}} disabled={!account.wallet.availableToWithdraw || disabled}>
                {t('mobileUserAccounts.details.withdrawButton')}
              </Button>

              <ConfirmationButton
                size="small"
                variant="outlined"
                color="secondary"
                onConfirm={onFundsReturn}
                style={{ marginTop: 16, marginLeft: 20 }}
                confirmationTitle={t('mobileUserAccounts.details.withdrawFundsTitle')}
                confirmationText={t('mobileUserAccounts.details.withdrawFundsConfirmation')}
                disabled={!account.wallet.availableToWithdraw || disabled}
              >
                {t('mobileUserAccounts.details.withdrawFundsButton')}
              </ConfirmationButton>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MobileUserAccountWallet.propTypes = {
  t: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onFundsReturn: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default withTranslation()(MobileUserAccountWallet);
