import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { Button, Typography, Paper } from '@material-ui/core';
import { actions } from '../../actions/mobileUserAccountsActions';
import LoadingIndicator from '../LoadingIndicator';
import MobileUserAccountDetails from './MobileUserAccountDetails';
import MobileUserAccountWallet from './MobileUserAccountWallet';
import { AccountStatusOptions } from '../../models/mobileUserAccounts/MobileUserAccountsListModel';
import ConfirmationButton from '../common/ConfirmationButton';

class MobileUserAccountDetailsPage extends React.Component {
  state = {
    actionInProgress: false,
  }

  componentDidMount() {
    this.props.actions.getDetails(this.props.match.params.id);
  }

  handleUnblockVerification = () => {
    this.setState({ actionInProgress: true});
    this.props.actions.unblockVerification(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }));
  }

  handleChangeTesterRole = () => {
    this.setState({ actionInProgress: true});
    this.props.account.isTester
      ? this.props.actions.revokeTesterRole(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }))
      : this.props.actions.grantTesterRole(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }))
  }

  handleWithdraw = () => {
    this.setState({ actionInProgress: true});
    this.props.actions.withdraw(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }));
  }

  handleFundsReturn = () => {
    this.setState({ actionInProgress: true});
    this.props.actions.registerAllFundsReturn(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }));
  }

  handleDelete = () => {
    this.setState({ actionInProgress: true});
    this.props.actions.delete(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }));
  }

  handleLock = () => {
    this.setState({ actionInProgress: true});
    this.props.actions.lock(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }));
  }

  handleUnlock = () => {
    this.setState({ actionInProgress: true});
    this.props.actions.unlock(this.props.account.userId).finally(() => this.setState({ actionInProgress: false }));
  }

  render() {
    const { actionInProgress } = this.state;
    const { t, fetching, account } = this.props;

    return (
      <DocumentTitle title={t('mobileUserAccounts.details.title')}>
        <div className="page">
          <div style={{padding: 16}}>
            <LoadingIndicator fetching={fetching}>
              {account &&
                <React.Fragment>
                  <MobileUserAccountDetails account={account} />
                  <div style={{marginTop: 16}}>
                    <MobileUserAccountWallet account={account} onWithdraw={this.handleWithdraw} onFundsReturn={this.handleFundsReturn} disabled={actionInProgress} />
                  </div>

                  {account.verificationBlocked && <Paper style={{marginTop: 16, padding: 16}} elevation={0}>
                    <Typography gutterBottom variant="body1" color="error">{t('mobileUserAccounts.details.verificationBlocked')}</Typography>
                    <Button size="small" variant="outlined" color="secondary" onClick={this.handleUnblockVerification} disabled={!account.verificationBlocked || account.status === AccountStatusOptions.deleted.value || actionInProgress}>
                      {t('mobileUserAccounts.details.unblockButton')}
                    </Button>
                  </Paper>}

                  {account.status === AccountStatusOptions.deleted.value && <Paper style={{marginTop: 16, padding: 16}} elevation={0}>
                    <Typography gutterBottom variant="body1" color="error">{t('mobileUserAccounts.details.accountDeleted')}</Typography>
                    <ul>
                      <li>
                        <Typography variant="body2">
                          <a href="https://app.onesignal.com/apps/7223d107-fc8d-488d-98d4-cf26648c1b44/segments" target="_blank" rel="noopener noreferrer">Przejdź do OneSignal</a>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Wybierz segment <strong>PO ID</strong>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Wprowadź identyfikator <strong>{account.userId}</strong> i zapisz segment
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                        <a href="https://dashboard.onesignal.com/apps/7223d107-fc8d-488d-98d4-cf26648c1b44/players?segment=cb88650c-8386-4ba7-ba3e-56d64319cc44" target="_blank" rel="noopener noreferrer">Użyj segmentu</a> aby odnaleźć i usunąć użytkownika
                        </Typography>
                      </li>
                    </ul>
                  </Paper>}

                  <Paper style={{marginTop: 16, padding: 16}} elevation={0}>
                    <Button size="small" variant="outlined" onClick={this.handleChangeTesterRole} style={{marginRight: 10}} disabled={account.status === AccountStatusOptions.deleted.value || actionInProgress}>
                      {account.isTester ? t('mobileUserAccounts.details.revokeTesterRoleButton') : t('mobileUserAccounts.details.grantTesterRoleButton')}
                    </Button>

                    {account.status === AccountStatusOptions.locked.value &&
                    <Button size="small" variant="outlined" onClick={this.handleUnlock} style={{marginRight: 10}} disabled={account.status === AccountStatusOptions.deleted.value || actionInProgress}>
                      {t('mobileUserAccounts.details.unlockAccountButton')}
                    </Button>}

                    {account.status === AccountStatusOptions.active.value && <ConfirmationButton
                      size="small"
                      variant="outlined"
                      onConfirm={this.handleLock}
                      style={{marginRight: 10}}
                      disabled={account.status === AccountStatusOptions.deleted.value || actionInProgress}
                      confirmationTitle={t('mobileUserAccounts.details.lockAccountConfirmationTitle')}
                      confirmationText={t('mobileUserAccounts.details.lockAccountConfirmationText')}
                      confirmLabel={t('mobileUserAccounts.details.lockAccountButton')}
                    >
                      {t('mobileUserAccounts.details.lockAccountButton')}
                    </ConfirmationButton>}
                    <ConfirmationButton
                      size="small"
                      variant="outlined"
                      onConfirm={this.handleDelete}
                      style={{marginRight: 10}}
                      disabled={account.status === AccountStatusOptions.deleted.value || actionInProgress}
                      confirmationTitle={t('mobileUserAccounts.details.deleteConfirmationTitle')}
                      confirmationText={t('mobileUserAccounts.details.deleteConfirmationText')}
                      confirmLabel={t('mobileUserAccounts.details.deleteButton')}
                    >
                      {t('mobileUserAccounts.details.deleteButton')}
                    </ConfirmationButton>
                  </Paper>
                </React.Fragment>
              }
            </LoadingIndicator>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

MobileUserAccountDetailsPage.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  account: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    fetching: state.mobileUserAccountDetails.fetching,
    account: state.mobileUserAccountDetails.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(MobileUserAccountDetailsPage);
