import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ShowIcon from '@material-ui/icons/FindInPage';
import routePaths from '../../routePaths';
import TextFilter from '../common/table/filters/TextFilter';
import SelectFilter from '../common/table/filters/SelectFilter';
import { TesterOptions } from '../../models/mobileUserAccounts/MobileUserAccountsListModel';

const styles = {
  'true': {
    color: '#757575',
  },
};

/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
/* eslint react/no-multi-comp: 0 */
export const columns = [
  {
    Header: '',
    id: 'show_details',
    Cell: row => (
      <Link to={routePaths.tenantDetails.replace(':id', row.original.id)}>
        <IconButton style={{ width: 24, height: 24, padding: 2 }}>
          <ShowIcon />
        </IconButton>
      </Link>),
    width: 35,
    sortable: false,
    filterable: false,
  },
  {
    Header: 'Subdomena',
    id: 'id',
    accessor: t => <span style={styles[t.internalUseOnly]}>{t.id}</span>,
    width: 150,
    filterable: false,
  },
  {
    Header: 'Nazwa',
    id: 'name',
    accessor: t => <span style={styles[t.internalUseOnly]}>{t.name}</span>,
    width: 150,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Pełna nazwa',
    id: 'fullName',
    accessor: t => <span style={styles[t.internalUseOnly]}>{t.fullName}</span>,
    width: 150,
    Filter: ({ filter, onChange }) => <TextFilter onChange={onChange} filter={filter} label="filtruj..." />,
  },
  {
    Header: 'Dostępny tylko dla testerów',
    id: 'internalUseOnly',
    accessor: t => <span style={styles[t.internalUseOnly]}>{t.internalUseOnly ? 'TAK' : 'NIE'}</span>,
    width: 200,
    Filter: ({ filter, onChange }) => <SelectFilter onChange={onChange} filter={filter} items={TesterOptions} label="filtruj..." />,
  },
];
