import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Typography, Chip } from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import { AccountStatusOptions } from '../../models/mobileUserAccounts/MobileUserAccountsListModel';

class MobileUserAccountDetails extends React.PureComponent {
  render() {
    const { t, account } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  {t('mobileUserAccounts.details.header')}
                </Typography>
                <Chip label={AccountStatusOptions[account.status].name} />
              </Grid>
            </Grid>
            <Paper style={{padding: 16}}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.firstName')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="body2">
                    {account.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.lastName')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="body2">
                    {account.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.phoneNumber')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="body2">
                    {account.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.email')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="body2">
                    {account.email}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1">
                    {t('mobileUserAccounts.details.tenants')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={10}>
                  <Typography variant="caption" gutterBottom>
                    {t('mobileUserAccounts.details.tenantsDetails')}
                  </Typography>
                  {!account.tenants.length
                    ? <Typography variant="body2">Brak zaakceptowanych regulaminów</Typography>
                    : account.tenants.map(tenant => (
                      <Typography variant="body2" key={tenant.tenant} gutterBottom color={tenant.accessDenied ? 'error' : 'textPrimary'}>
                        <a href={`https://${tenant.tenant}.roovee.eu/reports/clients/${account.userId}/history`} target="_blank" rel="noopener noreferrer">{tenant.tenant.toUpperCase()} (szczegóły)</a> {tenant.accessDenied ? 'Konto na czarnej liście' : null}
                      </Typography>
                    ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MobileUserAccountDetails.propTypes = {
  t: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};

export default withTranslation()(MobileUserAccountDetails);
