import Immutable from "seamless-immutable";
import initialState from "./initialState";
import {types} from "../actions/publicAccessTokenActions";

export default function publicAccessTokenReducer(state = Immutable(initialState.publicAccessToken), action) {
  switch (action.type) {
    case types.CREATE_PUBLIC_ACCESS_TOKEN:{
      return Immutable.merge(state, {
        submitting: true,
        accessToken: null
      });
    }
    case types.CREATE_PUBLIC_ACCESS_TOKEN_SUCCESS:{
      const result = action.data;
      return Immutable.merge(state, {
        submitting: false,
        accessToken: result,
        showCreatedTokenDialog: true,
        showNewTokenDialog: false
      });
    }
    case types.CREATE_PUBLIC_ACCESS_TOKEN_FAILED:{
      return Immutable.merge(state, {
        submitting: false,
        accessToken: null,
      });
    }
    case types.UPDATE_PUBLIC_ACCESS_TOKEN:{
      return Immutable.merge(state, {
        submitting: true
      });
    }
    case types.UPDATE_PUBLIC_ACCESS_TOKEN_SUCCESS:{
      return Immutable.merge(state, {
        submitting: false,
        tokenDialog: {
          show: false,
          edit: false,
          data: null
        }
      });
    }
    case types.UPDATE_PUBLIC_ACCESS_TOKEN_FAILED:{
      return Immutable.merge(state, {
        submitting: false
      });
    }
    case types.SHOW_NEW_TOKEN_DIALOG:{
      return Immutable.merge(state, {
        showNewTokenDialog: true
      });
    }
    case types.HIDDEN_NEW_TOKEN_DIALOG:{
      return Immutable.merge(state, {
        showNewTokenDialog: false
      });
    }
    case types.HIDDEN_CREATED_TOKEN_DIALOG:{
      return Immutable.merge(state, {
        showCreatedTokenDialog: false
      });
    }
    case types.GET_PUBLIC_ACCESS_TOKEN: {
      return Immutable.merge(state, {
        fetchingAccessTokenList: true,
        accessTokenList: []
      });
    }
    case types.GET_PUBLIC_ACCESS_TOKEN_SUCCESS: {
      const result = action.data;
      return Immutable.merge(state, {
        fetchingAccessTokenList: false,
        accessTokenList: result
      });
    }
    case types.GET_PUBLIC_ACCESS_TOKEN_FAILED: {
      return Immutable.merge(state, {
        fetchingAccessTokenList: false,
        accessTokenList: []
      });
    }
    case types.GET_TOKEN_PERMISSIONS: {
      return Immutable.merge(state, {
        fetchingPermissions: true,
        permissions: []
      });
    }
    case types.GET_TOKEN_PERMISSIONS_SUCCESS: {
      const result = action.data;
      return Immutable.merge(state, {
        fetchingPermissions: false,
        permissions: result.permissionsList
      });
    }
    case types.GET_TOKEN_PERMISSIONS_FAILED: {
      return Immutable.merge(state, {
        fetchingPermissions: false,
        permissions: []
      });
    }
    case types.SHOW_TOKEN_DIALOG: {
      return Immutable.merge(state, {
        tokenDialog: {
          show: true,
          edit: action.result.edit,
          data: action.result.data
        }
      });
    }
    case types.HIDDEN_TOKEN_DIALOG: {
      return Immutable.merge(state, {
        tokenDialog: {
          show: false,
          edit: false,
          data: null
        }
      });
    }
    case types.SHOW_DEACTIVATION_TOKEN_DIALOG: {
      return Immutable.merge(state, {
        deactivateTokenDialog: {
          show: true,
          data: action.data
        }
      });
    }
    case types.HIDDEN_DEACTIVATION_TOKEN_DIALOG: {
      return Immutable.merge(state, {
        deactivateTokenDialog: {
          show: false,
          data: null
        }
      });
    }
    case types.DEACTIVATION_TOKEN_SUCCESS: {
      return Immutable.merge(state, {
        deactivateTokenDialog: {
          show: false,
          data: null
        }
      });
    }
    default: return state
  }
}
