import React from 'react';
import PropTypes from 'prop-types';
import DateTimeFilter from '../common/table/filters/DateTimeFilter';
import TextFilter from '../common/table/filters/TextFilter';
import SelectFilter from '../common/table/filters/SelectFilter';
import { InternalUseOnlyOptions, LockStateOptions, ShackleStateOptions } from '../../models/bikes/BikesListModel';

class FiltersPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  getFilterValue(id) {
    return this.props.filteredBy.find(f => f.id === id);
  }

  handleDelete(deleteFunction) {
    this.props.onFilteredChange(deleteFunction(this.props.filteredBy));
  }

  render() {
    return (
      <div className="flex-row flex-wrap" style={{marginBottom: 16}}>
        <TextFilter filter={this.getFilterValue("tenant")} onDelete={this.handleDelete} readonly label="Operator" />
        <TextFilter filter={this.getFilterValue("bikeNumber")} onDelete={this.handleDelete} readonly label="Numer" />
        <TextFilter filter={this.getFilterValue("lockImei")} onDelete={this.handleDelete} readonly label="IMEI" />
        <TextFilter filter={this.getFilterValue("lockPhoneNumber")} onDelete={this.handleDelete} readonly label="Nr telefonu" />
        <TextFilter filter={this.getFilterValue("lockBatteryLevel")} onDelete={this.handleDelete} readonly label="Bateria" />
        <TextFilter filter={this.getFilterValue("bikeBatteryLevel")} onDelete={this.handleDelete} readonly label="Bateria rpweru" />
        <TextFilter filter={this.getFilterValue("lockFirmware")} onDelete={this.handleDelete} readonly label="Firmware" />
        <SelectFilter filter={this.getFilterValue("lockState")} items={LockStateOptions} onDelete={this.handleDelete} readonly label="Blokada" />
        <SelectFilter filter={this.getFilterValue("shackleState")} items={ShackleStateOptions} onDelete={this.handleDelete} readonly label="Podkowa" />
        <DateTimeFilter filter={this.getFilterValue("lockTimestamp")} onDelete={this.handleDelete} readonly label="Czas locka od" />
        <DateTimeFilter filter={this.getFilterValue("gpsTimestamp")} onDelete={this.handleDelete} readonly label="Czas GPS od" />
        <TextFilter filter={this.getFilterValue("satellites")} onDelete={this.handleDelete} readonly label="Satelity" />
        <TextFilter filter={this.getFilterValue("agps")} onDelete={this.handleDelete} readonly label="AGPS" />
        <TextFilter filter={this.getFilterValue("typeName")} onDelete={this.handleDelete} readonly label="Typ" />
        <SelectFilter filter={this.getFilterValue("internalUseOnly")} items={InternalUseOnlyOptions} onDelete={this.handleDelete} readonly label="Testowe" />
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  onFilteredChange: PropTypes.func,
  filteredBy: PropTypes.array
};

export default FiltersPanel;
