import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import { withTranslation} from 'react-i18next';
import FormTextField from '../form/FormTextField';
import {Button, Tab, Tabs, Tooltip} from '@material-ui/core';
import FormCheckBox from "../form/FormCheckBox";
import {useSelector} from "react-redux";


const SupportedLanguageCodes = Object.freeze({
  pl: { value: 'pl' },
  en: { value: 'en' },
  de: { value: 'de' },
  ru: { value: 'ru' },
  cs: { value: 'cs' },
  lt: { value: 'lt' },
  uk: { value: 'uk' },
});

const validation = values => {
  const errors = {}
  Object.keys(SupportedLanguageCodes).map(x => {
    if(values.description){
      if (!values.description[x]) {
        if(!errors.description) {
          errors.description = {}
        }
        errors.description[x] = "Pole jest wymagane"
      }
      if (!values.name[x]) {
        if(!errors.name) {
          errors.name = {}
        }
        errors.name[x] = "Pole jest wymagane"
      }
    }
    else {
      if(!errors.description) {
        errors.description = {}
        errors.name = {}
      }
      errors.description[x] = "Pole jest wymagane"
      errors.name[x] = "Pole jest wymagane"
    }
  })
  return errors;
}

let BikeTypeForm = ({ handleSubmit, submitting, t }) => {
  const [chooseLang, setChooseLang] = useState(SupportedLanguageCodes.pl.value);

  const state = useSelector(x => x.form.bikeTypeForm)
  console.log('state',state)

  const canSave = useMemo(() => {
    if(!state.syncErrors) {
      return true;
    }
    if(Object.keys(state.syncErrors.description).length > 0) {
      return false;
    }
    if(Object.keys(state.syncErrors.name).length > 0) {
      return false;
    }
    return false;
  }, [state.syncErrors])


  const errorText = useMemo(() => {
    if (!state.syncErrors) {
      return null;
    }
    let isError = false;

    if (state.syncErrors.description && Object.keys(state.syncErrors.description).length !== 0) {
      isError = true
    }

    if (state.syncErrors.name && Object.keys(state.syncErrors.name).length !== 0) {
      isError = true
    }

    if(!isError) {
      return null;
    }

    return (
      <div>
        {state.syncErrors.name && Object.keys(state.syncErrors.name).length !== 0 && <div>
          Brak tłumaczeń dla nazwy: {Object.keys(state.syncErrors.name).map(x => (<span key={`name-${x}`} style={{padding: '0 2px'}}>{x.toUpperCase()}</span>))}
        </div>}
        {state.syncErrors.description && Object.keys(state.syncErrors.description).length !== 0 && <div>
          Brak tłumaczeń dla opisów: {Object.keys(state.syncErrors.description).map(x => (<span key={`description-${x}`}  style={{padding: '0 2px'}}>{x.toUpperCase()}</span>))}
        </div>}
      </div>
    )


  }, [state.syncErrors]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Tabs
          value={chooseLang}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={(event, language) => setChooseLang(language) }
        >
          <Tab value={SupportedLanguageCodes.pl.value} label={<Tooltip title="polski"><span>PL</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.en.value} label={<Tooltip title="angielski"><span>EN</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.de.value} label={<Tooltip title="niemiecki"><span>DE</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.ru.value} label={<Tooltip title="rosyjski"><span>RU</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.cs.value} label={<Tooltip title="czeski"><span>CS</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.lt.value} label={<Tooltip title="litewski"><span>LT</span></Tooltip>} />
          <Tab value={SupportedLanguageCodes.uk.value} label={<Tooltip title="ukraiński"><span>UK</span></Tooltip>} />
        </Tabs>
      </div>
      <div>
        <Field
          name={`name.${chooseLang.toLowerCase()}`}
          component={FormTextField}
          label={`Nazwa (${chooseLang.toUpperCase()}) *`}
        />
      </div>
      <div>
        <Field
          name={`description.${chooseLang.toLowerCase()}`}
          component={FormTextField}
          label={`Opis (${chooseLang.toUpperCase()}) *`}
          fullWidth
        />
      </div>
      <div>
        <Field
          name="isElectric"
          component={FormCheckBox}
          label="Rower elektryczny"
        />
      </div>
      {errorText}
      <div style={{textAlign: 'right', marginTop: 16}}>
        <Button variant="contained" color="primary" type={'submit'}  className="signin-button" disabled={submitting || !canSave} >
          {t('bikeTypes.submitButton')}
        </Button>
      </div>
    </form>
  );
};

BikeTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

BikeTypeForm = reduxForm({
  form: 'bikeTypeForm',
  validate: validation,
})(BikeTypeForm);

export default withTranslation()(BikeTypeForm);
