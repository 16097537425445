/* eslint-disable */
import local from './config.local.json';
import production from './config.prod.json';
import dev from './config.dev.json';

const config = {
  local,
  production,
  dev
};

export default config[window.env.APP_ENV];
