import apiBase from './apiBase';

class BikesApi {
  static getBikes(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize
      },
      filterBy: Object.assign({}, ...filterBy.map(x => ({ [x.id]: x.value }))),
      sortBy: Object.assign({}, ...sortBy.map(x=> ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/bikes`, payload);
  }

  static restrictRentals(ids, restrictionReason) {
    return apiBase
      .create()
      .post(`/bikes/restrictRentals`, { ids, restrictionReason });
  }

  static allowRentals(ids) {
    return apiBase
      .create()
      .post(`/bikes/allowRentals`, { ids });
  }

  static sendLockMessage(bikesIds, message, devicesType) {
    return apiBase
      .create()
      .post(`/bikes/sendLockMessage`, { bikesIds, message, devicesType });
  }
}

export default BikesApi;
