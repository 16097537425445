import React from "react";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import SheetIcon from '@material-ui/icons/GridOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PropTypes from "prop-types";
import {blue, green, red} from "@material-ui/core/colors";
import {ReportStatus} from "../../utils/ReportStatus";
import {useDispatch} from "react-redux";
import {actions} from "../../actions/reportsActions";
import moment from "moment";

const HistoryList = ({data, fetching}) => {

  const dispatch = useDispatch()

  if(fetching){
    return (
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: 10}}>Trwa ładowanie...</div>
        <CircularProgress />
      </div>
    )
  }

  if(!data || data.histories.length === 0){
    return (
      <div style={{textAlign: "center"}}>
        <div style={{marginBottom: 10}}>Nie znaleziono raportów.</div>
      </div>
    )
  }

  return(
    <div>
      <div>
        <div>Raporty</div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Od</TableCell>
              <TableCell>Do</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Data generacji</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.histories && data.histories.map(x => (
              <TableRow key={x.id}>
                <TableCell>

                  {x.status === ReportStatus.queued.value
                  || x.status === ReportStatus.fetchingData.value
                  || x.status === ReportStatus.rendering.value
                    ? <ScheduleIcon style={{ height: 24, width: 24, fill: blue[400] }} />
                    : x.status === ReportStatus.failed.value
                      ? <Tooltip title="Wystąpił błąd podczas generowania raportu">
                        <ErrorIcon style={{ height: 24, width: 24, fill: red[400] }} />
                      </Tooltip>
                      : <>
                      </>
                  }

                  {x.pdfReportUrl && <Tooltip title="Pobierz raport w formacie PDF">
                    <IconButton style={{ height: 24, width: 24 }} onClick={() => dispatch(actions.downloadPdf(x.tenantId, x.id))}>
                      <PdfIcon />
                    </IconButton>
                  </Tooltip>
                  }

                  {x.xlsxReportUrl && <Tooltip title="Pobierz raport w formacie Excel">
                    <IconButton style={{ height: 24, width: 24, marginLeft: 10 }} onClick={() => dispatch(actions.downloadXlsx(x.tenantId, x.id))}>
                      <SheetIcon style={{ fill: green[400] }} />
                    </IconButton>
                  </Tooltip>
                  }
                </TableCell>
                <TableCell>{moment(x.dateFrom).local().format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{moment(x.dateTo).local().format('DD.MM.YYYY HH:mm')}</TableCell>
                <TableCell>{ReportStatus[x.status].name}</TableCell>
                <TableCell>{moment(x.startedAt).local().format('DD.MM.YYYY HH:mm')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

HistoryList.propTypes = {
  data: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
}

export default HistoryList;
