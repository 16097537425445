import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import './styles.scss';
import { Typography } from '@material-ui/core';

/* eslint react/no-multi-comp: 0 */

const multiFilterConverter = {
  convertToString: (filter) => filter && filter.value ? filter.value.join(", ") : '',
  convertToFilter: (text) => {
    const values = text ? text.split(",") : [];
    if (!values.length) return null;
    const isLast = (i) => i === values.length - 1;
    return [...values.map((m, i) => isLast(i) ? _.trimStart(m) : _.trim(m))].filter(v => v);
  },
  createDeleteFunction: (id, valueToDelete) => (filters) => {
    const deleteValue = (filter, valueToDelete) => filter.value.length > 1 ? ({ ...filter, value: filter.value.filter(v => v !== valueToDelete) }) : null;
    return filters
      .map(f => f.id !== id ? f : deleteValue(f, valueToDelete))
      .filter(f => f !== null);
  },
  convertToReadonly: (filter) => [...filter.value],
};

const singleFilterConverter = {
  convertToString: (filter) => filter && filter.value || '',
  convertToFilter: (text) => _.trim(text, [',', ' ']),
  createDeleteFunction: (id) => (filters) => filters.filter(f => f.id !== id),
  convertToReadonly: (filter) => [filter.value],
};

const getConverter = (multi) => (multi ? multiFilterConverter : singleFilterConverter);

class ActiveMode extends React.Component {
  constructor(props) {
    super(props);
    this.converter = getConverter(props.multi);
    this.state = {
      rawValue: this.converter.convertToString(props.filter),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ rawValue: this.converter.convertToString(nextProps.filter) });
  }

  render() {
    const { onChange, label, filter } = this.props;
    const rawValue = this.state.rawValue;
    return (
      <TextField
        placeholder={label}
        type="text"
        InputProps={{
          disableUnderline: true,
          style: { fontSize: '1em' },
        }}
        margin="none"
        fullWidth
        onChange={e => this.setState({ rawValue: e.target.value })}
        value={rawValue}
        onBlur={() => rawValue !== this.converter.convertToString(filter) && onChange(this.converter.convertToFilter(rawValue))}
        onKeyPress={(ev) => ev.key === 'Enter' && onChange(this.converter.convertToFilter(rawValue))}
      />);
  }
}

const readonlyMode = (onDelete, label, filter, multi) => (
  <div className="filter">
    <Typography variant="caption" style={{display: 'inline'}} component="span">{label}</Typography>
    {getConverter(multi).convertToReadonly(filter).map(v =>
      <Chip className="chip" color="primary" label={v} key={v} onDelete={() => onDelete(getConverter(multi).createDeleteFunction(filter.id, v))} />
    )}
  </div>
);

const TextFilter = ({ onChange, onDelete, readonly, label, filter, multi }) => {
  return (
    readonly
      ? (filter ? readonlyMode(onDelete, label, filter, multi) : null)
      : <ActiveMode onChange={onChange} label={label} filter={filter} multi={multi} />
  );
};

TextFilter.propTypes = {
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  multi: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
};

ActiveMode.propTypes = {
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  onDelete: PropTypes.func,
  multi: PropTypes.bool,
  label: PropTypes.string,
  filter: PropTypes.object,
};

export default TextFilter;
