import React from 'react';
import DocumentTitle from 'react-document-title';
import AnonymousContentPanel from '../AnonymousContentPanel';
import { CircularProgress, Button } from '@material-ui/core';
import './SignInPageStyles.scss';
import { useMsal } from '@azure/msal-react';
import { authConfig } from '../../../msal';
import { InteractionStatus } from '@azure/msal-browser';

const buttonProgress = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

const SignInPage = () => {
  const { instance, inProgress } = useMsal();
  const handleSignIn = () => {
    return instance.loginPopup({ scopes: authConfig.scopes })
      .catch(e => {
        if (e.errorMessage.indexOf("AADB2C90118") > -1) {
          return instance.loginPopup({
            authority: authConfig.passwordResetAuthority,
            scopes: authConfig.scopes
          });
        }
      });
  };

  return (
    <DocumentTitle title="ROOVEE">
      <AnonymousContentPanel>
        <Button
          onClick={handleSignIn}
          disabled={inProgress === InteractionStatus.Login}
        >
          <h2>Zaloguj się</h2>
        </Button>
        {inProgress === InteractionStatus.Login && <CircularProgress style={buttonProgress} color="secondary" size={24} />}
      </AnonymousContentPanel>
    </DocumentTitle >
  );
};

export default SignInPage;
