import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { Button, List, CircularProgress, ListItem, ListItemText, Paper, ListItemSecondaryAction } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { actions } from '../../actions/bikeTypesActions';
import routePaths from '../../routePaths';

class BikeTypesPage extends React.Component {
  componentDidMount() {
    this.props.actions.getAll();
  }

  render() {
    const { t, fetching, bikeTypes, actions } = this.props;

    return (
      <DocumentTitle title={t('bikeTypes.title')}>
        <div className="page">
          <div style={{padding: 16}}>
            <div style={{ textAlign: 'right', marginBottom: 16 }}>
              <Button variant="contained" color="primary" component={Link} to={routePaths.newBikeTypeDetails} >{t('bikeTypes.newBikeTypeButton')}</Button>
            </div>
            {fetching && <CircularProgress color="secondary" size={24} />}
            <Paper>
              <List>
                {bikeTypes.map(bikeType => (
                  <ListItem key={bikeType.id} button component={Link} to={routePaths.bikeTypeDetails.replace(':id', bikeType.id)}>
                    <ListItemText primary={bikeType.name.pl} secondary={bikeType.description.pl} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="Delete" onClick={() => actions.delete(bikeType.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

BikeTypesPage.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  bikeTypes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    fetching: state.bikeTypes.fetching,
    bikeTypes: state.bikeTypes.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(BikeTypesPage);
