import { TableModel } from '../TableModel';

export class MobileUserAccountsListModel {
  constructor() {
    this.loading = false;
    this.pagedData = new TableModel(
      { pageNumber: 1, pageSize: 100 },
      [{ id: 'lastName', value: 'asc' }, { id: 'firstName', value: 'asc' }],
      []);
  }
}

export const TesterOptions = {
  true: { value: 'true', name: 'TAK' },
  false: { value: 'false', name: 'NIE' },
};

export const VerificationBlockedOptions = {
  true: { value: 'true', name: 'TAK' },
  false: { value: 'false', name: 'NIE' },
};

export const IsOverdraftOptions = {
  true: { value: 'true', name: 'TAK' },
  false: { value: 'false', name: 'NIE' },
};

export const AccountStatusOptions = {
  active: { value: 'active', name: 'Aktywne' },
  locked: { value: 'locked', name: 'Zablokowane' },
  deleted: { value: 'deleted', name: 'Usunięte' },
};
