import React from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {Field, reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import FormDateTimeField from "../form/FormDateTimeField";

export const formValidation = values => {
  const constraints = {
    type: {
      presence: { message: 'Pole jest wymagane' },
    },
  };

  const errors = validate(values, constraints, { fullMessages: false }) || {};
  if (!values.startDate) errors.startDate = 'Data jest wymagana';
  if (!values.endDate) errors.endDate = 'Data jest wymagana';


  if (values.startDate && values.endDate && values.endDate.isBefore(values.startDate)) {
    errors.endDate = 'Data musi być późniejsza';
  }

  return errors;
};




let GenerateWithdrawalReportForm = ({ handleSubmit, }) => {

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="startDate"
          component={FormDateTimeField}
          label="Od"
          disableFuture
        />
      </div>
      <div>
        <Field
          name="endDate"
          component={FormDateTimeField}
          label="Do"
        />
      </div>
    </form>
  );
};

GenerateWithdrawalReportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  customReportList: PropTypes.arrayOf(PropTypes.object).isRequired,
  sourceReportType: PropTypes.number,
  reportType: PropTypes.number,
  userRole: PropTypes.string,
  canGenerateReportWithChosenData: PropTypes.bool,
};

GenerateWithdrawalReportForm = reduxForm({
  form: 'generateWithdrawalReport',
  validate: formValidation,
})(GenerateWithdrawalReportForm);

GenerateWithdrawalReportForm = connect(
  () => {
    return {
      initialValues: {
        startDate: moment().local().add(-1, 'month'),
        endDate: moment().local(),
      },
    };
  }
)(GenerateWithdrawalReportForm);

export default connect()(GenerateWithdrawalReportForm);
