import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import { submit } from 'redux-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import IncomeDistributionForm from "./IncomeDistributionForm";

const IncomeDistributionDialog = ({ open, onSubmit, onCancel, dispatch, t, tenantIncomeDistribution }) => (
  <Dialog open={open} fullWidth={true} onClose={onCancel}>
    <DialogTitle>{t('tenantIncomeDistribution.title')}</DialogTitle>
    <DialogContent>
      <IncomeDistributionForm onSubmit={onSubmit} t={t} dispatch={dispatch} initialValues={tenantIncomeDistribution} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        {t('common.cancelButton')}
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => dispatch(submit('incomeDistributionForm'))}
      >
        {t('tenants.newTenantDialog.submitButton')}
      </Button>
    </DialogActions>
  </Dialog>
);

IncomeDistributionDialog.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tenantIncomeDistribution: PropTypes.object
};

export default compose(
  connect(),
  withTranslation()
)(IncomeDistributionDialog);
