import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/mobileUserAccountsActions';
import { AccountStatusOptions } from '../models/mobileUserAccounts/MobileUserAccountsListModel';

export default function mobileUserAccountDetailsReducer(state = Immutable(initialState.mobileUserAccountDetails), action) {
  switch (action.type) {
    case types.GET_MOBILE_USER_ACCOUNT: {
      return { fetching: true };
    }
    case types.GET_MOBILE_USER_ACCOUNT_SUCCESS: {
      const { account } = action;
      
      return {
        fetching: false,
        data: account,
      };
    }
    case types.GET_MOBILE_USER_ACCOUNT_FAILURE: {
      return { fetching: false, data: null };
    }
    case types.UNLOCK_USER_VERIFICATION_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.verificationBlocked = false;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.GRANT_TESTER_ROLE_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.isTester = true;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.REVOKE_TESTER_ROLE_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.isTester = false;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.WITHDRAW_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.wallet = Object.assign({}, state.data.wallet);
      updatedAccount.wallet.availableToWithdraw = 0;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.REGISTER_FUNDS_RETURN_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.wallet = Object.assign({}, state.data.wallet);
      updatedAccount.wallet.availableToWithdraw = 0;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.LOCK_MOBILE_USER_ACCOUNT_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.status = AccountStatusOptions.locked.value;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.UNLOCK_MOBILE_USER_ACCOUNT_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.status = AccountStatusOptions.active.value;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    case types.DELETE_MOBILE_USER_ACCOUNT_SUCCESS: {
      const updatedAccount = Object.assign({}, state.data);
      updatedAccount.status = AccountStatusOptions.deleted.value;
      return {
        fetching: false,
        data: updatedAccount,
      };
    }
    default:
      return state;  
  }
}
