import { create as createApi } from 'apisauce';
import config from '../config';
import * as logger from '../utils/logger';
import pcaInstance, { authConfig } from '../msal';

async function getAccessToken() {
  const accounts = pcaInstance.getAllAccounts();
  if (accounts.length > 0) {
    const request = {
      scopes: authConfig.scopes,
      authority: authConfig.authority,
      account: accounts[0]
    }
    const accessToken = await pcaInstance.acquireTokenSilent(request).then((response) => {
      return response.accessToken;
    }).catch(error => {
      // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
      console.log(error);
      return null;
    });

    return accessToken;
  }

  return null;
}

export default class VirtualLocksApiBase {
  static create() {
    const api = createApi({
      baseURL: config.VIRTUALLOCKS_API_URL,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
      timeout: 20000
    });

    api.addAsyncRequestTransform(request =>
      getAccessToken()
        .then(token => {
          if (!token) return request;
          request.headers['Authorization'] = `Bearer ${token}`;
          return request;
        })
        .catch(e => {
          logger.error(e);
          return request;
        })
    );

    api.addMonitor(response => {
      if (response.status === 401 || response.status === 403) {
        pcaInstance.logoutRedirect();
      }
    });
    return api;
  }
}
