import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation} from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { actions } from '../../actions/virtualLocksActions';
import NewVirtualLockDialog from './NewVirtualLockDialog';
import VirtualLockDetails from './VirtualLockDetails';

class VirtualLocksPage extends React.Component {
  state = {
    lockDialogVisible: false,
    submitting: false,
  }

  componentDidMount() {
    this.props.actions.getAll();
  }

  handleAddNewLock = () => this.setState({ lockDialogVisible: true });

  handleCreateNewLock = data => {
    this.setState({ submitting: true });
    this.props.actions.create(data)
      .finally(() => this.setState({ submitting: false, lockDialogVisible: false }));
  }

  handleCancelNewLockCreation = () => this.setState({ lockDialogVisible: false });

  handleDeleteLock = imei => this.props.actions.delete(imei);

  handleEnableLock = imei => this.props.actions.enable(imei);

  handleDisableLock = imei => this.props.actions.disable(imei);

  handleTapNfcCard = (imei, nfcCardId) => this.props.actions.tapNfcCard(imei, nfcCardId);

  handleOpenLock = imei => this.props.actions.open(imei);

  handleCloseLock = imei => this.props.actions.close(imei);

  render() {
    const { t, fetching, locks } = this.props;
    const { submitting, lockDialogVisible } = this.state;

    return (
      <DocumentTitle title={t('virtualLocks.title')}>
        <div className="page">
          <div style={{ padding: 16 }}>
            <div style={{ textAlign: 'right', marginBottom: 16 }}>
              <Button variant="contained" color="primary" onClick={this.handleAddNewLock} disabled={submitting}>{t('virtualLocks.newLockButton')}</Button>
            </div>
            <Grid container spacing={3}>
              {fetching && <CircularProgress color="secondary" size={24} />}
              {locks.map(lock => (
                <Grid item key={lock.imei}>
                  <VirtualLockDetails
                    lock={lock}
                    onDelete={this.handleDeleteLock}
                    onEnable={this.handleEnableLock}
                    onDisable={this.handleDisableLock}
                    onOpen={this.handleOpenLock}
                    onClose={this.handleCloseLock}
                    onTapNfcCard={this.handleTapNfcCard}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <NewVirtualLockDialog
            open={lockDialogVisible}
            submitting={submitting}
            onSubmit={this.handleCreateNewLock}
            onCancel={this.handleCancelNewLockCreation}
          />
        </div>
      </DocumentTitle>
    );
  }
}

VirtualLocksPage.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  locks: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    fetching: state.virtualLocks.fetching,
    locks: state.virtualLocks.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(VirtualLocksPage);
