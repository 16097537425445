import initialState from './initialState';
import Immutable from 'seamless-immutable';
import { types } from '../actions/virtualLocksActions';
import { LockStateOptions } from '../models/bikes/BikesListModel';

export default function virtualLocksReducer(state = Immutable(initialState.virtualLocks), action) {
  switch (action.type) {
    case types.GET_LOCKS: {
      return state.merge({ fetching: true, data: [] });
    }
    case types.GET_LOCKS_SUCCESS: {
      return state.merge({
        fetching: false,
        data: action.result,
      });
    }
    case types.CREATE_LOCK_SUCCESS: {
      return state.merge({
        fetching: false,
        data: [...state.data, action.lock],
      });
    }
    case types.DELETE_LOCK_SUCCESS: {
      return state.merge({
        fetching: false,
        data: state.data.filter(l => l.imei !== action.imei),
      });
    }
    case types.ENABLE_LOCK_SUCCESS: {
      const index = state.data.findIndex(l => l.imei === action.imei);
      const item = Object.assign({}, state.data[index]);
      const newState = Object.assign({}, item.state);
      newState.enabled = true;
      item.state = newState;

      return state.merge({
        fetching: false,
        data: [...state.data.slice(0, index),
          item,
          ...state.data.slice(index + 1, state.length)],
      });
    }
    case types.DISABLE_LOCK_SUCCESS: {
      const index = state.data.findIndex(l => l.imei === action.imei);
      const item = Object.assign({}, state.data[index]);
      const newState = Object.assign({}, item.state);
      newState.enabled = false;
      item.state = newState;

      return state.merge({
        fetching: false,
        data: [...state.data.slice(0, index),
          item,
          ...state.data.slice(index + 1, state.length)],
      });
    }
    case types.OPEN_LOCK_SUCCESS: {
      const index = state.data.findIndex(l => l.imei === action.imei);
      const item = Object.assign({}, state.data[index]);
      const newState = Object.assign({}, item.state);
      newState.lockState = LockStateOptions.opened.value;
      item.state = newState;

      return state.merge({
        fetching: false,
        data: [...state.data.slice(0, index),
          item,
          ...state.data.slice(index + 1, state.length)],
      });
    }
    case types.CLOSE_LOCK_SUCCESS: {
      const index = state.data.findIndex(l => l.imei === action.imei);
      const item = Object.assign({}, state.data[index]);
      const newState = Object.assign({}, item.state);
      newState.lockState = LockStateOptions.closed.value;
      item.state = newState;

      return state.merge({
        fetching: false,
        data: [...state.data.slice(0, index),
          item,
          ...state.data.slice(index + 1, state.length)],
      });
    }
    default:
      return state;  
  }
}
